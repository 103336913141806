import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Dialog, makeStyles, DialogActions, DialogContent, DialogTitle, List, ListItem, Avatar, ListItemAvatar, ListItemText, Typography, IconButton } from '@material-ui/core';
import BaseButton from './BaseButton';
import { Person, Email, Call, Close } from '@material-ui/icons';
import { blue } from '@material-ui/core/colors';
import { useSelector, useDispatch } from 'react-redux';
import util from '../util';
const moment = require('moment');

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    }
}));

const UserProfile = (props) => {
    const classes = useStyles();
    const userData = useSelector(state => state.appReducer.userData);
    const { emailAddress, fullName, mobile, dob } = userData;
    let { open, handleClose } = props;
    return (
        <>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth={true}
                style={{ zIndex: '99999' }}
                className="dialog-container"
            >
                <DialogTitle id="customized-dialog-title" className="dialog-title" >
                    <Typography variant="h6">USER PROFILE</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <Person />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={fullName} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <Email />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={emailAddress} />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <Call />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={mobile} />
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog >
        </>
    )
}
export default withStyles({ withTheme: true })(React.memo(UserProfile)); 