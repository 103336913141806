import React from 'react';
import { apis } from '../../httpUtil';
import util from '../../util';
import List from '../../components/List';
import swal from 'sweetalert';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import actionTypes from '../../redux/actions/actionType';
import { enums } from '../../Utility/const';
import { history } from '../../redux/store/';
import { useSelector, useDispatch } from 'react-redux';

const isIpad = window.innerWidth <= 1024;
const columns = [
    {
        title: "Mother's Name", field: "fullName", type: 'string', width: isIpad ? "16%" : "16%", cellStyle: {
            color: '#2196F3'
        }
    },
    {
        title: "Birth Date", field: "birthdate", type: 'date',
        render: rowData => { return util.renderer.date('birthdate', rowData) }
    },
    { title: "Cell Number", field: "cellNumber" },
    {
        title: "Created On", field: "createdAt", type: 'date',
        render: rowData => { return util.renderer.date('createdAt', rowData) }
    },
    { title: "Created By", field: "createdBy", type: 'string' },
    {
        title: "Status", field: "caseStatusId", render: rowData => { return util.renderer.combo('caseStatusId', rowData, enums.lookupType.ProfileStatus) },
    },
    {
        title: "Discharged On", field: "infantDischargeDate", type: 'date',
        render: rowData => { return util.renderer.date('infantDischargeDate', rowData) }
    }
]
export const caseDetailFields = [
    { "label": "SSN", "field": "motherSSN" },
    { "label": "Case #", "field": "caseNumber" },
    { "label": "Infant's Name", "field": "infantName" },
    { "label": "Birth Hospital", "field": "infantBirthHospitalId", "comboType": "Hospitals" },
    { "label": "DOB", "field": "infantBirthDate", "type": "date" },
    { "label": "Profile Status", "field": "statusId", "comboType": "ProfileStatus" },
    { "label": "Mother", "field": "motherName" },
    { "label": "Father", "field": "fatherName" },
    { "label": "Dischargd On", "field": "infantDischargeDate", "type": "date" },
    { "label": "Address", "field": "streetAddress1" }
]
let dispatch;
const changeAction = {
    icon: 'edit_outline',
    tooltip: 'Edit',
    onClick: (event, rowData) => {
        history.push('/assessment/' + rowData.caseId + '/task');
        dispatch({ type: actionTypes.SET_ACTIVE_CASEID, activeCaseId: rowData.caseId });
        dispatch({ type: actionTypes.SET_PROFILE_DATA, updatedData: {} });
    }
}

const ExpandIcon = () => {
    return <div><svg id="customExpand" width="22" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99998 3.34417L7.90581 6.25L9.19831 4.9575L4.99998 0.75L0.79248 4.9575L2.09415 6.25L4.99998 3.34417ZM4.99998 14.6558L2.09415 11.75L0.801647 13.0425L4.99998 17.25L9.20748 13.0425L7.90581 11.75L4.99998 14.6558Z" fill='#008000' />
    </svg></div>
}

const descriptionAction = {
    icon: ExpandIcon,
    tooltip: 'Detail Information',
    onClick: (event, rowData) => {
        const wrapper = document.createElement(`div`);
        wrapper.innerHTML = util.detailInfo(rowData, caseDetailFields, util.renderer);
        swal({
            title: "Profile Detail Information",
            content: wrapper
        });
    }
}
const Cases = (props) => {
    dispatch = useDispatch();
    const appTopSearch = useSelector(state => state.appReducer.appTopSearch);
    return (
        <List columns={columns}
            searchValue={appTopSearch}
            doubleClick={true}
            formRoute='/assessment/'
            history={history}
            actions={[changeAction, descriptionAction]}
            defaultSort="createdAt"
            sortDir="desc"
            isCase={true}
            className='table-expand-wrapper-stick'
            listOption={{
                api: apis.case,
                listName: 'caseList',
                reduxActions: 'SET_CASE_LIST'
            }}
            searchValue={props.searchValue}
        />
    )
}
export default React.memo(Cases);