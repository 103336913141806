import * as React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import util from '../util';

const useStyles = makeStyles(theme => ({
    selectBase: {
        marginTop: '1em'
    },
    datePicker: {
        '& .MuiIconButton-root': {
            color: 'rgba(33, 150, 243, 0.87)'
        }
    }
}));
// Date Picker component
const BaseDatePicker = React.memo(function BaseDatePicker(props) {
    const { data, handleChange, formSubmit, records } = props, classes = useStyles();
    let required = false;
    let helperText = '', dateError = false, wrapperClass = '';
    let error = [], dateData = { ...data }, typeId, key, name, newId, autoAddRow;
    if (data) {
        name = data.name;
        typeId = data.typeId;
        key = data.key;
        newId = data.newId;
        autoAddRow = data.autoAddRow;
    }
    let value = null;
    if (records) {
        if (key && typeId) {
            const rec = autoAddRow ? records.find(rec => (Number(rec[key]) === typeId) && (rec['newId'] == newId || rec['id'] === newId)) : records.find(rec => Number(rec[key]) === typeId);
            rec && (value = rec[name]);
        } else {
            value = records[name];
        }
    }


    if ((dateData.warning || dateData.conditionalValid) && !value) {
        helperText = "Warning"
        wrapperClass = 'warning'
    }


    if (formSubmit || (value && value !== undefined)) {
        error = util.validation(data, value, formSubmit);
        if (error && error.length) {
            helperText = error[0].helperText
            dateError = error[0].error
        } else if (value && data.error) {
            dateError = true
        }
    }

    if (data && data.validations && data.validations.includes("required")) {
        required = true;
    }
    if (!value && data && data.group === "childSafetyTags") { // populate data from contacts for consent 
        value = util.getDefaultValueForConsent(typeId, name);
    }
    !dateData.format && (dateData.format = util.datePickerFormat);
    //(value && value.toString() === "Invalid Date") && (value = null)
    !value && (value = null);
    return (<div className={wrapperClass}><MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.selectBase}>
        <KeyboardDatePicker
            className={classes.datePicker}
            {...dateData}
            helperText={helperText}
            id={dateData.id}
            error={dateError}
            value={value}
            onChange={(date) => handleChange(date, data)}
            autoOk={true}
            required={required}
            maxDate={dateData.name.toLowerCase().indexOf('birth') > -1 ? new Date() : new Date().setFullYear(new Date().getFullYear() + 2)}
        />
    </MuiPickersUtilsProvider></div>)
})

export default React.memo(BaseDatePicker);