import * as React from 'react';
import { makeStyles, withStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import BaseTextField from '../components/BaseTextField';
import BaseRadioGroup from '../components/BaseRadioGroup';
import BaseDatePicker from '../components/BaseDatePicker';
import { useDispatch } from 'react-redux';
import BaseSelect from '../components/BaseSelect';
import { useSelector } from 'react-redux';
import moment from 'moment';
import util from '../util';
import swal from 'sweetalert';

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginBottom: '1em',
        '& .MuiFormGroup-row': {
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .MuiInput-formControl': {
            marginTop: '8px !important'
        }
    },
    tableView: {
        '& .MuiTableRow-hover > td:first-child, .MuiTableRow-head th:first-child': {
            display: 'table-cell !important'
        }
    }
});

const StyledTableCell = withStyles(theme => ({
    head: { background: 'rgba(196, 196, 196, 0.31) !important', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 'bold', fontSize: '16px !important' }
}))(TableCell);

let dispatch = null;

const AssessmentNeed = (props) => {
    dispatch = useDispatch();
    const classes = useStyles();
    const { headers, fields, group } = props;
    let records = useSelector(state => state.appReducer[group]);
    const handleChange = (e, fieldInfo, isOther) => {
        let { value, checked } = (e && e.target) || {};
        let { key, typeId, group, name, type, tempTypeId, newId, isMultiDropDown, autoAddRow, index } = fieldInfo;
        let newField = {};
        if (fieldInfo) {
            if (fieldInfo.type === "dateTime") {
                value = e;
            } else if (fieldInfo.type === "multiSelect") {
                value = util.capitalize(value.toString());
                let isAlreadySelected = records.findIndex(e => e.needs == value);
                if (isAlreadySelected > -1) {
                    swal({
                        title: 'Already Exists',
                        icon: "error",
                        dangerMode: true
                    });
                    return false;
                }
                let filterValue = fields.filter(e => e.typeId == fieldInfo.typeId) || [];
                let sortedValue = filterValue.sort((a, b) => (a.newId > b.newId) ? 1 : -1);
                let isAlreadyAdded = fields.filter(e => e.typeId == fieldInfo.typeId && e.lookupId == 0 && e.newId == fieldInfo.newId) || [];
                // Check for existing rows is same
                if (isAlreadyAdded.length < 2 && fieldInfo.newId == sortedValue[0].newId) {
                    let filterField = fields.filter(e => e.typeId == fieldInfo.typeId && !e.newId) || [];
                    if (filterField.length > 0) {
                        newField = { ...filterField[0] };
                        let filterValue = fields.filter(e => e.typeId == fieldInfo.typeId) || [];
                        let sortedValue = filterValue.sort((a, b) => (a.newId > b.newId) ? 1 : -1);
                        if (sortedValue[0].newId <= 0) {
                            newField.newId = sortedValue[0].newId + -1;
                            let index = fields.findIndex(e => e.typeId == fieldInfo.typeId && e.lookupId == 0 && e.newId == sortedValue[0].newId);
                            if (index > -1) {
                                fields[index].lookupId = value;
                            }
                            newField.isMultiDropDown = 1;
                        } else {
                            newField.newId = 0;
                            newField.isMultiDropDown = autoAddRow ? 1 : 0;
                        }
                        newField.lookupId = 0;
                        newField.autoAddRow = true;
                        //fields.splice(index + 1, 0, newField);
                        fields.push(newField);
                    }
                    let isMultiIndex = fields.findIndex(e => e.isMultiDropDown == -1 && e.id == fieldInfo.typeId);
                    if (isMultiIndex > 0) {
                        fields[isMultiIndex].isMultiDropDown = 1;
                    }
                }
            } else if (fieldInfo.format == "cellNumber") {
                if (value.replace(/-/g, '').length > 10) {
                    return false;
                }
            } else {
                value = util.capitalize(value);
            }
        }
        dispatch({
            type: 'SET_FORM_DATA', formData: {
                group: group, key: key, value: {
                    [key]: typeId, [name]: type === "checkbox" ? checked : value,
                    tempTypeId: tempTypeId, isMultiDropDown: !autoAddRow ? 0 : 1, newId: fieldInfo.newId
                }
            }
        });
        if (isOther != undefined && !isOther && newId != 0) {
            dispatch({
                type: 'SET_FORM_DATA', formData: { group: group, key: key, value: { [key]: typeId, other: "" } }
            });
        }
    }
    return (<>
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" className={classes.tableView + ' assesment-cont'}>
                    <TableHead>
                        <TableRow>
                            {headers.map(column => (
                                <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, width: column.width }}
                                >
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((field, index) => {
                            let data = {
                                key: field.key, typeId: field.id, group: group, tempTypeId: field.typeId, newId: field.newId,
                                isMultiDropDown: field.isMultiDropDown, autoAddRow: field.autoAddRow, index: index
                            }
                            let fieldInfo = {};
                            if (field.customStringValue) {
                                fieldInfo = field.customStringValue;
                                fieldInfo = JSON.parse(fieldInfo);
                            }
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                        {
                                            fieldInfo.type === 'multiSelect' ? <BaseSelect records={records} id={data.id} data={{ ...data, ...fieldInfo }} handleChange={handleChange} label={field.displayValue} /> :
                                                field.displayValue
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        <BaseTextField records={records} onChange={handleChange} data={{ ...data, ...{ name: 'referredTo' }, ...{ validations: [{ "type": "length", "min": 2, "max": 300 }] } }} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <BaseTextField records={records} onChange={handleChange} data={{ ...data, ...{ name: 'cellNumber' }, ...{ validations: ["cellNumber"] }, ...{ format: "cellNumber" }, ...{ isPhoneNumber: true } }} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <BaseRadioGroup records={records} onChange={handleChange} data={{ ...data, ...{ name: "familyAcceptedService" } }} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <BaseDatePicker records={records} handleChange={handleChange} data={{ ...data, ...{ name: "referralDate", type: 'dateTime', validations: ["invalid", { "type": "date", "min": "01/01/1900", "max": moment().add(1, 'years').format('MM/DD/YYYY') }] } }} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <BaseDatePicker records={records} handleChange={handleChange} data={{ ...data, ...{ name: 'dayUpdate', type: 'dateTime', validations: ["invalid", { "type": "date", "min": "01/01/1900", "max": moment().add(1, 'years').format('MM/DD/YYYY') }] } }} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    </>)
}

export default React.memo(AssessmentNeed);