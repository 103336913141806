import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import BaseCheckBox from './BaseCheckBox';
import BaseTextField from '../components/BaseTextField';
import BaseWeight from '../components/BaseWeight';
import BaseDatePicker from '../components/BaseDatePicker';
import BaseSignature from '../components/BaseSignature';
import BaseSelect from '../components/BaseSelect';
import BaseAutocomplete from '../components/BaseAutocomplete';
import { useSelector } from 'react-redux';
import util from '../util';
import swal from 'sweetalert';
import { TYPE } from '../Utility/const';
import BaseTimePicker from '../components/BaseTimePicker';
const moment = require('moment');

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        flexGrow: 1,
        padding: '0em 1em',
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiInput-input': {
            height: '1.5em',
            paddingLeft: '.1em',
            fontSize: '.99em'
        },
        '& .MuiGrid-root': {
            lineHeight: 4,
            alignItems: 'center'
        },
        '& .MuiGrid-item': {
            width: '100%',
            fontSize: '.99em',
            marginBottom: '0.2em'
        },
        '& .MuiInput-formControl': {
            marginTop: '0 !important'
        },
        '& .MuiInputBase-root': {
            width: '100%',
            marginTop: '9px'
        },
        '& .MuiInputLabel-animated': {
            fontSize: '14px',
            lineHeight: 1,
            top: '-.6em'
        }
    },

    rootPadding2: {
        padding: '0em 0.2em !important',
    },

    gridBoxRight: {
        paddingLeft: '.5em'

    },
    paper: {
        margin: '.5em 0 .5em 0',
        padding: '2.8em 0em 0.5em 0em'
    },
    subHeader: {
        paddingBottom: '2em',
        fontSize: '.85em',
        color: 'grey',
        paddingLeft: '.5em',
        marginTop: '-.75em'
    }
}));

let dispatch = null, onCheckBoxChange;
const handleChange = (e, fieldInfo) => {
    let { value = "", checked, id } = fieldInfo.type === TYPE.AUTOCOMPLETE ? e ? e : {} : (e && e.target) || {};
    let regx = /[<>]/g;
    if (regx.test(value)) {
        value = value.replace(regx, '');
    }
    let { key, typeId, group, name, type, isClearSignature, format } = fieldInfo;
    switch (type) {
        case TYPE.TIME:
        case TYPE.DATETIME:
            value = e;
            if (name === 'infantDischargeDate' || name === 'infantBirthDate') {
                const isDischargeDate = name === 'infantDischargeDate';
                const secondField = document.getElementById(isDischargeDate ? 'infantBirthDate' : 'infantDischargeDate');
                let secondValue = secondField.value;
                if (secondValue && value && value.toDateString() !== "Invalid Date") {
                    const tempValue = moment(value).format(util.dateFormat);
                    let isValid = true;
                    if (isDischargeDate) {
                        isValid = moment(tempValue).isSameOrAfter(secondValue, 'day');

                    } else {
                        isValid = moment(secondValue).isSameOrAfter(tempValue, 'day');
                    }
                    if (!isValid) {
                        swal({
                            title: "Discharge date can't be earlier than date of birth.",
                            icon: "error",
                            dangerMode: true
                        });
                        return false;
                    }
                }
            }
            break;
        case TYPE.CHECKBOX:
            value = checked;
            break;
        case TYPE.SIGNATURE:
            value = isClearSignature ? '' : e.toDataURL();
            break;
        case TYPE.WEIGHT:
            let lb = document.getElementById("infantWeight")
            let oz = document.getElementById("ozweight")
            if (id === 'infantWeight') {
                value = JSON.stringify({ "lb": value, "oz": oz.value })
            } else {
                value = JSON.stringify({ "lb": lb.value, "oz": value })
            }
            break;
        case TYPE.TEXT:
            value = value.trimLeft();
            value = format != 'email' ? util.capitalize(value) : value;
            const lengthValidation = fieldInfo.validations && fieldInfo.validations.filter(v => v.type === "length");
            if (lengthValidation && lengthValidation.length > 0) {
                if (value.length > lengthValidation[0].max) {
                    return false;
                }
            }
            if (fieldInfo.disableNumber) {
                const reg = /^[a-zA-Z\u00C0-\u00FF\-\.\'\s]*$/;
                if (value && !reg.test(value) || (value === null)) {
                    return false;
                }
            }
            if (fieldInfo.format == "cellNumber") {
                if (value.replace(/-/g, '').length > 10) {
                    return false;
                }
            }
            if (fieldInfo.format == "number") {
                value = value || 0;
            }
            break;
        default:
            break;
    }
    if (e && e.keyCode === 9) {
        value = value && value.trim();
    }
    let setKeyObject = key ? { [key]: typeId, [name]: value } : { [name]: value }
    dispatch({
        type: 'SET_FORM_DATA', formData: { group: group, key: key, value: setKeyObject }
    });
    if (type === TYPE.CHECKBOX && onCheckBoxChange) {
        onCheckBoxChange(e, fieldInfo);
    }
}
// FormGroup - Main Component
const FormGroup = React.memo(function FormGroup(props) {
    const { jsonRecord, key, formSubmit, declamation, group, paperClass } = props;
    onCheckBoxChange = props.onCheckBoxChange;
    dispatch = useDispatch();
    const classes = useStyles();

    let jsonRecordArray = JSON.parse(JSON.stringify(jsonRecord));
    const jsonRow = (jsonRecord && Object.keys(jsonRecordArray)) || 0;
    let records = useSelector(state => state.appReducer[group], []);
    useEffect(() => {
        const childSafetyAgreement = document.getElementById('childSafetyAgreement');
        const childSafetyPOSC = document.getElementById('ChildSafetyPOSC');
        const dfsWorkerName = document.getElementById('dfsWorkerName');
        const dfsWorkerPhoneNo = document.getElementById('dfsWorkerPhoneNo');

        if (childSafetyAgreement && childSafetyPOSC && dfsWorkerName && dfsWorkerPhoneNo) {
            // CMS #214 hide the field
            childSafetyAgreement.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = "none";
            dfsWorkerName.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = childSafetyPOSC.checked ? 'block' : "none";
            dfsWorkerPhoneNo.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = childSafetyPOSC.checked ? 'block' : "none";
        }


        const infantsafeOtherValue = document.getElementById('infantsafeOtherValue');
        const infantsafeOther = document.getElementById('infantsafeOther');
        if (infantsafeOther && infantsafeOtherValue) {
            infantsafeOtherValue.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = infantsafeOther.checked ? 'block' : "none";
        }

        const hospitaleducationValue = document.getElementById('hospitaleducationValue');
        const hospitaleducation = document.getElementById('hospitaleducation');
        if (hospitaleducation && hospitaleducationValue) {
            hospitaleducationValue.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = hospitaleducation.checked ? 'block' : "none";
        }

        const otherRelationshipValue = document.getElementById('otherRelationship');
        const otherRelationship = document.getElementById('relationship');
        if (otherRelationship && otherRelationshipValue) {
            //335 for other relation
            otherRelationshipValue.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = otherRelationship.nextElementSibling.value == '335' ? 'block' : "none";
        }

    }, []);
    let paeprContainer = paperClass || classes.paper;
    return (<div className='form-group-wrapper' key={key}>
        {jsonRow && jsonRow.map((row, index) => {
            return <Paper key={`${index}${key}`} className={paeprContainer} >
                <Grid container className={[classes.root]} spacing={0}>
                    {declamation && <div className="declamation-text">By signing below, Mother, Father or other caregiver(s) acknowledge that this Plan of Safe Care has been prepared, reviewed and thoroughly discussed with the POSC Coordinator.  It is agreed that medical and substance use treatment information may be shared/disclosed with the Plan Participants under this written consent as provided by HIPPA (45 CFR 160, 164) and 42 CFR Part 2.  Mother, Father or other caregiver(s) hereby consent to the sharing of the POSC with the Plan Participants.<br />
                        Plan Participants will regularly communicate and share information to ensure that timely referrals for services are made by the POSC Coordinator and that the appropriate services are delivered to the family.  The POSC Coordinator and Plan Participants will ensure confidentiality of the information received through the POSC.
                        The POSC Coordinator hereby confirms that the Division of Family Services has been notified of the infant’s birth, this POSC has been prepared for the infant and family and a copy of the POSC will be provided to the Plan Participants within 48 hours after this date. </div>}
                    {jsonRecordArray[row].map(data => {
                        let { label, layout, type, items, hidden, typeId = '', multiline, headerLabel } = data;
                        data.group = group || row;
                        const { xs, sm, md, lg } = layout || {};
                        //#367 - Section 5: Hospital Education - Other 
                        let isOthers = (label == "Other" && typeId == 28) || headerLabel;
                        return <>
                            {isOthers && <div className="full-width">{headerLabel && <div className={classes.subHeader}>{headerLabel}</div>}</div>}
                            {!hidden && <Grid container key={label} item xs={xs || 12} sm={sm || 4} md={md || 4} lg={lg || 4} spacing={0} className={row === "consent" ? classes.rootPadding2 : classes.rootPadding}>
                                <Grid item xs={12} sm={12} md={12} lg={12} container className={row === "consent" ? [classes.gridBoxRight, classes.rootPadding2] : [classes.gridBoxRight, classes.rootPadding]}>
                                    <Grid item>
                                        {type === TYPE.CHECKBOX && <Grid item ><BaseCheckBox records={records} labelalign={'right'} data={data} handleChange={handleChange} /></Grid>}
                                        {(type === 'weight') && <BaseWeight records={records} data={data} onChange={handleChange} rowName={row} formSubmit={formSubmit} handleChange={handleChange} />}
                                        {(type === TYPE.TEXT || type === TYPE.NUMBER || type === TYPE.SEARCH) && <BaseTextField typeId={typeId} records={records} data={data} onChange={handleChange} rowName={row} multiline={multiline} formSubmit={formSubmit} />}
                                        {type === TYPE.DATETIME && <BaseDatePicker records={records} handleChange={handleChange} data={data} formSubmit={formSubmit} />}
                                        {type === TYPE.TIME && <BaseTimePicker records={records} handleChange={handleChange} data={data} formSubmit={formSubmit} />}
                                        {type === TYPE.DROPDOWN && <BaseSelect records={records} name={data.name} id={data.id} items={items} data={data} handleChange={handleChange} label={data.label} formSubmit={formSubmit} />}
                                        {type === TYPE.AUTOCOMPLETE && <BaseAutocomplete records={records} name={data.name} id={data.id} items={items} data={data} handleChange={handleChange} label={data.label} formSubmit={formSubmit} />}
                                        {type === TYPE.SIGNATURE && <BaseSignature records={records} handleChange={handleChange} data={data} style={{ width: '100%', position: 'relative' }} padHeight={55} />}
                                    </Grid>
                                </Grid>
                            </Grid>}
                        </>
                    })}
                </Grid>
            </Paper>
        })}
    </div>
    )
});

export default React.memo(FormGroup);