import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      backgroundColor: '#2196f3',
      float: "right",     
      '&:hover': {
        backgroundColor: '#066cbd',
      }
    },
  },
}));

export default function BaseButton(props) {
  const classes = useStyles();
  const { handleClick, label } = props;
  return (
    <div className={classes.root}>
      <Button variant="contained" color="primary" onClick={handleClick}>
        {label}
      </Button>
    </div>
  );
}