import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
    progress: {
        margin: theme.spacing(2)
    }
});

const Loader = (props) => {
    const title = 'Loading...';
    const { classes, isOpen } = props;
    return (
        <>
            {
                isOpen && <Dialog open={true} PaperProps={{ className: 'loader-dialog' }}>
                    <center>
                        <CircularProgress className={classes.progress} color="primary" />
                        {title && <p className="loader">{title}</p>}
                    </center>
                </Dialog>
            }
        </>
    )
}
export default withStyles(styles)(Loader);