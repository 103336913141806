import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, AppBar, Tabs, Tab, Typography } from '@material-ui/core';
import { history } from '../../redux/store/';
import Resource from '../Resource';
import LookupType from './LookupType';
import Lookup from './Lookup';

const resourceTab = 0, lookupTypeTab = 1, lookupTab = 2;

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`dashboard-panel-${index}`}
            aria-labelledby={`dashboard-panel-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </Typography>
    );
}
const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTabs-indicator': {
            backgroundColor: '#01579B !important'
        },
        '& .MuiAppBar-root': {
            zIndex: '1',
            marginBottom: '1.5em'
        }
    }
}));

const AdminPannel = React.memo(function AdminPannel(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = React.useState(0);
    const { params } = props.match;
    const lookupTypeId = params && params.id;
    const lookupList = useSelector(state => state.appReducer.lookupList) || {};
    const lookupTypeName = lookupList.lookupTypeName || '';

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        let url = '';
        switch (newValue) {
            case resourceTab:
                url = '/admin/resource';
                break;
            case lookupTypeTab:
                url = '/admin/lookuptype';
                break;
            case lookupTab:
                return;
        }
        history.push(url);
    };

    useEffect(() => {
        let activeTab = 0;
        console.log('tabIndex', tabIndex)
        switch (params && params.tab) {
            case 'lookuptype':
                activeTab = lookupTypeId ? lookupTab : lookupTypeTab;
                break;
            case 'report':
                //activeTab = reportTab;
                break;
            default:
                break;
        }
        setTabIndex(activeTab);
    }, [])
    return <>
        <div className={`dashboard-tab ${classes.root}`} >
            <Grid container spacing={0}>
                <AppBar position="static" color="default">
                    <Tabs value={tabIndex} onChange={handleChange} indicatorColor="primary">
                        <Tab label="Resource" id={resourceTab} />
                        <Tab label="Types" id={lookupTypeTab} />
                        {tabIndex == lookupTab &&
                            <Tab label={lookupTypeName || 'Lookup'} id={lookupTab} disabled={tabIndex !== lookupTab} />
                        }
                    </Tabs>
                </AppBar>
            </Grid>
            <TabPanel value={tabIndex} index={resourceTab}>
                <Grid item xs={12} >
                    {!lookupTypeId &&
                        <Resource />
                    }
                </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={lookupTypeTab}>
                <Grid item xs={12} >
                    <LookupType />
                </Grid>
            </TabPanel>
            {tabIndex == lookupTab &&
                <TabPanel value={tabIndex} index={lookupTab}>
                    <Grid item xs={12} >
                        <Lookup lookupTypeId={lookupTypeId} />
                    </Grid>
                </TabPanel>
            }
        </div>
    </>
})

export default React.memo(AdminPannel);