import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Pie from '../../components/Pie';
import List from '../../components/List';
import actions from '../../redux/actions/actionType';
import { apis } from '../../httpUtil';
import { useSelector } from 'react-redux';
import Bar from '../../components/Bar';

const useStyles = makeStyles({
    root: {
        marginTop: '1.5rem',
        marginBottom: '3em',
        '& .MuiTableSortLabel-root:hover': {
            color: 'rgb(255, 255, 255)'
        },
        '& .MuiTableSortLabel-icon': {
            marginRight: '0  !important',
            marginLeft: '0  !important'
        },
        '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
            marginRight: '0  !important',
            marginLeft: '0  !important'
        },
        '& .MuiTableCell-head': {
            color: '#FFFFFF',
            padding: '14px 0px !important'
        },
        '& .MuiAppBar-colorDefault': {
            backgroundColor: '#2196f3',
            color: '#FFFFFF'
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            background: '#5062e5'
        },
        '& .pie-header canvas': {
            marginTop: '-2em'
        },
        '& .MuiTableBody-root .MuiTableCell-root': {
            fontSize: '15px !important',
            color: '#000 !important',
            padding: '2em 0em !important'
        }
    },
    pieChart: {
        padding: '0em',
        fontSize: '14px',
    },
    tableWrapper: {
        marginTop: '.2em'
    },
    container: {
        maxHeight: 365,
    }
});
const barChartColors = ['#4472C4', '#ED7D31', '#A5A5A5', '#FFC000'];
const pieChartColor = ['#4472C4', '#ED7D31', '#A5A5A5', '#FFC000'];

/**
 * columns for table showing report
 */
const columns = [
    {
        title: "# of Draft", field: "totalDraft", type: 'string'
    },
    {
        title: "# of Submitted", field: "totalSubmitted", type: 'string'
    },
    {
        title: "# of Completed", field: "totalCompleted", type: 'string'
    },
    {
        title: "# of Closed", field: "totalClosed", type: 'string'
    },
    {
        title: "# of referrals for family members", field: "familyAcceptedServiceCount", type: 'string'
    }
];
/**
 * desc - get chart's formated dataset
 * @param {Array} chartData 
 */
const getDataSet = (chartData, config) => {
    let data = [], result = { labels: [], datasets: [], totalCases: 0 };
    if (chartData && chartData.length > 0) {
        chartData.map(row => {
            if (row.lookupId > -1) {
                result.totalCases += row.lookupId > 0 ? Number(row.totalData) : 0;
                result.labels.push(row.displayValue);
                data.push(row.totalData);
            }

        });
        config.data = data
        result.datasets = [config];
    }
    return result;
}
/**
 * desc - get Chart's formated Data
 * @param {Array} chartData 
 * @param {Object} config 
 */
const getReportChartData = (chartData, config) => {
    let chart = {};
    let formatedData = getDataSet(chartData, config);
    chart.labels = formatedData && formatedData.labels;
    chart.datasets = formatedData && formatedData.datasets;
    chart.totalCases = formatedData && formatedData.totalCases;
    return chart;
}

const pieChartConfig = {
    data: {},
    backgroundColor: pieChartColor,
    hoverBackgroundColor: pieChartColor,
    borderWidth: '0',
    borderColor: 'transparent'
}
const barChartConfig = {
    data: 0,
    backgroundColor: barChartColors,
    borderColor: barChartColors,
    borderWidth: 1,
    barThickness: 14
}

/**
 * desc - report component
 * @param {Object} props 
 */
const CaseReport = (props) => {
    const classes = useStyles();
    const reportData = useSelector(state => state.appReducer.reports);
    let pieChartData = reportData && reportData.chartData && reportData.chartData.pieChartData || [];
    let barChartData = reportData && reportData.chartData && reportData.chartData.barChartData || [];
    let doughnutChart = getReportChartData(pieChartData, pieChartConfig);
    let barData = getReportChartData(barChartData, barChartConfig);
    let [searchValue, setSearchValue] = useState({ searchPramas: '' });
    searchValue = Object.assign(searchValue, { dateRange: props.dateRange });
    return (
        <div className={classes.root}>
            <Grid container justify="center">
                {
                    doughnutChart && doughnutChart.totalCases > 0 ?
                        <Grid item xs={5} md={5} lg={5} align="center">
                            <Pie data={doughnutChart} chartHeader={'Report'} pieFooterText={`${doughnutChart.totalCases} case(s)`} fromAward={true} />
                        </Grid> : null
                }
                {doughnutChart && doughnutChart.totalCases ? <Grid item xs={7} md={7} lg={7} align="center" className="pdl-1">
                    <Bar barData={barData} chartHeader="Case Number" yAsisLabel="Case Status Type" xAxisLabel="Total Cases" fromAward={true} />
                </Grid> : null
                }
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} className={classes.tableWrapper}>
                    <List isExpand={false}
                        fromReport={true}
                        columns={columns}
                        fromDashboard={true}
                        options={{ toolbar: true }}
                        tableHeader='Reports'
                        searchValue={searchValue}
                        isShowPaging={false}
                        disableSort={true}
                        listOption={{ api: apis.case, customAction: 'report', listName: 'reports', reduxActions: actions.SET_REPORT_LIST }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default CaseReport;
