import React, { useState, useEffect } from 'react';
import CaseList from './case';
import { Button, makeStyles } from '@material-ui/core';
import actions from '../../redux/actions/actionType';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { history } from '../../redux/store';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

let dispatch = null;

const onAdd = () => {
    history.push('/assessment/0');
    dispatch({ type: actions.SET_ACTIVE_CASEID, activeCaseId: 0 });
    dispatch({ type: actions.CLEAR_PROFILE_DATA });
    dispatch({ type: actions.SET_ACTIVE_TAB, activeTab: 3 });
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    search: {
        position: 'relative',
        background: '#EDEDED',
        borderRadius: '5px',
        marginLeft: '1em'
    },
    searchIcon: {
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        marginLeft: '-0.5em',
        color: '#676767'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    },
    gridBtnCont: {
        textAlign: 'right'
    }
}));

const CaseScreen = (props) => {
    dispatch = useDispatch();
    const caseScroll = useSelector(state => state.appReducer.caseScroll);
    const [searchValue, setSearchValue] = useState({ searchPramas: '', isSubmit: false });
    const gridPaging = useSelector(state => state.appReducer.gridPaging);
    const [isSubmit, setIsSubmit] = useState(false);
    const classes = useStyles();
    const [search, setSearch] = useState('')
    const onScroll = (a) => {
        setTimeout(() => {
            dispatch({ type: actions.SET_SCROLL, caseScroll: a.target.scrollTop });
        }, 1000);
    };
    const onSearch = (event) => {
        let { value = '' } = event.target;
        setSearch(value);
        setIsSubmit(false);
    }
    const handleSearch = (event) => {
        event.preventDefault();
        setSearchValue({ ...searchValue, ...{ searchPramas: search } });
        setIsSubmit(event.type !== "blur")
        dispatch({
            type: actions.SET_PAGING, gridPaging: { ...gridPaging, ...{ pageNo: 0 }, searchValue: { searchPramas: search } }
        });
    }

    const mouseDownSearch = (event) => {
        if (!isSubmit) {
            handleSearch(event);
        }
    }

    return (
        <div className="case-section" id="case-container">
            <Paper elevation={0}>
                <div className="paper-header-text br-b">CASELOAD</div>
                <div className="case-search">
                    <Grid container spacing={2}>
                        <Grid item xs={12} container spacing={0} style={{ marginRight: '2em' }}>
                            <Grid item xs={5} md={4} lg={3}>
                                <form autoComplete="off" onSubmit={handleSearch} >
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder="Search"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            onChange={onSearch}
                                            onBlur={mouseDownSearch}
                                            inputProps={{ 'aria-label': 'search' }}
                                            defaultValue={gridPaging.searchValue && gridPaging.searchValue.searchPramas || ''}
                                        />
                                    </div>
                                </form>
                            </Grid>
                            <Grid item xs={7} md={8} lg={9} className={classes.gridBtnCont} >
                                <Button variant="contained" onClick={onAdd}>
                                    <span className="pdr-5">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.25 6.75H6.75V11.25H5.25V6.75H0.75V5.25H5.25V0.75H6.75V5.25H11.25V6.75Z" fill="#2196F3" />
                                        </svg>
                                    </span>
                                    Add Case
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <CaseList history={props.history} searchValue={searchValue} />
            </Paper>
        </div >
    )
}

export default CaseScreen;