import React, { useState, useEffect, useRef } from 'react';
import loader from './loader';
import LoaderSvg from './loader.svg';
import AudioPlayer from './audio-player';
import DialingTone from './dialing.mp3';
import CallingTone from './calling.mp3';
import DisconnectTone from './end_call.mp3';
import axios from 'axios';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../redux/actions/actionType';
import util from '../../../../util';
import ReactModal from 'react-modal-resizable-draggable';
import { VideoCallActivityEnums } from '../../../../Utility/const';
import moment from "moment";

const defaultCredentials = {
    appId: 3506,
    authKey: 'fePCDpDUzgmHn--',
    authSecret: 'VjCaCf5QFTENeZP'
};

const defaultConfig = {
    debug: false
};

let session = null;

let mediaParams = {
    audio: true,
    video: true,
    elementId: "localStream",
    options: {
        muted: true,
        mirror: true
    }
};

const CallStates = {
    StartingCall: 1,
    IncomingCall: 2,
    AcceptedCall: 3,
    Disconnected: 4
};

let mediaSources = null;

let mediaSourceIndex = 0;

function Index({
    user,
    connectTo,
    credentials = defaultCredentials,
    onStateChange,
    onIncomingReject,
    onIncoming,
    appConfig = defaultConfig,
    incomingCallFrom,
    isOpen,
    hideCallingPopup,
    ...props
}) {
    const [callInfo, setCallInfo] = useState({ callState: CallStates.Disconnected });
    const [loggedIn, setLoggedIn] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const localStreamRef = useRef(null);
    const remoteStreamRef = useRef(null);
    const userData = useSelector(state => state.appReducer.userData);
    const dispatch = useDispatch();
    const { webUserId } = user;
    const { unansweredVideoCall, answeredVideoCall, rejectedVideoCall } = VideoCallActivityEnums;
    const disconnect = () => {
        console.log('disconnecting...');
        if (localStreamRef.current) {
            localStreamRef.current.src = null;
            remoteStreamRef.current.src = null;
        }
        if (session) {
            setCallInfo({ ...callInfo, callState: CallStates.Disconnected, mute: false });
            setAudioUrl(DisconnectTone);
            console.log('disconnecting session...');
            session.stop({});
            const cc = window.ConnectyCube;
            cc.videochat.clearSession(session.ID);
            session = null;
        }
        session = null;
        setIsOpenPopup(false);
        onStateChange({ disconnected: true });
        dispatch({ type: actions.OPEN_VIDEO_CALL, remoteUserData: { openVideoCall: false, id: '', name: '' } });
    }
    const onStopCall = (sessionInfo, userId) => {
        if (session.initiatorID === session.currentUserID) {
            addCallActivityDetails({
                activityName: answeredVideoCall,
                fromUserId: webUserId,
                userId: userId,
                startCallTime: session.startCallTime
            });
        }
        hideCallingPopup();
        disconnect();
    }

    const startOwnVideo = async (session) => {
        let stream;
        while (session && !stream) {
            try {
                stream = await session.getUserMedia(mediaParams);
            } catch (err) {
                console.log("Error in getUserMedia");
                if (err.message === "Could not start video source" || err.message === 'Requested device not found') {
                    let videoInputIndex = 0, canRetry = false;
                    for (const mediaSource of mediaSources) {
                        if (mediaSource.kind === "videoinput") {
                            if (videoInputIndex > mediaSourceIndex) {
                                mediaSourceIndex = videoInputIndex;
                                mediaParams.video = { deviceId: mediaSource.deviceId };
                                canRetry = true;
                                console.log("trying with " + mediaSource.label);
                                break;
                            }
                            videoInputIndex++;
                        }
                    }
                    if (!canRetry) {
                        disconnect();
                        session = null;
                        swal({
                            title: "No video chat possible. Your device does not have a camera.",
                            icon: "info"
                        });
                    }
                };
                console.error(err);
            }
        }
        return stream;
    }

    useEffect(() => {
        const cc = window.ConnectyCube;
        if (session) {
            disconnect();
            session = null;
        }
        const connecting = connectTo !== null && typeof (connectTo) === 'object';
        if (!connectTo) {
            return;
        }
        const options = {};
        session = cc.videochat && cc.videochat.createNewSession([connectTo.id], cc.videochat.CallType.VIDEO, options);

        setCallInfo({ ...callInfo, callState: CallStates.StartingCall });

        startOwnVideo(session).then((stream) => {
            if (!stream || !session) {
                disconnect();
                return;
            }
            if (!session.getDisplayMedia) {
                // todo: disable screen sharing
            }
            setAudioUrl(connecting ? DialingTone : null);
            session.call({});
            //this.setActiveDeviceId(stream);
            //this._prepareVideoElement("localStream");
        });

    }, [connectTo]);

    const onIncomingCall = (newSession, extension) => {
        if (newSession.initiatorID === newSession.currentUserID) {
            return false;
        }
        if (session) {
            if (session.ID === newSession.ID) {
                return;
            }
            newSession.reject({ busy: true });
            onIncomingReject({ userId: newSession.initiatorID, reason: 'busy' });
            return false;
        }
        session = newSession;
        onIncoming({ userId: newSession.initiatorID });
        setCallInfo({ ...callInfo, callState: CallStates.IncomingCall });
        setAudioUrl(CallingTone);
        return true;
    }

    const onRemoteStream = (session, userId, stream) => {
        // todo: remove loader
        session.attachMediaStream('remoteStream', stream)
    }

    const onAcceptCallListener = async (callSession, userId, extension) => {
        if (userId === callSession.currentUserID) {
            console.log("You have accepted the call on other side");
            hideCallingPopup();
            setAudioUrl(null);
            //disconnect();
            return;
        }
        console.log('accept by remote user');
        setCallInfo({ ...callInfo, callState: CallStates.AcceptedCall, mute: false });
        setAudioUrl(null);
    }

    const onAcceptCall = async () => {
        setIsOpenPopup(true)
        const stream = await startOwnVideo(session);
        if (!session) {
            return
        }
        if (!session.getDisplayMedia) {
            // todo: disable screen sharing
        }

        session.accept({});
        //  onIncomingReject({ ...incomingCallFrom, reason: 'accepted by user' });
        setCallInfo({ ...callInfo, callState: CallStates.AcceptedCall, mute: false });
        setAudioUrl(null);
    }

    const onRejectCall = () => {
        if (!session) {
            return;
        }

        const opponentId = session && session.initiatorID === session.currentUserID ? session.opponentsIDs[0] : session.initiatorID;
        const isConnected = session && session.peerConnections && session.peerConnections[opponentId] && session.peerConnections[opponentId].connectionState === 'connected';
        if (session.initiatorID === session.currentUserID) {
            addCallActivityDetails({
                activityName: isConnected ? answeredVideoCall : unansweredVideoCall,
                fromUserId: webUserId,
                userId: opponentId,
                startCallTime: (session && session.startCallTime) || moment()
            });
        }
        isConnected ? session.stop({}) : session.reject({});
        disconnect();
        setAudioUrl(null);
        session = null;
        onIncomingReject({ ...incomingCallFrom, reason: 'rejected by user' });
    }

    const login = async () => {
        const cc = window.ConnectyCube;
        cc.init(credentials, appConfig);
        const session = await cc.createSession(user);
        cc.chat.connect({ userId: user.id, password: user.password });

        cc.videochat.onCallListener = onIncomingCall;
        // todo: accept call, remote stream and device change?
        cc.videochat.onAcceptCallListener = onAcceptCallListener;
        cc.videochat.onRemoteStreamListener = onRemoteStream;
        //cc.videochat.onDevicesChangeListener = onDeviceChange;

        cc.videochat.onStopCallListener = onStopCall;
        cc.videochat.onUserNotAnswerListener = notAnsweredCallListener;
        cc.videochat.onRejectCallListener = onRejectCallListener;

        setLoggedIn(true);
    }

    const getUserById = async (userId) => {
        let appUser = {};
        await axios(`${util.mobileAppBaseUrl}/users/appUsersList`).then(data => {
            if (data && data.data && data.data.result) {
                let appUserData = data.data.result.filter(e => Number(e.connectyCubeId) == Number(userId)) || [];
                if (appUserData.length > 0) {
                    appUser = appUserData[0];
                }
            }
        })
        return appUser;
    }

    const onRejectCallListener = async (session, userId, extension = {}) => {
        if (userId === session.currentUserID) {
            session = null;
            disconnect();
            // swal({
            //     title: "You have rejected the call on other side",
            //     icon: "info",
            //     dangerMode: true
            // });
            return false;
        } else {
            const userData = await getUserById(userId);
            disconnect();
            swal({
                title: extension.busy ? `${userData.fullName} is busy` : `${userData.fullName} rejected the call request`,
                icon: "info",
                dangerMode: true
            });
        }
        addCallActivityDetails({
            activityName: rejectedVideoCall,
            fromUserId: webUserId,
            userId: userId,
            startCallTime: session.startCallTime
        });
    };

    const onMuteUnmute = () => {
        if (!session) {
            return;
        }
        const { mute } = callInfo;
        console.log(`Currently mute: ${mute}`);
        if (mute) {
            session.unmute("audio");
        } else {
            session.mute("audio");
        }
        setCallInfo({ ...callInfo, mute: !mute });
    }

    useEffect(() => {
        navigator.mediaDevices && navigator.mediaDevices.enumerateDevices().then((sources) => {
            mediaSources = sources;
        });
        if (user.id) {
            loader({ callback: login })
        } else {
            disconnect();
            setLoggedIn(false);
        }

        // todo: clean-up and session clear
    }, [user.id]);

    let loaderText = "";


    const cc = window.ConnectyCube;
    const callState = callInfo.callState;
    const inCall = callState === CallStates.AcceptedCall;
    const containerClassName = callState === CallStates.AcceptedCall || callState === CallStates.StartingCall ? "videochat-in-call" : "videochat-hidden";

    console.log(`CallState: ${callInfo.callState}`);

    let outerClass = "videochat-hidden";
    if (callState !== CallStates.Disconnected) {
        outerClass = "videochat-container";
    }

    if (loggedIn) {
        if (inCall) {
            // do nothing;
        } else if (connectTo) {
            loaderText = "Connecting to:" + JSON.stringify(connectTo);
        }
    } else {
        loaderText = "Logging in...";
    }
    /**
     *  desc-not answered call listener
     * 
     * @param {Object} session 
     * @param {Number} userId 
     */
    const notAnsweredCallListener = (session, userId) => {
        addCallActivityDetails({
            activityName: unansweredVideoCall,
            fromUserId: webUserId,
            userId: userId,
            startCallTime: session.startCallTime
        });
        disconnect();
    }
    /**
     *  desc- add call activity (call duration call type initiator etc.)
     * @param {Object} options - consisite of activityName, toUserId, fromUserId and callDuration
     */
    const addCallActivityDetails = async (options) => {
        const { activityName, fromUserId, startCallTime, userId } = options;
        const callDuration = moment().diff(moment(startCallTime), 'seconds');
        const data = await getUserById(Number(userId));
        if (activityName !== '' && data && fromUserId && data.userId) {
            const { userId } = data;
            await axios({
                method: 'POST', url: `${util.videoCallAPIEndPoints.addCallActivity}`,
                data: {
                    callDurationInSecs: callDuration,
                    calledByUser: fromUserId,
                    calledUserType: "web",
                    calledToUser: userId,
                    displayValue: activityName,
                    userIds: `${fromUserId},${data.userId}`,
                    createdFrom: "web"
                }
            });
        }
    }
    return (<>
        { user && user.id ?
            <div>
                <AudioPlayer url={audioUrl} loop={audioUrl && audioUrl.indexOf("end_call") === -1} autoPlay={true} />
                <div class={outerClass}>
                    <ReactModal initWidth={800} initHeight={400} isOpen={(isOpen || isOpenPopup)}>
                        <div className={containerClassName}>
                            <div className="videochat-stream-container">
                                <video playsInline id="remoteStream" className="videochat-stream" ref={remoteStreamRef}></video>
                                {loaderText ? <div className="videochat-stream-loader">
                                    <div className="videochat-stream-loader-text">{loaderText}</div>
                                    <div className="videochat-stream-loader-spinner"><img src={LoaderSvg} alt="Loading..." /></div>
                                </div> : null}
                            </div>
                            <div className="videochat-stream-container">
                                <video playsInline id="localStream" className="videochat-stream" ref={localStreamRef}></video>
                            </div>
                            <div className="videochat-buttons-container">
                                <button className={"videochat-mute-unmute" + (callInfo.mute ? " muted" : "")} disabled={!inCall} onClick={onMuteUnmute}></button>
                                <button className="videochat-stop-call" onClick={onRejectCall}></button>
                            </div>
                        </div>
                    </ReactModal>
                </div>
                <div className={incomingCallFrom ? "videochat-incoming" : "videochat-hidden"}>
                    <div className="videochat-stream-loader">
                        <div className="videochat-stream-loader-text">Incoming call from {incomingCallFrom && incomingCallFrom.name}</div>
                        <div className="videochat-stream-loader-spinner"><img src={LoaderSvg} alt="Loading..." /></div>
                    </div>
                    <div className="videochat-buttons-container incoming-videochat-buttons">
                        <button className="videochat-call" onClick={onAcceptCall}></button>
                        <button className="videochat-stop-call" onClick={onRejectCall}></button>
                    </div>
                </div>
            </div>
            : null
        }
    </>
    )
}

export default Index;