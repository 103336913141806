import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
// import actions from '../redux/actions/actionType';
import { enums } from '../Utility/const';
import swal from 'sweetalert';
import Button from '@material-ui/core/Button';
import util from '../util';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'relative',
        margin: '.5em .5em .9em .5em',
        padding: '2.8em 0em 1.5em 0em',
        '& .MuiFormLabel-root': {
            fontSize: '14px'
        }
    },
    removeBtn: {
        position: 'absolute',
        right: '-10px',
        top: '-7px;',
        color: 'red'
    },
    addButn: {
        background: '#E3F2FD',
        boxShadow: '0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 24%)',
        color: '#2196F3',
        width: '100px',
        marginTop: '1.5em'
    },
    itemPadding: {
        paddingRight: '0.5em',
        lineHeight: '4.5'
    },
    itemCont: {
        padding: '0em 1em'
    },
    datePicker: {
        '& .MuiSvgIcon-root': {
            color: 'rgba(33, 150, 243, 0.87)'
        }
    }
}));

const MonitoringForm = React.memo(function MonitoringForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const combos = useSelector(state => state.appReducer["comboData"]);
    const monitoringTypeData = combos && combos[enums.lookupType["monitoringType"]];
    // find monitoring contact type - to Do : Optimize code later
    let monitoringFields = useSelector(state => state.appReducer.monitoring);
    const caseData = useSelector(state => state.appReducer.caseData) || {};

    useEffect(() => {
        if (monitoringFields && monitoringFields.length === 0) {
            addMonitoring(true)
        }
    }, [])

    const getUpdateDate = (key, infantDischargeDate) => {
        let updateDate = 0;
        let previousDaysIndex = key > 90 && monitoringFields.findIndex(e => e.monitoringTypeId == (Number(key) - 1) && e.updatedDate);
        let defaultDate = (typeof (previousDaysIndex) == 'number' && previousDaysIndex > -1) && monitoringFields[previousDaysIndex].updatedDate;
        switch (key) {
            case 90:
                updateDate = moment(infantDischargeDate).add(14, 'days');
                break;
            case 91:
                updateDate = moment(defaultDate ? defaultDate : infantDischargeDate).add(defaultDate ? 14 : 28, 'days');
                break;
            case 92:
                updateDate = moment(defaultDate ? defaultDate : infantDischargeDate).add(defaultDate ? 14 : 42, 'days')
                break;
            case 93:
                updateDate = moment(defaultDate ? defaultDate : infantDischargeDate).add(defaultDate ? 14 : 56, 'days')
                break;
            case 94:
                updateDate = moment(defaultDate ? defaultDate : infantDischargeDate).add(defaultDate ? 14 : 70, 'days')
                break;
            case 95:
                updateDate = moment(defaultDate ? defaultDate : infantDischargeDate).add(defaultDate ? 14 : 84, 'days')
                break;
        }
        return updateDate;
    }


    const handleMonitoringChange = (event, fieldInfo, type) => {
        let { name, value } = (event && event.target) || {};
        if (type === 'date') {
            name = 'updatedDate';
            value = event || 0;
        } else if (type === 'text') {
            value = util.capitalize(value)
        }
        let data = { tempMonitoringTypeId: fieldInfo.tempMonitoringTypeId, [name]: value };
        if (name === 'monitoringTypeId') {
            const monitoringIndex = monitoringFields.findIndex(d => d.monitoringTypeId === value && d.isDeleted == 0);
            if (monitoringIndex > -1) {
                swal({
                    title: 'Already Exists',
                    icon: "error",
                    dangerMode: true
                });
                return false;
            }
            if (caseData.infantDischargeDate && value) {
                data.updatedDate = getUpdateDate(value, caseData.infantDischargeDate);
            }
        } else if (name === "notes") {
            const tempRecord = monitoringFields.filter(d => d.tempMonitoringTypeId === fieldInfo.tempMonitoringTypeId);
            if (!caseData.infantDischargeDate && !tempRecord.updatedDate) {
                data.updatedDate = new Date();
            }
        }
        if (fieldInfo.defaultAddSection) {
            data['defaultAddSection'] = false;
        }
        dispatch({
            type: 'SET_FORM_DATA', formData: { group: 'monitoring', key: 'tempMonitoringTypeId', value: data }
        });
    }

    const addMonitoring = (defaultAdd) => {
        const keyId = monitoringFields.length + 1;
        dispatch({
            type: 'SET_FORM_DATA', formData: { group: 'monitoring', key: 'tempMonitoringTypeId', value: { tempMonitoringTypeId: keyId * -1, defaultAddSection: defaultAdd == true } }
        });
    }

    const removeMonitoring = (index) => {
        dispatch({
            type: 'SET_FORM_MONITORING_REMOVE', formDataRemove: { groupRemove: 'monitoring', index: index }
        });
    }

    return <>
        {monitoringFields && monitoringFields.map((data, index) => {
            data.tempMonitoringTypeId = index + 1 * -1;
            let isDeleted = data && data.isDeleted || 0;
            return (
                !isDeleted && <Paper className={classes.paper} key={index}>
                    <Grid container spacing={0} className={classes.itemCont}>
                        <Grid container item xs={12} sm={4} md={4} lg={4} spacing={0} className={classes.itemPadding}>
                            <Grid item xs={12} sm={12} md={12} lg={12} container >
                                <Grid item>
                                    <FormControl >
                                        <InputLabel >{`Day Update:`}</InputLabel>
                                        <Select labelId="dropdown" onChange={(e) => handleMonitoringChange(e, data)} value={(data && data['monitoringTypeId']) || ''} inputProps={{
                                            name: 'monitoringTypeId',
                                            id: `monitoringTypeId${index + 1}`
                                        }} >
                                            {monitoringTypeData && monitoringTypeData.map(item => <MenuItem key={item.value ? item.value : item} value={item.value ? item.value : item}>{item.label ? item.label : item}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={4} md={4} lg={4} spacing={0} className={classes.itemPadding}>
                            <Grid item xs={12} sm={12} md={12} lg={12} container >
                                <Grid item>
                                    <TextField
                                        id={`monitoring${index + 1}`}
                                        label={`Notes`}
                                        value={(data && data['notes']) || ''}
                                        onChange={(e) => handleMonitoringChange(e, data, 'text')}
                                        name="notes"
                                        autoComplete={`off-monitoring${index + 1}`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={4} md={4} lg={4} spacing={0} >
                            <Grid item xs={12} sm={12} md={12} lg={12} container >
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.selectBase}>
                                        <KeyboardDatePicker
                                            label={`Update Date`}
                                            value={(data && data['updatedDate']) || null}
                                            onChange={(e) => handleMonitoringChange(e, data, 'date')}
                                            format="MM/dd/yyyy"
                                            autoOk={true}
                                            minDate={new Date('01/01/1900')}
                                            maxDate={new Date('01/01/2100')}
                                            maxDateMessage={'Date should not be after (01/01/2100) date'}
                                            minDateMessage={'Date should not be before (01/01/1900) date'}
                                            //disabled={caseData.infantDischargeDate}
                                            inputProps={{
                                                name: 'updatedDate',
                                                id: `monitoringupdatedDate${index + 1}`
                                            }}
                                            className={classes.datePicker}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        {index > 0 && <RemoveCircleIcon onClick={() => removeMonitoring(index)} className={classes.removeBtn} />}
                    </Grid>
                </Paper>
            )
        })}
        <Button className={`monitoring-add-button ${classes.addButn}`} variant="contained" onClick={addMonitoring}><span style={{ marginRight: '.3em' }}>{util.addIcon}</span> Add</Button>
    </>
})

export default React.memo(MonitoringForm);