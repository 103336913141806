import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import { routes } from '../routes';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import util from '../util';
import Breadcrumb from './Breadcrumb';

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <Typography
            key={index}
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className='tab-child'
        >
            {value === index && <Box>{
                children
            }</Box>}
        </Typography>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


let dispatch = null, activeTab = 0, activeCaseId, caseRecord;
const handleChange = async (event, newValue, props) => {
    const canChange = await util.preventData();
    if (canChange) {
        const activeRoute = routes.find(rec => rec.tabIndex === newValue);
        let path = activeRoute.path;
        if (path.indexOf('/:id')) {
            path = path.replace(":id", activeCaseId);
        }
        props.history.push(path);
        dispatch({ type: 'SET_TITLE_NAME', appTitleName: activeRoute.title });
    }
};
const getActiveTab = (props) => {
    let pathName = props.location.pathname;
    if (props.match && props.match.params.id) {
        pathName = props.location.pathname.replace(props.match.params.id, ':id');
    }
    const activeRoute = routes.find(rec => rec.path === pathName);
    if (activeRoute) {
        activeTab = activeRoute.tabIndex;
    }
    return activeTab;
}

function Tablayout(props) {
    const classes = useStyles();
    dispatch = useDispatch();
    activeTab = getActiveTab(props);
    activeCaseId = useSelector(state => state.appReducer.activeCaseId);
    caseRecord = useSelector(state => state.appReducer.caseData);
    let routesData = [...routes];
    // remove dashboard route with Id
    const dashboardIndex = routesData.findIndex(x => x.path === "/dashboard/:id")
    if (dashboardIndex > -1) {
        routesData.splice(dashboardIndex, 1);
    }
    if (!activeCaseId || props.location.pathname === "/dashboard" || props.location.pathname === "/cases") {
        if (activeTab === 2) {
            routesData = routesData.splice(0, 3);
        } else {
            routesData = routesData.splice(0, 2);
        }

    }
    return (
        <div className={classes.root}>
            {/* <Breadcrumb {...props} /> */}
            <Paper>
                <AppBar position="static" >
                    <Tabs value={activeTab} onChange={(event, newValue) => { handleChange(event, newValue, props) }}>
                        {
                            routesData &&
                            routesData.map((tab) => { return (tab.tabComponent && <Tab key={tab.title} className={tab.title === 'Dashboard' ? "dashboard-tab-header" : ''} label={tab.title} />) })
                        }
                    </Tabs>
                    {
                        routesData &&
                        routesData.map((tab, index) => {
                            return (tab.tabComponent && <TabPanel value={activeTab} index={index} key={index}>{tab.tabComponent}</TabPanel>)
                        }
                        )
                    }
                </AppBar>
            </Paper>
        </div >
    );
}
export default React.memo(Tablayout);