import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from './FormGroup'
import BaseButton from './BaseButton';

const FormDialog = (props) => {
    let { id, handleClose, open, formSubmit, formFields, title, onSave, dataGroup } = props;
    title = title + (id > 0 ? ' - Edit' : ' - New');
    return (
        <>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
                style={{ zIndex: '99999' }}
                className="dialog-container"
            >
                <DialogTitle className="dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <FormGroup formSubmit={formSubmit} group={dataGroup} jsonRecord={[formFields]} key="TaskForm" />
                </DialogContent>
                <DialogActions >
                    <BaseButton label="Cancel" handleClick={handleClose} />
                    <BaseButton label="Submit" handleClick={onSave} />
                </DialogActions>
            </Dialog >
        </>
    )
}

export default FormDialog;