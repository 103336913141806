import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useSelector } from 'react-redux';
import { enums } from '../Utility/const';
import TextField from '@material-ui/core/TextField';

const SelectComp = React.memo(function SelectComp(props) {
    let error = false, other = '';
    const { name, id, handleChange, data, formSubmit, records, label } = props;
    const combos = useSelector(state => state.appReducer["comboData"]);
    let required = false;
    const { key, typeId, comboType, type, group, newId, autoAddRow } = data;
    let { items } = props;
    if (!items && comboType) {
        items = combos && combos[enums.lookupType[comboType]]
    }
    const fieldName = name ? name : data.name;
    let value = 0;
    if (records) {
        if (key && typeId) {
            const rec = autoAddRow ? records.find(rec => (Number(rec[key]) === typeId) && (rec['newId'] == newId || rec['id'] === newId)) : records.find(rec => Number(rec[key]) === typeId);
            if (rec) {
                value = rec[fieldName];
                other = rec['other'];
            }
        }
        else {
            value = records[fieldName];
            other = records['other'];
        }
    }
    if (formSubmit || (value && value !== undefined)) {
        if (!value && data.validations && data.validations[0] === "required") {
            error = true
        }
    }

    const isMultiSelect = type === 'multiSelect';
    if (isMultiSelect && value) {
        value = value.split(',');
        let values = [];
        value.forEach((val) => { values.push(Number(val)) });
        value = values;
    }

    if (data && data.validations && data.validations.includes("required")) {
        required = true;
    }

    let otherCustomeField = false;
    if (group === "motherNeeds" && Array.isArray(value)) {
        let otherIndex = items.findIndex(d => d.label === "Other");
        if (otherIndex > -1) {
            otherCustomeField = value.includes(items[otherIndex].value)
        }

    }
    if (group === "careGiverContact" && name == "relationId") {
        const otherRelationshipValue = document.getElementById('otherRelationship');
        if (otherRelationshipValue) {
            otherRelationshipValue.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = Number(value) == enums.lookup.otherRelation ? 'block' : 'none';
        }
    }

    return <FormControl error={error} required={required}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select labelId="dropdown" value={value || []} name={fieldName} id={id} onChange={(e) => handleChange(e, data, otherCustomeField)}>
            {items && items.map(item => <MenuItem key={item.value ? item.value : item} value={item.value ? item.value : item}>{item.label ? item.label : item}</MenuItem>)}
        </Select>
        {otherCustomeField && <TextField autoComplete={`off-${fieldName}-${other}`} name={`other`} value={other} onChange={(e) => handleChange(e, { ...data, ...{ name: `other`, type: 'text' } })} />}
    </FormControl>
});

export default React.memo(SelectComp);