import axios from 'axios';
import swal from 'sweetalert';
import { history } from '../redux/store/';

const request = async (url, params) => {
    let reqParams = Object.assign({
        method: 'POST',
        withCredentials: true,
        url: url
    });
    if (params) {
        reqParams.data = params;
    }
    try {
        let response = await axios(reqParams);
        let data = response.data;
        if (response) {
            if (response === 'Unauthorized' || response.status === 401) {
                swal({
                    title: "Your session has expired. Please login again.",
                    text: "",
                    icon: "error",
                    dangerMode: true
                });
                history.push('/');
            } else if (response.status === 200 && data.success) {
                return data;
            } else {
                return data;
            }
        } else {
            return data;
        }
    } catch (ex) {
        let error = '';
        if (ex == null || ex == undefined) {
            error = "Network error.";
            swal({
                title: 'Error',
                text: error,
                icon: "error",
                dangerMode: true
            });
            return;
        } else {
            let message = ex.message;
            if (ex.message.indexOf(401) > -1) {
                message = "Your session has expired. Please login again";
                history.push('/');
            }
            message = ex.message.indexOf(500) > -1 ? "An error occurred. Please try again later." : message;
            swal({
                title: "Error",
                text: message,
                icon: "error",
                dangerMode: true
            });
        }
        return { error: error };
    }
}
export default request;