import moment from "moment";
export const consts = {
    NewPlan: 'New Plan',
    ExistingPlan: 'Existing Plan',
    SAFE4BOTH: 'SAFE4BOTH',
    Assessment: 'Profile',
    Tasks: 'TASK(TO DO LIST)',
    Appointments: 'Appointments',
    Awards: 'Awards',
    Rewards: 'Rewards',
    Login: 'Login',
    Cases: 'Cases',
    Profile: 'Profile',
    Dashboard: 'Dashboard',
    DashboardList: 'Dashboard/List',
    Resource: 'Resource',
    ConsentStaticInformation: "By signing below, Mother, Father or other caregiver(s) acknowledge that this Plan of Safe Care has been prepared, reviewed and thoroughly discussed with the POSC Coordinator.  It is agreed that medical and substance use treatment information may be shared/disclosed with the Plan Participants under this written consent as provided by HIPPA (45 CFR 160, 164) and 42 CFR Part 2.  Mother, Father or other caregiver(s) hereby consent to the sharing of the POSC with the Plan Participants. Plan Participants will regularly communicate and share information to ensure that timely referrals for services are made by the POSC Coordinator and that the appropriate services are delivered to the family.  The POSC Coordinator and Plan Participants will ensure confidentiality of the information received through the POSC. The POSC Coordinator hereby confirms that the Division of Family Services has been notified of the infant’s birth, this POSC has been prepared for the infant and family and a copy of the POSC will be provided to the Plan Participants within 48 hours after this date."
}

export const ACTION_API = {
    LIST: 'list',
    DELETE: 'delete',
    SAVE: 'save',
    LOAD: 'load'
}

export const enums = {
    lookup: {
        sibling: 243,
        otherSupportPerson: 67,
        infrantSafetySignatureName: 326,
        infrantSafetySignature: 36,
        infantNeedsOther: 14,
        motherSubstanceAbuse: 15,
        motherMentalHealth: 17,
        motherNeedsOther: 20,
        fatherNeedsSubstanceAbuse: 213,
        fatherNeedsOther: 215,
        fatherNeedsMentalHealth: 218,
        otherSubstanceExpo: 10,
        substanceOpioids: 3,
        unansweredVideoCall: 316,
        answeredVideoCall: 315,
        rejectedVideoCall: 314,
        otherRelation: 335,
        DFSWorkerNamePhoneNumber: 38,
        DateOfDischarge: 40,
        statusSubmitted: 255,
        statusCompleted: 336,
        statusClose: 256,
        statusDraft: 254
    },
    consentType: {
        pOSCCoordinator: 96,
        mother: 98,
        father: 99,
        otherCaregiver: 100
    },
    contactType: {
        pOSCCoordinator: 55
    },
    tagType: {
        infantDischargeDate: 253
    },
    lookupType: {
        substanceExposure: 1,
        infantNeed: 2,
        motherNeed: 3,
        educationTags: 4,
        infantSafeTags: 5,
        childSafetyTags: 6,
        contactType: 7,
        ActivityType: 8,
        Activity: 9,
        ActivityStatus: 10,
        Priority: 11,
        monitoringType: 12,
        consentType: 13,
        AppointmentStatus: 14,
        AppointmentType: 15,
        State: 16,
        Relationship: 17,
        Frequency: 18,
        CareGiverRelationship: 19,
        Opioids: 20,
        Abuse: 21,
        MentalHealth: 22,
        FatherNeed: 23,
        MicrolearningEducationTags: 24,
        OtherEducationTags: 25,
        InfantOtherNeeds: 26,
        ParentsOtherNeeds: 27,
        ProfileStatus: 28,
        Hospitals: 29,
        County: 30,
        Prescription: 32,
        Resource: 36
    }
}

export const SAVE_STATE = {
    PROGRESS: 'progress',
    SAVED: 'saved',
    ERROR: 'error'
}


export const TYPE = {
    CHECKBOX: 'checkbox',
    TEXT: 'text',
    NUMBER: 'number',
    SEARCH: 'search',
    DATETIME: 'dateTime',
    DROPDOWN: 'dropdown',
    AUTOCOMPLETE: 'autocomplete',
    SIGNATURE: 'signature',
    WEIGHT: 'weight',
    TIME: 'time'
}

export const isLive = false;


export const timeIteam = [
    { label: '8:00am', value: '8:00am' },
    { label: '8:15am', value: '8:15am' },
    { label: '8:30am', value: '8:30am' },
    { label: '8:45am', value: '8:45am' },
    { label: '9:00am', value: '9:00am' },
    { label: '9:15am', value: '9:15am' },
    { label: '9:30am', value: '9:30am' },
    { label: '9:45am', value: '9:45am' },
    { label: '10:00am', value: '10:00am' },
    { label: '10:15am', value: '10:15am' },
    { label: '10:30am', value: '10:30am' },
    { label: '10:45am', value: '10:45am' },
    { label: '11:00am', value: '11:00am' },
    { label: '11:15am', value: '11:15am' },
    { label: '11:30am', value: '11:30am' },
    { label: '11:45am', value: '11:45am' },
    { label: '12:00pm', value: '12:00pm' },
    { label: '12:15pm', value: '12:15pm' },
    { label: '12:30pm', value: '12:30pm' },
    { label: '12:45pm', value: '12:45pm' },
    { label: '1:00pm', value: '1:00pm' },
    { label: '1:15pm', value: '1:15pm' },
    { label: '1:30pm', value: '1:30pm' },
    { label: '1:45pm', value: '1:45pm' },
    { label: '2:00pm', value: '2:00pm ' },
    { label: '2:15pm', value: '2:15pm' },
    { label: '2:30pm', value: '2:30pm' },
    { label: '2:45pm', value: '2:45pm' },
    { label: '3:00pm', value: '3:00pm' },
    { label: '3:15pm', value: '3:15pm' },
    { label: '3:30pm', value: '3:30pm ' },
    { label: '3:45pm', value: '3:45pm' },
    { label: '4:00pm', value: '4:00pm' },
    { label: '4:15pm', value: '4:15pm' },
    { label: '4:30pm', value: '4:30pm' },
    { label: '4:45pm', value: '4:45pm' },
    { label: '5:00pm', value: '5:00pm' },
    { label: '5:15pm', value: '5:15pm' },
    { label: '5:30pm', value: '5:30pm' },
    { label: '5:45pm', value: '5:45pm' },
    { label: '6:00pm', value: '6:00pm' },
    { label: '6:15pm', value: '6:15pm' },
    { label: '6:30pm', value: '6:30pm' },
    { label: '6:45pm', value: '6:45pm' },
    { label: '7:00pm', value: '7:00pm' },
    { label: '7:15pm', value: '7:15pm' },
    { label: '7:30pm', value: '7:30pm' },
    { label: '7:45pm', value: '7:45pm' },
    { label: '8:00pm ', value: '8:00pm' },
    { label: '8:15pm', value: '8:15pm' },
    { label: '8:30pm', value: '8:30pm ' },
    { label: '8:45pm', value: '8:45pm' },
    { label: '9:00pm', value: '9:00pm' },
    { label: '9:15pm', value: '9:15pm' },
    { label: '9:30pm', value: '9:30pm' },
    { label: '9:45pm', value: '9:45pm' },
    { label: '10:00pm', value: '10:00pm' },
    { label: '10:15pm', value: '10:15pm' },
    { label: '10:30pm', value: '10:30pm' },
    { label: '10:45pm', value: '10:45pm' },
    { label: '11:00pm', value: '11:00pm' },
    { label: '11:15pm', value: '11:15pm' },
    { label: '11:30pm', value: '11:30pm' },
    { label: '11:45pm', value: '11:45pm' },
    { label: '12:00am', value: '12:00am' },
    { label: '12:15am', value: '12:15am' },
    { label: '12:30am', value: '12:30am' },
    { label: '12:45am', value: '12:45am' },
    { label: '1:00am', value: '1:00am' },
    { label: '1:15am', value: '1:15am' },
    { label: '1:30am', value: '1:30am' },
    { label: '1:45am', value: '1:45am' },
    { label: '2:00am', value: '2:00am' },
    { label: '2:15am', value: '2:15am' },
    { label: '2:30am', value: '2:30am' },
    { label: '2:45am', value: '2:45am' },
    { label: '3:00am', value: '3:00am' },
    { label: '3:15am', value: '3:15am' },
    { label: '3:30am', value: '3:30am' },
    { label: '3:45am', value: '3:45am' },
    { label: '4:00am', value: '4:00am' },
    { label: '4:15am', value: '4:15am' },
    { label: '4:30am', value: '4:30am' },
    { label: '4:45am', value: '4:45am' },
    { label: '5:00am', value: '5:00am' },
    { label: '5:15am', value: '5:15am' },
    { label: '5:30am', value: '5:30am' },
    { label: '5:45am', value: '5:45am' },
    { label: '6:00am', value: '6:00am' },
    { label: '6:15am', value: '6:15am' },
    { label: '6:30am', value: '6:30am' },
    { label: '6:45am', value: '6:45am' },
    { label: '7:00am', value: '7:00am' },
    { label: '7:15am', value: '7:15am' },
    { label: '7:30am', value: '7:30am' },
    { label: '7:45am', value: '7:45am' },
];

export const timeDuration = [
    { label: '15 min', value: '15 min' },
    { label: '30 min', value: '30 min' },
    { label: '1 hr', value: '1 hr' },
    { label: '2 hr(s)', value: '2 hr(s)' },
    { label: '3 hr(s)', value: '3 hr(s)' },
    { label: '4 hr(s)', value: '4 hr(s)' },
    { label: '5 hr(s)', value: '5 hr(s)' },
    { label: '6 hr(s)', value: '6 hr(s)' },
    { label: '7 hr(s)', value: '7 hr(s)' },
    { label: '8 hr(s)', value: '8 hr(s)' }
]

export const contactField = {
    "mother": [
        {
            "label": "Mother’s First Name",
            "name": "firstName",
            "type": "text",
            "helperText": "",
            "validations": [
                "required",
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        },
        {
            "label": "Mother’s Last Name",
            "name": "lastName",
            "type": "text",
            "helperText": "",
            "validations": [
                "required",
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        },
        {
            "label": "Date of Birth",
            "name": "birthdate",
            "type": "dateTime",
            "id": "motherdateofbirth",
            "format": "MM/dd/yyyy",
            "validations": [
                "required",
                "invalid",
                {
                    "type": "date",
                    "min": "01/01/1900",
                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                }
            ]
        },
        {
            "label": "Street Address 1",
            "name": "streetAddress1",
            "type": "text",
            "id": "motherstreetaddress",
            "validations": [
                "required",
                {
                    "type": "length",
                    "min": 0,
                    "max": 200
                }
            ]
        },
        {
            "label": "Street Address 2",
            "name": "streetAddress2",
            "type": "text",
            "id": "motherstreetaddress2",
            "validations": [
                {
                    "type": "length",
                    "min": 0,
                    "max": 200
                }
            ]
        },
        {
            "label": "City",
            "name": "city",
            "type": "text",
            "id": "mothercity",
            "helperText": "",
            "disableNumber": true,
            "validations": [
                "required",
                {
                    "req": "required",
                    "pattern": ""
                }
            ]
        },
        {
            "label": "State",
            "name": "stateId",
            "type": "autocomplete",
            "id": "motherstate",
            "helperText": "",
            "comboType": "State",
            "validations": [
                "required"
            ]
        },
        {
            "label": "ZIP Code",
            "name": "zip",
            "type": "text",
            "format": "number",
            "id": "motherzip",
            "helperText": "Please revise your five-digit Zip Code.",
            "validations": [
                "required",
                {
                    "type": "length",
                    "min": 5,
                    "max": 5
                }
            ]
        },
        {
            "label": "Cell Number",
            "name": "cellNumber",
            "type": "text",
            "format": "cellNumber",
            "id": "mothercellnumber",
            "validations": [
                "required"
            ]
        },
        {
            "label": "Employer’s Name",
            "name": "employerName",
            "type": "text",
            "id": "motheremployername",
            "helperText": "",
            "validations": [
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        },
        {
            "label": "Employer’s Number",
            "name": "employerNumber",
            "format": "cellNumber",
            "type": "text",
            "id": "motheremployernumber",
            "validations": [
                "cellNumber"
            ]
        },
        {
            "label": "County",
            "name": "countyId",
            "type": "dropdown",
            "helperText": "",
            "id": "mothercounty",
            "comboType": "County"
        },
        {
            "label": "Mother's Email",
            "name": "email",
            "type": "text",
            "format": "email",
            "id": "motherEmailAddress",
            "validations": [
                "email"
            ]
        }
    ],
    "infant": [
        {
            "label": "Infant’s First Name",
            "name": "infantFirstName",
            "type": "text",
            "id": "infantFirstName",
            "helperText": "",
            "validations": [
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        },
        {
            "label": "Infant’s Last Name",
            "name": "infantLastName",
            "type": "text",
            "id": "infantLastName",
            "helperText": "",
            "validations": [
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        },
        {
            "label": "Date of Birth",
            "name": "infantBirthDate",
            "type": "dateTime",
            "id": "infantBirthDate",
            "format": "MM/dd/yyyy",
            "validations": [
                "invalid",
                {
                    "type": "date",
                    "min": "01/01/1900",
                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                }
            ]
        },
        {
            "label": "Gender",
            "name": "infantGender",
            "type": "dropdown",
            "id": "infantGender",
            "items": [
                {
                    "value": "M",
                    "label": "Male"
                },
                {
                    "value": "F",
                    "label": "Female"
                },
                {
                    "value": "O",
                    "label": "Other"
                }
            ]
        },
        {
            "label": "Birth Hospital",
            "name": "infantBirthHospitalId",
            "id": "infantBirthHospital",
            "type": "autocomplete",
            "helperText": "",
            "comboType": "Hospitals"
        },
        {
            "label": "Weight",
            "name": "infantWeight",
            "type": "weight",
            "format": "number",
            "id": "infantWeight",
            "validations": [
                {
                    "type": "length",
                    "min": 1,
                    "max": 2
                }
            ]
        },
        {
            "label": "Case Number",
            "name": "caseNumber",
            "type": "text",
            "id": "caseNumber",
            "helperText": "",
            "validations": [
                {
                    "type": "length",
                    "min": 2,
                    "max": 20
                }
            ]
        },
        {
            "label": "Discharge Date",
            "name": "infantDischargeDate",
            "type": "dateTime",
            "id": "infantDischargeDate",
            "format": "MM/dd/yyyy",
            "headerLabel": 'Section 6 needs to be signed off before entering the discharge date.',
            "validations": [
                "invalid",
                {
                    "type": "date",
                    "min": "01/01/1900",
                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                }
            ]
        },
    ],
    "father": [
        {
            "label": "Father’s First Name",
            "name": "firstName",
            "type": "text",
            "helperText": "",
            "validations": [
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        },
        {
            "label": "Father’s Last Name",
            "name": "lastName",
            "type": "text",
            "helperText": "",
            "validations": [
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        },
        {
            "label": "Date of Birth",
            "name": "birthdate",
            "type": "dateTime",
            "id": "fatherdateofbirth",
            "format": "MM/dd/yyyy",
            "validations": [
                "invalid",
                {
                    "type": "date",
                    "min": "01/01/1900",
                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                }
            ]
        },
        {
            "label": "Street Address 1",
            "name": "streetAddress1",
            "type": "text",
            "id": "fatherstreetaddress",
            "validations": [
                {
                    "type": "length",
                    "min": 0,
                    "max": 200
                }
            ]
        },
        {
            "label": "Street Address 2",
            "name": "streetAddress2",
            "type": "text",
            "validations": [
                {
                    "type": "length",
                    "min": 0,
                    "max": 200
                }
            ]
        },
        {
            "label": "City",
            "name": "city",
            "type": "text",
            "id": "fathercity",
            "helperText": "",
            "disableNumber": true
        },
        {
            "label": "State",
            "name": "stateId",
            "type": "autocomplete",
            "id": "fatherstate",
            "helperText": "",
            "comboType": "State"
        },
        {
            "label": "ZIP Code",
            "name": "zip",
            "type": "text",
            "format": "number",
            "id": "fatherzip",
            "helperText": "Please revise your five-digit Zip Code.",
            "validations": [
                {
                    "type": "length",
                    "min": 5,
                    "max": 5
                }
            ]
        },
        {
            "label": "Cell Number",
            "name": "cellNumber",
            "type": "text",
            "format": "cellNumber",
            "id": "fathercellnumber",
            "validations": [
                "cellNumber"
            ]
        },
        {
            "label": "Employer’s Name",
            "name": "employerName",
            "type": "text",
            "id": "fatheremployername",
            "helperText": "",
            "validations": [
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        },
        {
            "label": "Employer’s Number",
            "name": "employerNumber",
            "format": "cellNumber",
            "type": "text",
            "id": "fatheremployernumber",
            "validations": [
                "cellNumber"
            ]
        },
        {
            "label": "Father's Email",
            "name": "email",
            "type": "text",
            "format": "email",
            "id": "fatherEmail",
            "validations": [
                "email"
            ]
        }
    ],
    "otherCaregiver": [
        {
            "label": "Other Caregiver’s First Name",
            "name": "firstName",
            "type": "text",
            "id": "othername",
            "helperText": "",
            "validations": [
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        },
        {
            "label": "Other Caregiver’s Last Name",
            "name": "lastName",
            "type": "text",
            "id": "othername",
            "helperText": "",
            "validations": [
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        },
        {
            "label": "Date of Birth",
            "name": "birthdate",
            "type": "dateTime",
            "id": "otherdateofbirth",
            "format": "MM/dd/yyyy",
            "validations": [
                "invalid",
                {
                    "type": "date",
                    "min": "01/01/1900",
                    "max": moment().add(1, 'years').format('MM/DD/YYYY')
                }
            ]
        },
        {
            "label": "Caregiver's Email",
            "name": "email",
            "type": "text",
            "format": "email",
            "id": "otherCaregiverEmail",
            "validations": [
                "email"
            ]
        },
        {
            "label": "Relationship",
            "name": "relationId",
            "type": "dropdown",
            "comboType": "CareGiverRelationship",
            "id": "relationship"
        },
        {
            "label": "Other",
            "name": "other",
            "type": "text",
            "id": "otherRelationship",
            "helperText": "",
            "validations": [
                {
                    "type": "length",
                    "min": 2,
                    "max": 100
                }
            ]
        }
    ]
}

export const CaseNotes = {
    InfantContact: 317,
    Consent: 318,
    Monitoring: 319,
    ChildSafety: 320,
    InfantSafe: 321,
    HealthEducation: 322,
    InfantNeed: 323,
    SubstanceExpo: 324,
    PlanOfSafe: 325
}

export const HospitalEducation = [
    { id: 21 }, //Family Planning
    { id: 22 }, //SIDS Education
    { id: 23 }, //Infant Feeding
    { id: 24 }, //Safe Sleep Education
    { id: 25 }, //Abusive Head TraumaEducation
    { id: 26 }, //NAS Symptoms Management
    { id: 27 } //Newborn Safety
]
export const VideoCallActivityEnums = {
    unansweredVideoCall: 'unansweredVideoCall',
    answeredVideoCall: 'answeredVideoCall',
    rejectedVideoCall: 'rejectedVideoCall'
}

export const resourceFormFields = [
    {
        "label": "Resource Type", "name": "resourceTypeId", "type": "dropdown", "id": "resourceTypeId", "comboType": "Resource",
        "validations": [
            "required"
        ],
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Title", "name": "title", "type": "text", "id": "title",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Source Name", "name": "resourceName", "type": "text", "id": "resourceName",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Link", "name": "link", "type": "text", "id": "link",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Tags", "name": "tags", "type": "text", "id": "tags",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Notes", "name": "notes", "type": "text", "id": "notes",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Points", "name": "point", "type": "text", "id": "point",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    }
]

export const lookupTypeFormFields = [
    {
        "label": "Setup Type", "name": "lookupType", "type": "text", "id": "lookupType",
        "validations": [
            "required"
        ],
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Description", "name": "description", "type": "text", "id": "Description",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Is Visible", "name": "isEditable", "type": "checkbox", "id": "isEditable",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        },
        'checked': false,
        'className': 'popop-checkbox'
    }
]

export const lookupFormFields = [
    {
        "label": "Display Value", "name": "displayValue", "type": "text", "id": "displayValue",
        "validations": [
            "required"
        ],
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Display Order", "name": "sortOrder", "type": "text", "id": "sortOrder", 'format': "number",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    }
]