import React from 'react';
import PropTypes from 'prop-types';

import Flex from './Flex';

import { getTileClasses } from './shared/utils';
import { tileGroupProps } from './shared/propTypes';

class TileGroup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFirstScroll: true
    }
    this.onScroll = this.onScroll.bind(this);

  }

  onScroll(e) {
    let scrollEndWidth = this.refs.tile.scrollLeft + this.refs.tile.offsetWidth;
    scrollEndWidth += 10;
    //next
    if (scrollEndWidth >= this.refs.tile.scrollWidth) {
      // console.log('Next');
      // this.refs.tile.scrollLeft = 1;
      // this.props.onScrollAction('Next');
    }

    //prev
    if (window.innerWidth == scrollEndWidth) {
      console.log('Prev');
      //TODO: Need To Discussion with DJ Sir
      this.props.onScrollAction('Prev');
    }
  }

  render() {
    const {
      className,
      count,
      dateTransform,
      dateType,
      end,
      hover,
      offset,
      start,
      step,
      tile: Tile,
      value,
      valueType,
      selectedDates,
      ...tileProps
    } = this.props;

    const tiles = [];
    for (let point = start; point <= end; point += step) {
      const date = dateTransform(point);
      tiles.push(
        <Tile
          classes={getTileClasses({
            value, valueType, date, dateType, hover, selectedDates
          })}
          date={date}
          point={point}
          key={date.getTime()}
          {...tileProps}
        />
      );
    }
    let focusOnCurrentDate = new Date(value).getDate()
    if (this.refs && this.refs.tile && this.state.isFirstScroll) {
      this.refs.tile.scrollLeft = window.cordova && window.cordova.platformId == 'android' ? focusOnCurrentDate * 58 : focusOnCurrentDate * 46;
      this.setState({ isFirstScroll: false })
    }

    return (
      <div ref={'tile'} style={Styles.tile} onScroll={this.onScroll}>
        {tiles}
      </div>
    )
  }
}

// const TileGroup = ({
//   className,
//   count,
//   dateTransform,
//   dateType,
//   end,
//   hover,
//   offset,
//   start,
//   step,
//   tile: Tile,
//   value,
//   valueType,
//   ...tileProps
// }) => {
//   const tiles = [];
//   for (let point = start; point <= end; point += step) {
//     const date = dateTransform(point);

//     tiles.push(
//       <Tile
//         classes={getTileClasses({
//           value, valueType, date, dateType, hover,
//         })}
//         date={date}
//         point={point}
//         key={date.getTime()}
//         {...tileProps}
//       />,
//     );
//   }

//   // return (
//   //   <Flex
//   //     className={className}
//   //     count={count}
//   //     offset={offset}
//   //     wrap
//   //   >
//   //     {tiles}
//   //   </Flex>
//   // );

//   let onScroll = () => {

//   }

//   return (
//     <div ref={'tile'} style={Styles.tile} onScroll={onScroll}>
//       {tiles}
//     </div>
//   )
// };

const Styles = {
  tile: {
    display: 'inline-flex',
    /* flex-wrap: wrap; */
    //  position: 'fixed',
    width: '100%',
    overflowX: 'auto',
    scrollBehavior: 'smooth'
  }
}

TileGroup.propTypes = {
  ...tileGroupProps,
  activeStartDate: PropTypes.instanceOf(Date),
  count: PropTypes.number,
  dateTransform: PropTypes.func.isRequired,
  offset: PropTypes.number,
  tile: PropTypes.func.isRequired,
  step: PropTypes.number,
};

TileGroup.defaultProps = {
  count: 3,
  step: 1,
};

export default TileGroup;
