import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import ConnectyCube from './components/connecty-cube';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import util from '../../util'
import actions from '../../redux/actions/actionType';

/*
id: 2954069,
name: "Web Admin",
login: "web_admin@admin.com",
password: "benten@safe4both"
*/



function App() {
  const [connectTo, setConnectTo] = useState(null);
  const [incomingCallFrom, setIncomingCallFrom] = useState(null);
  const remoteUserData = useSelector(state => state.appReducer.remoteUserData);
  const userData = useSelector(state => state.appReducer.userData);
  const dispatch = useDispatch();
  const user = {
    id: userData && Number(userData.connectyCubeId),
    name: userData && userData.fullName,
    login: userData && userData.emailAddress,
    password: "benten@safe4both",
    webUserId: userData && userData.id
  };
  const remoteUser = {
    id: remoteUserData && remoteUserData.id,
    name: remoteUserData.name
  };

  useEffect(() => {
    if (remoteUserData.openVideoCall) {
      if (connectTo) {
        setConnectTo(null);
      } else {
        //setConnectTo({ id: remoteUserData.id, name: remoteUserData.name });
        setConnectTo(remoteUser);
      }
    }
  }, [remoteUserData.openVideoCall])

  const onStateChange = ({ disconnected }) => {
    if (disconnected) {
      dispatch({ type: actions.OPEN_VIDEO_CALL, remoteUserData: { openVideoCall: false, id: '', name: '' } });
      setConnectTo(null);
      setIncomingCallFrom(null);
    }
  }

  const getUserById = async (userId) => {
    let appUser = {};
    await axios(`${util.mobileAppBaseUrl}/users/appUsersList`).then(data => {
      if (data && data.data && data.data.result) {
        let appUserData = data.data.result.filter(e => Number(e.connectyCubeId) == Number(userId)) || [];
        if (appUserData.length > 0) {
          appUser = appUserData[0];
        }
      }
    })
    return appUser;
  }

  const onIncomingReject = ({ userId, reason }) => {
    console.log(`Incoming call rejected from ${userId} reason: ${reason}`);
    if (incomingCallFrom) {
      setIncomingCallFrom(null);
    }
  }

  const onIncoming = async ({ userId }) => {
    const userData = await getUserById(userId);
    console.log(`Incoming call from ${userId}`);
    setIncomingCallFrom({ userId: userId, name: userData.fullName });
  }

  const hideCallingPopup = () => {
    setIncomingCallFrom(null);
  }


  return (
    <div className="App">
      <ConnectyCube
        user={user}
        connectTo={connectTo}
        onStateChange={onStateChange}
        onIncomingReject={onIncomingReject}
        onIncoming={onIncoming}
        incomingCallFrom={incomingCallFrom}
        isOpen={remoteUserData.openVideoCall}
        hideCallingPopup={hideCallingPopup}
      />
      {/* <button onClick={toggleConnection} disabled={!!incomingCallFrom}>{connectTo ? "Hang up" : "Start call"}</button> */}
    </div>
  );
}

export default App;