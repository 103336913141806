import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-pad-wrapper'
import { useSelector } from 'react-redux';


const SignatureBase = (props) => {
    let signatureRef = useRef();
    const { data, handleChange, style, padHeight } = props;
    const { name, group, typeId, key } = data;
    const records = useSelector(state => state.appReducer[group], []);
    const handleClear = () => {
        signatureRef.current.instance.clear();
        handleChange(signatureRef.current.canvas, Object.assign({}, data, { isClearSignature: true }), true);
    }
    useEffect(() => {
        if (signatureRef.current && signatureRef.current.canvas) {
            signatureRef.current.canvas.addEventListener('mouseup', () => { handleChange(signatureRef.current.canvas, data) });
            signatureRef.current.canvas.addEventListener("touchend", () => { handleChange(signatureRef.current.canvas, data) });
            if (records) {
                const rec = records.find(rec => rec[key] === typeId);
                if (rec) {
                    let signature = rec[name];
                    if (signature) {
                        signatureRef.current.fromDataURL(signature);
                    }
                }
            }
        }
    }, []);
    return (
        <div className="signaturepad-wrapper" >
            <div style={style || { width: '48%', position: 'relative' }}>
                <span className={'sign-lable'}>Signature</span>
                <div style={{ background: 'rgb(229, 230, 237)', height: '55px' }}>
                    <SignaturePad options={{ penColor: '#2196f3' }} height={padHeight || 105} ref={signatureRef} />
                </div>
                <span style={{ color: '#2196f3', float: "right", lineHeight: 2 }}><span className="clear-signature" onClick={handleClear}>Clear</span></span>
            </div>
        </div>
    )
}
export default (SignatureBase);