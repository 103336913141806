import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '../../components/FormGroup'
import Substance from '../../components/Substance'
import AssessmentNeed from '../../components/AssessmentNeed';
import BaseButton from '../../components/BaseButton';
import { useSelector, useDispatch } from 'react-redux';
import { request, apis } from '../../httpUtil';
import actions from '../../redux/actions/actionType';
import util from '../../util';
import { ACTION_API, CaseNotes, enums, HospitalEducation } from '../../Utility/const';
import swal from 'sweetalert';
import StatusLog from './StatusLog';
import SupportDocument from './SupportDocuments';
import FormUploadFile from '../../components/FormUploadFile';
import FormUploadFileProgram from '../../components/FormUploadFileProgram';
import SupportProgram from './SupportProgram';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import Button from '@material-ui/core/Button';
import { Popover, Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';

import FormSibling from '../../components/SiblingForm';
import MonitoringForm from '../../components/MonitoringForm';
import CustomizedTables from '../../PrintDoc';
import CaseNote from '../../components/CaseNote';
import OtherSpecifyForm from '../../components/OtherSpecifyForm';
import OtherSubstanceForm from '../../components/OtherSubstanceForm';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: '.2em',
        '& .MuiExpansionPanelDetails-root': {
            background: '#EFEDED'
        },
        '& .MuiExpansionPanel-root.Mui-expanded': {
            margin: '.2em 0'
        },
        '& .MuiGrid-item': {
            width: '100%'
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiExpansionPanelSummary-content': {
            margin: '20px 0'
        },
        '& .MuiButton-containedPrimary': {
            color: '#2196F3',
            background: '#E3F2FD',
            boxShadow: '0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 24%)'
        }
    },
    paper: {
        margin: '.5em .5em .9em .5em',
        padding: '1.8em 0em 1.9em 0em'
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        flexShrink: 0,
        marginBottom: '2px',
        fontWeight: '500'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    headingStyle: {
        color: '#FFFFFF'
    },
    iconStyle: {
        color: '#FFFFFF'
    },
    titleCont: {
        backgroundColor: '#03a9f4',
        padding: '20px 0',
        fontWeight: 'bold',
        fontSize: '22px'
    },
    title: {
        paddingLeft: '24px'
    },
    footerCont: {
        backgroundColor: '#03a9f4',
        height: '2.5em',
        padding: '.5em 1.4em 1.3em 2em'
    },
    buttonCont: {
        '& > *': {
            margin: theme.spacing(1),
            backgroundColor: '#E8E8E8',
            color: 'rgba(0, 0, 0, 0.54)',
            float: "right",
            minWidth: '7em',
            '&:hover': {
                backgroundColor: '#E8E8E8',
            }
        }
    },
    submitBtn: {
        backgroundColor: '#E3F2FD',
        color: '#2196F3'
    },
    expandAllBtn: {
        float: 'right',
        marginRight: '1.75em',
        backgroundColor: '#E3F2FD',
        color: '#2196F3',
        '&:hover': {
            backgroundColor: '#E8E8E8',
        }
    }
}));
const infantHeaders = [
    { id: 'infantneed', label: 'Infant\'s Need(s)', minWidth: 100, width: '15%' },
    { id: 'servicereferredro', label: 'Service/Provider Referred To', minWidth: 160, align: 'center', width: '22%' },
    { id: 'serviceproviderphone', label: 'Phone', minWidth: 100, align: 'center', width: '15%' },
    {
        id: 'notprescribed',
        label: 'Family Accepted Service?',
        minWidth: 155,
        align: 'center',
        width: '18%'
    },
    {
        id: 'referraldate',
        label: 'Referral Date',
        minWidth: 70,
        align: 'center',
        width: '15%'
    },
    {
        id: 'dayupdate',
        label: '30/60 Day Update Date',
        minWidth: 125,
        align: 'center',
        width: '15%'
    }
];

const expandItem = {
    infantContactFields: false,
    planOfSafeCareFields: false,
    subExpoFields: false,
    infantNeedFields: false,
    healthEduFields: false,
    infantSafeFields: false,
    childSafetyFields: false,
    monitoringFields: false,
    consentFields: false
    //,
    // statusLog: false,
    // supportDoc: false,
    // supportProgram: false
}

let motherHeaders = [{ id: 'motherNeed', label: 'Mother\'s Need(s)', minWidth: 170 }].concat(infantHeaders);
motherHeaders.splice(1, 1);
let fatherHeaders = [{ id: 'fatherNeed', label: 'Father\'s Need(s)', minWidth: 170 }].concat(infantHeaders);
fatherHeaders.splice(1, 1);

let activeCaseId, dispatch, signatureRef, checkValidation, saveData, data;

const onSubmit = (hideAlert) => checkValidation(false) && saveData();

const onCheckBoxChange = (e, fieldInfo) => {
    const childSafetyAgreement = document.getElementById('childSafetyAgreement');
    const dfsWorkerName = document.getElementById('dfsWorkerName');
    const dfsWorkerPhoneNo = document.getElementById('dfsWorkerPhoneNo');
    if (childSafetyAgreement && dfsWorkerName && dfsWorkerPhoneNo) { // To Do - Optimized this code
        // CMS #214 hide the field
        childSafetyAgreement.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = "none";
        dfsWorkerName.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = e.currentTarget.checked ? 'block' : "none";
        dfsWorkerPhoneNo.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = e.currentTarget.checked ? 'block' : "none";
        if (fieldInfo && childSafetyAgreement.value && !e.currentTarget.checked) {
            let { typeId, key, name, group } = fieldInfo;
            let setKeyObject = key ? { [key]: (typeId + 1), [name]: '' } : { [name]: '' }
            dispatch({
                type: 'SET_FORM_DATA', formData: { group: group, key: key, value: setKeyObject }
            });
        }
    }

    const infantsafeOtherValue = document.getElementById('infantsafeOtherValue');
    if (infantsafeOtherValue && fieldInfo.id == 'infantsafeOther') { // To Do - Optimized this code 
        infantsafeOtherValue.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = e.currentTarget.checked ? 'block' : "none";
        if (fieldInfo && infantsafeOtherValue.value && !e.currentTarget.checked) {
            let { typeId, key, name, group, id } = fieldInfo;

            let setKeyObject = key ? { [key]: (id === "infantsafeOther" ? 269 : typeId + 1), [id === "infantsafeOther" ? 'other' : name]: '' } : { [id === "infantsafeOther" ? 'other' : name]: '' }
            dispatch({
                type: 'SET_FORM_DATA', formData: { group: group, key: key, value: setKeyObject }
            });
        }
    }


    const hospitaleducationValue = document.getElementById('hospitaleducationValue');
    if (hospitaleducationValue && fieldInfo.id == 'hospitaleducation') { // To Do - Optimized this code 
        hospitaleducationValue.parentNode.parentElement.parentElement.parentElement.parentElement.style.display = e.currentTarget.checked ? 'block' : "none";
        if (fieldInfo && hospitaleducationValue.value && !e.currentTarget.checked) {
            let { typeId, key, name, group, id } = fieldInfo;
            let setKeyObject = key ? { [key]: (id === "hospitaleducation" ? 268 : typeId + 1), [id === "hospitaleducation" ? 'other' : name]: '' } : { [id === "hospitaleducation" ? 'other' : name]: '' }
            dispatch({
                type: 'SET_FORM_DATA', formData: { group: group, key: key, value: setKeyObject }
            });
        }
    }
}

const HeaderIcons = (props) => {
    const { expanded, headerInfo, isDischange } = props;
    const { savedSection, success } = headerInfo;
    return (
        <div className="save-status-section">
            {(expanded === savedSection) ? success ? <CheckCircleIcon className="save-done" /> : <WarningIcon className="save-error" /> : ((isDischange && savedSection === 'infantContactFields') ? <WarningIcon className="save-error" /> : null)}
        </div>
    );
}
const ContactForm = (props) => {
    const classes = useStyles();
    dispatch = useDispatch();
    signatureRef = useRef();
    const [expanded, setExpanded] = React.useState({ ...expandItem });
    const [formSubmit, setFormSubmit] = React.useState(false);
    const [headerInfo, setHeaderInfo] = React.useState({ savedSection: '', success: false });
    const [expandedAll, setExpandedAll] = React.useState(false);
    let formFields = useSelector(state => state.appReducer.formFields); // select fields from store
    activeCaseId = useSelector(state => state.appReducer.activeCaseId);
    let fields = util.getGroupedFields(formFields); // devide fields by section
    let infantSafeTagsData = useSelector(state => state.appReducer.infantSafeTags) || [];
    //let hospitalEducationData = useSelector(state => state.appReducer.educationTags) || [];
    let caseData = useSelector(state => state.appReducer.caseData) || [];
    let otherSupportPersonFields = useSelector(state => state.appReducer.otherSupportPersonData) || [];
    let siblingFields = useSelector(state => state.appReducer.siblingData) || [];

    //For Rander formfields after change in consent section
    let consentData = useSelector(state => state.appReducer.consent) || [];

    const [isSubmitClick, setIsSubmitClick] = React.useState(false);

    const [isDischange, setIsDischange] = useState(false);
    // load case data
    useEffect(() => {
        let caseId = activeCaseId || props.match && props.match.params.id;
        loadData(caseId, fields);
        return () => {
            dispatch({ type: actions.SET_SAVE_DATE_STATUS, saveSectionStatus: {} });
        }
    }, []);

    const loadData = async (caseId, formFields) => {
        if (caseId) {
            const params = Object.assign({ action: ACTION_API.LOAD, caseId });
            const response = await request(apis.case, params);
            const caseNoteData = await request(apis.casenote, { action: 'load', caseId: caseId });
            const data = { updatedData: response.data, formFields: formFields }
            if (response.data) {
                dispatch({ type: actions.LOAD_PROFILE_DATA, data });
                if (response.data.caseSignature && response.data.caseSignature.length > 0 && signatureRef.current) {// load signature data
                    const signatureData = response.data.caseSignature[0].signature;
                    signatureRef.current.fromDataURL(signatureData);
                }
            }
            if (caseNoteData && caseNoteData.data) {
                dispatch({ type: actions.SET_CASE_NOTE, caseNote: caseNoteData.data });
            }
        }
    }


    saveData = async (autoSave, panel) => {
        data = util.getFormData();
        // dispatch({ type: actions.SET_SAVE_DATE_STATUS, saveStatus: SAVE_STATE.PROGRESS });
        if (data && Object.keys(data).length === 0) {
            if (!autoSave) {
                swal({
                    text: "No new data to save."
                });
            }
            return;
        }
        let params = Object.assign({ action: 'save' }, data);
        if (activeCaseId && activeCaseId > 0) {
            params.caseId = activeCaseId;
        }
        if (!autoSave) {
            params.signatureData = signatureRef && signatureRef.current && signatureRef.current.canvas.toDataURL();
        }

        params = JSON.parse(JSON.stringify(params).replace(/"\s+|\s+"/g, '"')); // remove white spaces

        const response = await request(apis.case, params);

        let title = 'Success', content = '';
        if (response && response.success) {
            content = response.data;
        } else {
            title = 'Error';
            content = response.error;
            if (content.indexOf('Duplicate') > -1) {
                swal({
                    title: title,
                    text: content,
                    icon: "error",
                    dangerMode: true
                });
            }
        }
        setHeaderInfo(Object.assign({}, { savedSection: panel, success: response.success }));
        dispatch({ type: actions.SET_SAVE_DATE_STATUS, saveSectionStatus: response.saveSectionStatus });
        if (response.updatedData) {
            if (!activeCaseId && response.updatedData.caseData) {
                dispatch({ type: actions.SET_ACTIVE_CASEID, activeCaseId: response.updatedData.caseData.id });
            }
            dispatch({ type: actions.SET_PROFILE_DATA, updatedData: response.updatedData });
        }
        if (!autoSave) {
            dispatch({ type: actions.SET_DIALOG_DATA, value: { open: true, content: content, title: title } });
        }
        setIsSubmitClick(true);
    }
    util.onSubmit = onSubmit;
    // on Submit form
    checkValidation = (hideAlert, panel) => {
        const data = util.getStore();
        let isValid = true;
        let panelExpand = '';
        let checkFields = [];
        for (const item in expanded) {
            if (expanded[item]) {
                panelExpand = item;
                checkFields.push(item);
            }
        }
        // let checkFields = expanded['infantContactFields'] ? ['infantContactFields'] : ['infantContactFields', expanded];
        let validCheck = false, errors;
        checkFields.forEach(checkField => {
            let setcionFields = (fields && fields[checkField]) || [];
            if (!Array.isArray(setcionFields)) {
                setcionFields = [setcionFields];
            }
            if (!validCheck) {
                panelExpand = checkField;
            }

            setcionFields.forEach(fieldGroup => {
                if (!isValid) {
                    return;
                }
                const keys = Object.keys(fieldGroup);
                (keys.length > 0) &&
                    keys.forEach(groupKey => {
                        let recordGroup = fieldGroup[groupKey];
                        const needsFields = recordGroup.filter(rec => rec.fields && rec.fields.length > 0);// for assessment needs fields
                        if (needsFields && needsFields.length > 0) {
                            let tempRecord = [];
                            recordGroup.forEach(tempRec => {
                                if (tempRec.fields) {
                                    tempRec.fields.forEach(field => {
                                        let newRec = Object.assign({ name: field.name }, tempRec);
                                        newRec.validations = field.validations;
                                        tempRecord.push(newRec);
                                    });
                                }
                            });
                            recordGroup = tempRecord;
                        }
                        recordGroup.forEach(s => {
                            if (!isValid) {
                                return;
                            }
                            let { name, group, typeId, key, validations } = s;
                            group = group ? group : groupKey;
                            if (group == "infant") {
                                group = "caseData";
                            }
                            let records = data[group], value;
                            if (records) {
                                if (key && typeId) {
                                    const rec = records.find(rec => Number(rec[key]) === typeId);
                                    if (rec) {
                                        value = rec[name] || rec['other'];
                                    }
                                    if (!value && group === "consent") { // populate data from contacts for consent
                                        value = util.getDefaultValueForConsent(typeId, name);
                                    }
                                } else {
                                    value = records[name];
                                }

                                if (validations && validations.length > 0 && value === undefined) {
                                    if (validations.includes('required')) {
                                        value = '';
                                    }
                                }

                                errors = util.validation(s, value);
                                if (errors && errors.length > 0) {
                                    isValid = false;
                                }
                            }
                            if (!isValid) {
                                validCheck = true;
                                return false;
                            }
                        })
                    });
            });
            if (isValid && checkField == 'planOfSafeCareFields') {
                let otherSupportError = otherSupportPersonFields.findIndex(e => e.error);
                if (otherSupportError > -1) {
                    swal({
                        title: `Invalid value in Other support person ${otherSupportError + 1}`,
                        icon: "info"
                    });
                    isValid = false;
                    hideAlert = true;
                }
            }
            if (isValid && checkField == 'infantContactFields') {
                let siblingError = siblingFields.findIndex(e => e.error);
                if (siblingError > -1) {
                    swal({
                        title: `Invalid value in Sibling name ${siblingError + 1}`,
                        icon: "info"
                    });
                    isValid = false;
                    hideAlert = true;
                }
            }
        });

        //let dischargeIndex = fields.infantContactFields.infant.findIndex(e => e.id == "infantDischargeDate");
        if (isValid && expanded['infantContactFields'] && caseData.infantDischargeDate) {
            //Tag Id 36 for signature in section 6
            let infantSafeTagsFilterData = infantSafeTagsData.filter(e => e.tagId == 36) || [];

            //#389 - Section 5 - education - revmove requirement 

            // for (let i = 0; i < HospitalEducation.length; i++) {
            //     let isTagChecked = hospitalEducationData.filter(el => el.tagId == HospitalEducation[i].id && el.value == "true") || [];
            //     if (isTagChecked.length == 0 && (infantSafeTagsFilterData.length == 0 || (infantSafeTagsFilterData.length > 0 && !infantSafeTagsFilterData[0].value))) {
            //         swal({
            //             title: "Complete all the hospital educations in Section 5, and Section 6 needs to be signed off before entering the discharge date.",
            //             icon: "info"
            //         });
            //         isValid = false;
            //         hideAlert = true;
            //         caseData.infantDischargeDate = '';
            //         break;
            //     }
            //     else if (isTagChecked.length == 0) {
            //         swal({
            //             title: "Please check and complete Section 5: Hospital Education before entering the Discharge Date",
            //             icon: "info"
            //         });
            //         setIsDischange(true);
            //         if (fields.infantContactFields && fields.infantContactFields.infant && dischargeIndex > -1) {
            //             fields.infantContactFields.infant[dischargeIndex]['error'] = true;
            //         }
            //         isValid = false;
            //         hideAlert = true;
            //         caseData.infantDischargeDate = '';
            //         break;
            //     } else {
            //         fields.infantContactFields.infant[dischargeIndex]['error'] = false;
            //     }
            // }
            if (isValid && (infantSafeTagsFilterData.length == 0 || (infantSafeTagsFilterData.length > 0 && !infantSafeTagsFilterData[0].value))) {
                swal({
                    title: "Section 6 need to be signed and completed first",
                    icon: "info"
                });
                setIsDischange(true);
                isValid = false;
                hideAlert = true;
                caseData.infantDischargeDate = '';
            }
        } else {
            setIsDischange(false);
        }

        if (!isValid) {
            if (!hideAlert) {
                swal({
                    title: errors[0].helperText || "Fill required fields.",
                    text: "",
                    icon: "error",
                    dangerMode: true,
                    buttons: ["Edit", "Cancel"]
                }).then((Cancel) => {
                    if (Cancel)
                        window.location.hash = "/cases";
                    //Set Case Load to Active tab
                    dispatch({ type: actions.SET_ACTIVE_TAB, activeTab: 2 });
                })
            }
            setFormSubmit(true);
            // setExpanded(panelExpand);
            setHeaderInfo(Object.assign({}, { savedSection: panelExpand, success: false }));
        }
        setIsSubmitClick(false);
        return isValid;
    }
    util.checkValidation = checkValidation;
    const handleChange = panel => (event, isExpanded) => {
        let newExpandObj = { ...expanded };
        if (!expanded[panel] && panel === 'infantContactFields' && activeCaseId === 0) {
            newExpandObj[panel] = isExpanded;
            setExpanded(newExpandObj);
        } else {
            if (checkValidation(false)) {
                newExpandObj[panel] = isExpanded;
                if (panel !== 'panel11' && panel !== 'panel10' && panel !== 'panel12') {
                    !isExpanded && saveData(true, panel);
                }
                setExpanded(newExpandObj);
            }
        }
    };

    useEffect(() => {
        if (expandedAll) {
            let isAllCollapse = true;
            for (const item in expanded) {
                if (expanded[item]) {
                    isAllCollapse = false
                }
            }
            isAllCollapse && setExpandedAll(false);
        }
    }, [expanded])

    const printDoc = () => {
        if (document.getElementById('doc')) {
            var content = document.getElementById('doc').innerHTML;
            var mywindow = window.open();

            mywindow.document.write('<html><head><title>Print</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write(content);
            mywindow.document.write('</body></html>');
            // mywindow.document.close();
            mywindow.focus()
            mywindow.print();
            //mywindow.close();
            return true;
        }
    }
    const childSafetyTags = [...fields.childSafetyFields.childSafetyTags];

    const onExpandAll = () => {
        setExpandedAll(!expandedAll);
        for (const item in expanded) {
            expanded[item] = !expandedAll;
        }
        setExpanded(expanded);
    }

    const caseStatusId = caseData && caseData.statusId;

    return (
        <div className={classes.root}>
            <ExpansionPanel className={classes.headingStyle}>
                <Typography className={classes.titleCont}>
                    <span className={classes.title}>New Plan of Safe Care </span>
                    <Button onClick={onExpandAll} className={classes.expandAllBtn}>{expandedAll ? 'Collapse All' : 'Expand All'}</Button>
                </Typography>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded['infantContactFields']} onChange={handleChange('infantContactFields')}>
                <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel1bh-content" id="panel1bh-header"                >
                    <Typography className={classes.heading}>Section 1: Family Information
                        <HeaderIcons expanded="infantContactFields" headerInfo={headerInfo} />
                    </Typography>
                </ExpansionPanelSummary>
                {
                    expanded['infantContactFields'] &&
                    <ExpansionPanelDetails>
                        <FormGroup group='motherContact' jsonRecord={[fields.infantContactFields.mother]} key="FamilyInformation1" formSubmit={formSubmit} />
                        <FormGroup group='caseData' jsonRecord={[fields.infantContactFields.infant]} key="FamilyInformation2" formSubmit={formSubmit} conditional={true} />
                        <FormGroup group='fatherContact' jsonRecord={[fields.infantContactFields.father]} key="FamilyInformation3" formSubmit={formSubmit} />
                        <FormGroup group='careGiverContact' jsonRecord={[fields.infantContactFields.otherCaregiver]} key="FamilyInformation4" formSubmit={formSubmit} />
                        <FormSibling />
                        {activeCaseId > 0 &&
                            <CaseNote group={CaseNotes.InfantContact} activeCaseId={activeCaseId} />
                        }
                    </ExpansionPanelDetails>
                }
            </ExpansionPanel>
            <ExpansionPanel expanded={(expanded['planOfSafeCareFields'])} onChange={handleChange('planOfSafeCareFields')}>
                <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel2bh-content" id="panel2bh-header"                >
                    <Typography className={classes.heading}>Section 2: Plan of Safe Care Coordinator and Plan Participants
                        <HeaderIcons expanded="planOfSafeCareFields" headerInfo={headerInfo} />
                    </Typography>

                </ExpansionPanelSummary>
                {(expanded['planOfSafeCareFields']) &&
                    < ExpansionPanelDetails >
                        <FormGroup group="contacts" jsonRecord={fields.planOfSafeCareFields} key="PlanofSafeCare" formSubmit={formSubmit} />
                        <OtherSpecifyForm />
                        {activeCaseId > 0 &&
                            <CaseNote group={CaseNotes.PlanOfSafe} activeCaseId={activeCaseId} />
                        }
                    </ExpansionPanelDetails>
                }
            </ExpansionPanel>
            <ExpansionPanel expanded={(expanded['subExpoFields'])} onChange={handleChange('subExpoFields')}>
                <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel3bh-content" id="panel3bh-header"                >
                    <Typography className={classes.heading}>Section 3: Prenatal Substance Exposure
                    <HeaderIcons expanded="subExpoFields" headerInfo={headerInfo} />
                    </Typography>
                </ExpansionPanelSummary>
                {(expanded['subExpoFields']) &&
                    <ExpansionPanelDetails>
                        <Substance group="caseSubstance" jsonRecord={fields.subExpoFields.caseSubstance} key="PrenatalSubstance" isSubmitClick={isSubmitClick} />
                        <OtherSubstanceForm />
                        {activeCaseId > 0 &&
                            <CaseNote group={CaseNotes.SubstanceExpo} activeCaseId={activeCaseId} />
                        }
                    </ExpansionPanelDetails>
                }
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded['infantNeedFields']} onChange={handleChange('infantNeedFields')}>
                <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel4bh-content" id="panel4bh-header"                >
                    <Typography className={classes.heading}>Section 4: Assessment of Needs and Referrals for Services for the Family
                    <HeaderIcons expanded="infantNeedFields" headerInfo={headerInfo} />
                    </Typography>
                </ExpansionPanelSummary>
                {expanded['infantNeedFields'] &&
                    <>
                        <ExpansionPanelDetails>
                            <AssessmentNeed group="infantNeeds" key="InfantNeeds" fields={fields.infantNeedFields.assessmentNeed} headers={infantHeaders} isSubmitClick={isSubmitClick} />
                            <AssessmentNeed group="motherNeeds" key="Mother Needs" fields={fields.motherNeedFields.assessmentNeed} headers={motherHeaders} isSubmitClick={isSubmitClick} />
                            <AssessmentNeed group="fatherNeeds" key="FatherNeeds" fields={fields.fatherNeedFields.assessmentNeed} headers={fatherHeaders} isSubmitClick={isSubmitClick} />
                            {activeCaseId > 0 &&
                                <CaseNote group={CaseNotes.InfantNeed} activeCaseId={activeCaseId} />
                            }
                        </ExpansionPanelDetails>
                    </>
                }
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded['healthEduFields']} onChange={handleChange('healthEduFields')}>
                <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel4bh-content" id="panel4bh-header" >
                    <Typography className={classes.heading}>Section 5: Education Provided to Mother, Father and Other Caregiver(s)
                    <HeaderIcons expanded="healthEduFields" headerInfo={headerInfo} />
                    </Typography>
                </ExpansionPanelSummary>
                {expanded['healthEduFields'] &&
                    < ExpansionPanelDetails >
                        <span className="education-tag-title">Microlearning Education</span>
                        <FormGroup group="microEducationTags" jsonRecord={[fields.healthEduFields.microEducationTags]} key="microEducationTags" paperClass='contact-custom-header' />
                        <span className="education-tag-title">Other Education</span>
                        <FormGroup group="otherEducationtags" jsonRecord={[fields.healthEduFields.otherEducationtags]} key="otherEducationtags" paperClass='contact-custom-header' />
                        <span className="education-tag-title">Hospital Education</span>
                        <FormGroup group="educationTags" jsonRecord={[fields.healthEduFields.educationTags]} key="educationTags" onCheckBoxChange={onCheckBoxChange} paperClass='contact-custom-header' />
                        {activeCaseId > 0 &&
                            <CaseNote group={CaseNotes.HealthEducation} activeCaseId={activeCaseId} />
                        }
                    </ExpansionPanelDetails>
                }
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded['infantSafeFields']} onChange={handleChange('infantSafeFields')}>
                <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel4bh-content" id="panel4bh-header"                >
                    <Typography className={classes.heading}>Section 6: Infant Safe Sleeping Arrangements
                    <HeaderIcons expanded="infantSafeFields" headerInfo={headerInfo} isDischange={isDischange} />
                    </Typography>
                </ExpansionPanelSummary>
                {expanded['infantSafeFields'] &&
                    <ExpansionPanelDetails>
                        <FormGroup group="infantSafeTags" jsonRecord={fields.infantSafeFields} key="InfantSafeSleeping" onCheckBoxChange={onCheckBoxChange} paperClass='contact-paper-cont' formSubmit={formSubmit} />
                        {activeCaseId > 0 &&
                            <CaseNote group={CaseNotes.InfantSafe} activeCaseId={activeCaseId} />
                        }
                    </ExpansionPanelDetails>
                }
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded['childSafetyFields']} onChange={handleChange('childSafetyFields')}>
                <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel4bh-content" id="panel4bh-header"                >
                    <Typography className={classes.heading}>Section 7: Child Safety and Discharge
                    <HeaderIcons expanded="childSafetyFields" headerInfo={headerInfo} />
                    </Typography>
                </ExpansionPanelSummary>
                {expanded['childSafetyFields'] &&
                    <ExpansionPanelDetails>
                        <Typography>
                            <FormGroup group="childSafetyTags" jsonRecord={{ childSafetyTags: childSafetyTags.splice(0, 6) }} key="ChildSafety" onCheckBoxChange={onCheckBoxChange} paperClass='contact-paper-cont' />
                            <FormGroup group="childSafetyTags" jsonRecord={{ childSafetyTags: childSafetyTags.splice(0, 3) }} key="ChildSafety" onCheckBoxChange={onCheckBoxChange} />
                            <FormGroup group="childSafetyTags" jsonRecord={{ childSafetyTags: childSafetyTags.splice(0, 5) }} key="ChildSafety" onCheckBoxChange={onCheckBoxChange} />
                            <FormGroup group="childSafetyTags" jsonRecord={{ childSafetyTags: childSafetyTags.splice(0, 5) }} key="ChildSafety" onCheckBoxChange={onCheckBoxChange} />
                        </Typography>
                        {activeCaseId > 0 &&
                            <CaseNote group={CaseNotes.ChildSafety} activeCaseId={activeCaseId} />
                        }
                    </ExpansionPanelDetails>
                }
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded['monitoringFields']} onChange={handleChange('monitoringFields')}>
                <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel4bh-content" id="panel4bh-header"                >
                    <Typography className={classes.heading}>Section 8: Monitoring
                    <HeaderIcons expanded="monitoringFields" headerInfo={headerInfo} />
                    </Typography>
                </ExpansionPanelSummary>
                {expanded['monitoringFields'] &&
                    < ExpansionPanelDetails >
                        <MonitoringForm />
                        {activeCaseId > 0 &&
                            <CaseNote group={CaseNotes.Monitoring} activeCaseId={activeCaseId} />
                        }
                    </ExpansionPanelDetails>
                }
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded['consentFields']} onChange={handleChange('consentFields')}>
                <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel4bh-content" id="panel4bh-header"                >
                    <Typography className={classes.heading}>Section 9: Consent for Information Sharing and Signatures
                    <HeaderIcons expanded="consentFields" headerInfo={headerInfo} />
                    </Typography>
                </ExpansionPanelSummary>
                {expanded['consentFields'] &&
                    <ExpansionPanelDetails>
                        <FormGroup group="consent" jsonRecord={fields.consentFields} key="ConsentforInformation" declamation={true} formSubmit={formSubmit} conditional={true} />
                        {activeCaseId > 0 &&
                            <CaseNote group={CaseNotes.Consent} activeCaseId={activeCaseId} />
                        }
                    </ExpansionPanelDetails>
                }
            </ExpansionPanel>
            {/* #276 - Plan create session - hide section10, 11,12 */}
            {activeCaseId > 0 &&
                <>
                    {/* < ExpansionPanel expanded={expanded === 'statusLog'} onChange={handleChange('statusLog')}>
                        <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel4bh-content" id="panel4bh-header"                >
                            <Typography className={classes.heading}>Section 10: Status Log</Typography>
                        </ExpansionPanelSummary>
                        {expanded === 'statusLog' &&
                            <ExpansionPanelDetails>
                                <StatusLog />
                            </ExpansionPanelDetails>
                        }
                    </ExpansionPanel>
                    <ExpansionPanel expanded={expanded === 'supportDoc'} onChange={handleChange('supportDoc')}>
                        <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel4bh-content" id="panel4bh-header"                >
                            <Typography className={classes.heading}>Section 11: Supporting Documents</Typography>
                        </ExpansionPanelSummary>
                        {expanded === 'supportDoc' &&
                            <ExpansionPanelDetails>
                                <FormUploadFile activeCaseId={activeCaseId} />
                                <SupportDocument activeCaseId={activeCaseId} />
                            </ExpansionPanelDetails>
                        }
                    </ExpansionPanel>
                    <ExpansionPanel expanded={expanded === 'supportProgram'} onChange={handleChange('supportProgram')}>
                        <ExpansionPanelSummary className={classes.headingStyle} expandIcon={<ExpandMoreIcon className={classes.iconStyle} />} aria-controls="panel4bh-content" id="panel4bh-header"                >
                            <Typography className={classes.heading}>Section 12: Support Programs</Typography>
                        </ExpansionPanelSummary>
                        {expanded === 'supportProgram' &&
                            <ExpansionPanelDetails>
                                <FormUploadFileProgram activeCaseId={activeCaseId} />
                                <SupportProgram activeCaseId={activeCaseId} />
                            </ExpansionPanelDetails>
                        }
                    </ExpansionPanel> */}
                    <CustomizedTables />
                </>
            }
            <ExpansionPanel className={classes.footerCont}>
                <div className={classes.buttonCont} style={{ flex: 1 }}>
                    <Button onClick={onSubmit} className={classes.submitBtn} >{caseStatusId == enums.lookup.statusSubmitted ? 'Complete' : 'Submit'} </Button>
                    <Button onClick={printDoc} >Print</Button>
                </div>
            </ExpansionPanel>
        </div >
    )
}
export default ContactForm;