import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mergeClassNames from 'merge-class-names';
import { tileProps } from './shared/propTypes';

export default class Tile extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      date,
      tileClassName,
      tileContent,
      view
    } = nextProps;

    const nextState = {};

    if (tileClassName !== prevState.tileClassNameProps) {
      nextState.tileClassName = typeof tileClassName === 'function' ? tileClassName({ date, view }) : tileClassName;
      nextState.tileClassNameProps = tileClassName;
    }

    if (tileContent !== prevState.tileContentProps) {
      nextState.tileContent = typeof tileContent === 'function' ? tileContent({ date, view }) : tileContent;
      nextState.tileContentProps = tileContent;
    }

    return nextState;
  }

  state = {};

  getDayFromDate(date) {
    let days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return days[date.getDay()];
  }
  render() {
    const {
      activeStartDate,
      children,
      classes,
      date,
      formatAbbr,
      locale,
      maxDate,
      maxDateTransform,
      minDate,
      minDateTransform,
      onClick,
      onMouseOver,
      style,
      tileDisabled,
      view
    } = this.props;
    const { tileClassName, tileContent } = this.state;
    let day = this.getDayFromDate(date);
    return (
      <button
        className={mergeClassNames(classes, tileClassName)}
        disabled={
          (minDate && minDateTransform(minDate) > date)
          || (maxDate && maxDateTransform(maxDate) < date)
          || (tileDisabled && tileDisabled({ activeStartDate, date, view }))
        }
        onClick={onClick && (() => onClick(date))}
        onMouseOver={onMouseOver && (() => onMouseOver(date))}
        onFocus={onMouseOver && (() => onMouseOver(date))}
        style={style}
        type="button"
      >
        {formatAbbr
          ? (
            <abbr aria-label={formatAbbr(locale, date)}>
              <div><div className="react-calendar_week-name"> {day} </div><div className="react-calendar__month-view-number">{children}<div className="dot-calendar"></div></div></div>
            </abbr>
          )
          : <div><div> {day} </div><div className="react-calendar__month-view-number">{children}<div className="dot-calendar"></div></div></div>
        }
        {tileContent}
      </button>
    );
  }
}

Tile.propTypes = {
  ...tileProps,
  children: PropTypes.node.isRequired,
  formatAbbr: PropTypes.func,
  maxDateTransform: PropTypes.func.isRequired,
  minDateTransform: PropTypes.func.isRequired
};
