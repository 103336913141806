import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Doughtnut from '../../components/Doughtnut';
import List from '../../components/List';
import { enums, timeDuration } from '../../Utility/const'
import util from '../../util';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../redux/actions/actionType';
import { ACTION_API, consts } from '../../Utility/const';
import FormDialog from '../../components/FormDialog';
import swal from 'sweetalert';
import { request, apis } from '../../httpUtil';
import Bar from '../../components/Bar';
import moment from 'moment';

let dispatch = null;
const loadData = async (caseId, formFields) => {
    if (caseId) {
        const params = Object.assign({ action: ACTION_API.LOAD, caseId });
        const response = await request(apis.case, params);
        const data = { updatedData: response.data, formFields: formFields }
        if (response.data) {
            dispatch({ type: actions.LOAD_PROFILE_DATA, data });
        }
    }
}
const useStyles = makeStyles({
    root: {
        '& .MuiInputLabel-formControl': {
            fontSize: '14px'
        },
        '& .MuiTableSortLabel-root:hover': {
            color: 'rgb(255, 255, 255)'
        },
        '& .MuiTableSortLabel-icon': {
            marginRight: '0  !important',
            marginLeft: '0  !important'
        },
        '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
            marginRight: '0  !important',
            marginLeft: '0  !important'
        },
        '& .MuiTableCell-head': {
            color: '#FFFFFF',
            padding: '14px 0px !important'
        },
        '& .MuiAppBar-colorDefault': {
            backgroundColor: '#2196f3',
            color: '#FFFFFF'
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            background: '#5062e5'
        }
    },
    searchBox: {
        padding: '.5em 2em 1.5em 2em'
    },
    bottomSection: {
        marginTop: '0em'
    },
    medColor: {
        background: '#FFC000'
    },
    highColor: {
        background: '#ED7D31'
    },
    lowColor: {
        background: '#70AD47'
    },
    pieChart: {
        padding: '0em',
        textAlign: 'center',
        fontSize: '14px',
    },
    tabpanel: {
        padding: '0px'
    },
    addButton: {
        color: '#FFFFFF'
    },
    tableWrapper: {
        marginTop: '.2em'
    },
    container: {
        maxHeight: 365,
        '& .MuiTableRow-root.MuiTableRow-hover:hover': {
            backgroundColor: '#FFE699   !important'
        }
    }
});
const aptFormFields = [
    {
        "label": "Start Date",
        "name": "appointmentDate",
        "type": "dateTime",
        "id": "appointmentDate",
        "format": "MM/dd/yyyy",
        "validations": [
            "required",
            {
                "type": "date",
                "min": "01/01/1900",
                "max": moment().add(1, 'years').format('MM/DD/YYYY')
            }
        ],
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Start Time",
        "name": "appointmentTime",
        "type": "time",
        "id": "appointmentTime",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        },
        "validations": [
            "required"
        ]
    },
    {
        "label": "Duration",
        "name": "duration",
        "type": "dropdown",
        "id": "duration",
        "items": timeDuration,
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        },
        "validations": [
            "required"
        ]
    },
    {
        "label": "Type",
        "name": "appointmentTypeId",
        "type": "dropdown",
        "id": "appointmentTypeId",
        "comboType": "AppointmentType",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        },
        "validations": [
            "required"
        ]
    },
    {
        "label": "Status",
        "name": "statusId",
        "type": "dropdown",
        "id": "statusId",
        "comboType": "AppointmentStatus",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        },
        "validations": [
            "required"
        ]
    },
    {
        "label": "Issue",
        "name": "issue",
        "type": "text",
        "id": "issue",
        "multiline": true,
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Notes",
        "name": "notes",
        "type": "text",
        "id": "notesId",
        "multiline": true,
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    }
]
const isIpad = window.innerWidth <= 1024;
const columns = [
    {
        title: "Start Time", field: "appointmentDateTime", type: 'date', width: isIpad ? "13%" : "13%",
        render: rowData => {
            return util.renderer.dateTime('appointmentDateTime', rowData)
        }
    },
    {
        title: "Duration", field: "duration", width: isIpad ? "7%" : "8%",
    },
    {
        title: "Mother's Name", field: "motherName", cellStyle: {
            color: '#2196F3'
        }, type: 'string', width: isIpad ? "14%" : "15%"
    },
    {
        title: "Type", field: "AppointmentType", type: 'string', width: isIpad ? "10%" : "10%"
    },
    {
        title: "Status", field: "AppointmentStatus", type: 'string', width: isIpad ? "10%" : "10%"
    },
    { title: "Issue", field: "issue", width: isIpad ? "10%" : "20%" },
    { title: "Created By", field: "createdBy", width: isIpad ? "11%" : "11%" },
    {
        title: "Modified On", field: "updatedAt", type: 'date', width: isIpad ? "11%" : "12%",
        render: rowData => { return util.renderer.date('updatedAt', rowData) }
    }
]

const Appointments = (props) => {
    dispatch = useDispatch();
    const classes = useStyles();
    const [showForm, setShowForm] = useState(false);
    const [appointmentId, setAppointmentId] = useState(0);
    const [formSubmit, setFormSubmit] = useState(false);
    let [searchValue, setSearchValue] = useState({ searchPramas: '' });
    let { fromDashboard } = props;
    let formFields = useSelector(state => state.appReducer.formFields); // select fields from store
    let fields = util.getGroupedFields(formFields); // devide fields by section
    const motherContact = useSelector(state => state.appReducer.motherContact);
    const chartData = useSelector(state => state.appReducer.appointmentChartData) || {};
    const chartFilter = useSelector(state => state.appReducer.chartFilter);
    let activeCaseId = util.getCaseId();
    useEffect(function () {
        let motherDataAvailable = motherContact && motherContact.filter(rec => rec.caseId === activeCaseId).length > 0;
        if (!fromDashboard && !motherDataAvailable) {
            loadData(activeCaseId, fields);
        }
    }, []);
    const onAdd = () => {
        dispatch({ type: actions.SET_APPOINTMENT_DATA, data: { issue: '', notes: '' } });
        setShowForm(true);
        setAppointmentId(0);
    }
    const handleClose = () => {
        setShowForm(false);
    }
    const onSave = async () => {
        const storeData = util.getStore();
        let data = storeData.appointmentData;
        data.caseId = activeCaseId || data.caseId;
        if (appointmentId > 0) {
            data.id = appointmentId;
        }
        let errors, isValid = true;
        aptFormFields.forEach(s => {
            if (!isValid) {
                return;
            }
            const { name, validations } = s;
            let value;
            if (data) {
                value = data[name];
                if (validations && validations.length > 0 && value === undefined) {
                    if (validations.includes('required')) {
                        value = '';
                    }
                }

                errors = util.validation(s, value);
                if (errors && errors.length > 0) {
                    isValid = false;
                }
            }
        });
        if (!isValid) {
            swal({
                title: errors[0].helperText || "Fill required fields.",
                icon: "error",
                dangerMode: true
            });
            setFormSubmit(true);
        } else {
            let appointmentDate = moment(data.appointmentDate).toDate();
            const appointmentTime = moment(data.appointmentTime);
            appointmentDate.setHours(appointmentTime.hours());
            appointmentDate.setMinutes(appointmentTime.minutes());
            data.appointmentDateTime = appointmentDate;
            const responseAdd = await request(apis.appointment, { action: 'Save', data: data });
            if (responseAdd && responseAdd.success) {
                dispatch({ type: 'SET_NOTIFICATION_DATA', notifications: responseAdd.notificationData });
                swal('Data saved successfully', { icon: "success", });
                handleClose();
            }
            if (!responseAdd.success) {
                util.listErrorAlert(responseAdd.error);
            }
        }
    }
    const changeAction = {
        icon: 'edit_outline',
        tooltip: 'Edit',
        onClick: (event, rowData) => {
            rowData.appointmentDate = rowData.appointmentDateTime;
            rowData.appointmentTime = rowData.appointmentDateTime;
            dispatch({ type: actions.SET_APPOINTMENT_DATA, data: rowData });
            setShowForm(true);
            setAppointmentId(rowData.appointmentId);
        }
    }

    const handleSearch = (e, value) => {
        e.preventDefault();
        setSearchValue({ ...searchValue, ...{ searchPramas: value } });
    }
    const expandAction = (data) => {
        return (
            {
                icon: () => <div id="customExpand" name='customExpand'>
                    {data.tableData && data.tableData.showDetailPanel ? util.openDescriptionIcon(data.notes) : util.descriptionIcon(data.notes)}
                </div>,
                tooltip: 'Detail Information'
            }
        )
    }
    const today = moment(new Date()).format(util.dateFormat);
    const pieChartTitleRight = chartFilter && chartFilter.month != 0 ? "(Selected Month)" : moment(new Date()).format(util.chartDateFormat);
    searchValue = Object.assign(searchValue, { dateRange: props.dateRange });
    return (<div className={classes.root}>
        <FormDialog formSubmit={formSubmit} dataGroup='appointmentData' open={showForm} formFields={aptFormFields} onSave={onSave} title='Appointment' handleClose={handleClose} activeCaseId={activeCaseId} id={appointmentId} />
        {!fromDashboard && <Grid container justify="center">
            <Grid item xs={5} md={5} lg={5} align="center">
                <Bar chartData={chartData} chartHeader='Case Appointments Status'
                    typeCombo={enums.lookupType.AppointmentStatus}
                    statusCombo={enums.lookupType.AppointmentStatus}
                    pieChartTitleRight={pieChartTitleRight} isAppointment={true} legend={false} width={380} />
            </Grid>
            <Grid item xs={7} md={7} lg={7} align="center" className="pdl-1">
                <Bar chartData={chartData} chartHeader={`Case Appointments by Type`} typeCombo={enums.lookupType.AppointmentType}
                    statusCombo={enums.lookupType.AppointmentStatus} pieChartTitleRight={pieChartTitleRight}
                    xAxisLabel='Appointment Amount' yAsisLabel="Appointment Type" />
            </Grid>
        </Grid>}
        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.tableWrapper}>
                <List columns={columns}
                    showForm={showForm}
                    actions={[expandAction, changeAction]}
                    // formRoute='/appointments/'
                    doubleClick={!fromDashboard}
                    routeTitle={consts.Appointments}
                    //showDateSearch={true}
                    onAdd={!fromDashboard && onAdd}
                    fromDashboard={fromDashboard}
                    options={{ toolbar: true }}
                    isExpand={true}
                    activeCaseId={activeCaseId}
                    defaultSort="appointmentDateTime"
                    sortDir="desc"
                    listOption={{
                        api: apis.appointment,
                        listName: 'appointmentList',
                        reduxActions: actions.SET_APPOINTMENT_LIST
                    }}
                    addTitle="Add Appointment"
                    tableHeader='Appointments Monitor'
                    searchValue={searchValue}
                    handleSearch={handleSearch}
                />
            </Grid>
        </Grid>
    </div>)
}
export default Appointments;