import React, { useState } from 'react';
import { apis, request } from '../../httpUtil';
import util from '../../util';
import List from '../../components/List';
import swal from 'sweetalert';
import actions from '../../redux/actions/actionType';
import { enums, resourceFormFields } from '../../Utility/const';
import { history } from '../../redux/store/';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Grid, IconButton } from '@material-ui/core';
import FormDialog from '../../components/FormDialog';

const moment = require('moment');
const isIpad = window.innerWidth <= 1024;
const ExpandIcon = () => {
    return <div><svg id="customExpand" width="22" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99998 3.34417L7.90581 6.25L9.19831 4.9575L4.99998 0.75L0.79248 4.9575L2.09415 6.25L4.99998 3.34417ZM4.99998 14.6558L2.09415 11.75L0.801647 13.0425L4.99998 17.25L9.20748 13.0425L7.90581 11.75L4.99998 14.6558Z" fill='#008000' />
    </svg></div>
}

const useStyles = makeStyles({
    root: {
        '& .MuiTableBody-root .MuiTableCell-root': {
            paddingRight: '0.5em!important'
        }
    },
    tableWrapper: {
        marginTop: '.2em'
    }
});

const columns = [
    {
        title: "Link", field: "link", cellStyle: { color: '#2196F3', wordBreak: 'break-word' }, width: '20%',
        render: rowData => {
            return <div onClick={() => onLinkClick(rowData.link)}>{rowData.link}</div>
        }
    },
    {
        title: "Resource Type", field: "resourceTypeId", width: '15%', render: rowData => {
            return util.renderer.combo('resourceTypeId', rowData, enums.lookupType.Resource)
        }
    },
    { title: "Title", field: "title", type: 'string', width: '22%' },
    { title: "Source Name", field: "resourceName", width: '15%' },
    {
        title: "Created On", field: "createdAt", type: 'date', width: '15%',
        render: rowData => { return util.renderer.dateTime('createdAt', rowData) }
    },
    { title: "Created By", field: "createdBy", type: 'string' }
]

const onLinkClick = (link) => {
    link && window.open(link);
}

let dispatch;

export const resourceDetailFields = [
    { "label": "Tags", "field": "tags" },
    { "label": "Notes", "field": "notes" },
    { "label": "Points", "field": "point" }
]

const expandAction = {
    icon: ExpandIcon,
    tooltip: 'Detail Information',
    onClick: (event, rowData) => {
        const wrapper = document.createElement(`div`);
        wrapper.innerHTML = util.detailInfo(rowData, resourceDetailFields, util.renderer);
        swal({
            title: "Resource Detail Information",
            content: wrapper
        });
    }
}

const Resource = (props) => {
    dispatch = useDispatch();
    let classes = useStyles();
    let [searchValue, setSearchValue] = React.useState({ searchPramas: '' });
    const [showForm, setShowForm] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const [resourceId, setResourceId] = useState(0);
    const handleSearch = (e, value) => {
        e.preventDefault();
        setSearchValue({ ...searchValue, ...{ searchPramas: value } });
    }
    const onAdd = () => {
        dispatch({ type: actions.SET_RESOURCE_DATA, data: {} });
        setShowForm(true);
        setResourceId(0);
    }

    const changeAction = {
        icon: 'edit_outline',
        tooltip: 'Edit',
        onClick: (event, rowData) => {
            dispatch({ type: actions.SET_RESOURCE_DATA, data: rowData });
            setShowForm(true);
            setResourceId(rowData.id)
        }
    }

    const handleClose = () => {
        setShowForm(false);
    }

    const onSave = async () => {
        const storeData = util.getStore();
        let data = storeData.resourceData;
        if (resourceId > 0) {
            data.id = resourceId;
        }
        let errors, isValid = true;
        resourceFormFields.forEach(s => {
            if (!isValid) {
                return;
            }
            const { name, validations } = s;
            let value;
            if (data) {
                value = data[name];
                if (validations && validations.length > 0 && value === undefined) {
                    if (validations.includes('required')) {
                        value = '';
                    }
                }
                errors = util.validation(s, value);
                if (errors && errors.length > 0) {
                    isValid = false;
                }
            }
        });
        if (!isValid) {
            swal({
                title: errors[0].helperText || "Fill required fields.",
                icon: "error",
                dangerMode: true
            });
            setFormSubmit(true);
        } else {
            const responseAdd = await request(apis.resource, { action: 'Save', data: data });
            if (responseAdd && responseAdd.success) {
                swal('Data saved successfully', { icon: "success", });
                handleClose();
            }
            if (!responseAdd.success) {
                util.listErrorAlert(responseAdd.error);
            }
        }
    }

    return (
        <div className="case-section">
            <Grid container spacing={0} className={classes.root}>
                <Grid item xs={12} className={classes.tableWrapper}>
                    <List columns={columns}
                        showForm={showForm}
                        searchValue={searchValue}
                        doubleClick={false}
                        history={history}
                        actions={[changeAction, expandAction]}
                        onAdd={onAdd}
                        options={{ toolbar: true }}
                        defaultSort="createdAt"
                        sortDir="desc"
                        // className='table-expand-wrapper-stick'
                        listOption={{
                            api: apis.resource,
                            listName: 'resourceList',
                            reduxActions: 'SET_RESOURCE_LIST'
                        }}
                        addTitle='Add Resource'
                        tableHeader='Resource'
                        handleSearch={handleSearch}
                    />
                </Grid>
            </Grid>
            <FormDialog formSubmit={formSubmit} dataGroup='resourceData' open={showForm} formFields={resourceFormFields} onSave={onSave} title='Resource' handleClose={handleClose} id={resourceId} />
        </div>
    )
}
export default React.memo(Resource);