import * as React from 'react';
import './App.css';
import { AppBar } from './navigation';
import { Router } from 'react-router';
import { Provider } from 'react-redux';
import { history, store } from './redux/store';
import blue from '@material-ui/core/colors/blue';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import AlertDialogSlide from '../src/components/Dialog';

const theme = createMuiTheme({
  palette: {
    primary: blue
  }
})
function App() {
  return (
    <Provider store={store}>
      <AlertDialogSlide />
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <AppBar />
        </MuiThemeProvider>
      </Router>
    </Provider>
  );
}

export default App;