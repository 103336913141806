import React, { useState, useEffect, useCallback } from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions/actionType';
import { request, apis } from '../../httpUtil/';
import loginImg from '../../img/login.png';
import loginIconImg from '../../img/login-icon.png';
import IconButton from '@material-ui/core/IconButton';
import versionNumber from '../../version.json';
import { Grid, TextField, InputAdornment, Checkbox } from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import util from '../../util';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '7px 4px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
    },
    form: {
        width: 450, // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        margin: '0 auto'
    },
    alert: {
        marginTop: '1.5em',
        padding: '0 10px!important'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    mainDiv: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        background: '#fafafa'
    },
    loginWrapper: {
        background: '#FFFFFF',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundRepeat: 'no-repeat',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        padding: '3em 0'
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        padding: '1em 0',
        '& h2': {

            fontSize: '3em',
            color: '#006dc3'
        }
    },
    subTitle: {
        color: '#a7a7a7',
        '& p': {
            margin: 0,
            fontSize: '14px'
        }
    },
    loginForm: {
        padding: '6% 0 13% 0',
        '& input': {
            width: '100%',
            padding: '1em 0 0em 0',
            height: '2em'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid rgb(198, 198, 198)'
        },
        '& .MuiInputLabel-animated': {
            fontSize: '1em'
        },
        '& .MuiFormControl-root': {
            width: '85%'
        }
    },
    forgotpassword: {
        fontSize: '14px',
        color: '#a7a7a7',
        padding: '0em 0 1em 0',
        '& a': {
            paddingLeft: '2em',
            color: '#a7a7a7',
            textDecoration: 'none'
        },
        '& a:hover': {
            color: '#006dc3'
        }
    },
    login: {
        top: '2em;',
        width: '85%',
        background: '#2196F3',
        color: '#fff',
        padding: '.75em 0',
        '&:hover': {
            background: '#2196F3',
            borderRadius: 0
        }
    },
    alert: {
        marginTop: '1em',
        maxWidth: '100%',
        padding: '0 !important'
    },
    fotgot: {
        fontWeight: '500',
        fontSize: '14px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        color: '#2196F3',
        cursor: 'pointer'
    },
    divider: {
        borderRight: '1px solid #9E9E9E',
        margin: '0 7px 0 6px',
        padding: '2px 0'
    },
    rememberCont: {
        display: 'flex',
        width: '90%'
    },
    termPolicy: {
        width: '100%',
        textAlign: 'center',
        marginTop: '2em',
        cursor: 'pointer'
    }
}));

function SignIn({ history }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alertStatus, setAlertStatus] = useState({ open: false });
    const appTitle = useSelector(state => state.appReducer.appTitleName);
    const [showPassword, setshowPassword] = useState(false);

    const processLogin = useCallback(async (params, event) => {
        if (event) {
            event.preventDefault();
        }
        const response = await request(params ? apis.login : apis.check_login, params);
        dispatch({ type: actions.SET_SHOW_LOADER, showLoader: false });
        if (response && response.data) {
            util.setLoginData(response, dispatch);
            history.push('/dashboard');
            util.setRecentMessage(response, dispatch)
        } else {
            dispatch({
                type: actions.RESET_STATE
            });
            if (params) {
                setAlertStatus({ error: response.error, open: true });
            }
        }
    }, [dispatch, history])

    useEffect(() => {
        processLogin(null);
        dispatch({ type: actions.SET_ACTIVE_TAB, activeTab: 1 });
    }, [processLogin]);

    const handleClose = () => {
        setAlertStatus({ error: '', open: false });
    }
    const onLogin = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (!email || !password) {
            setAlertStatus({ error: 'Email   and password required.', open: true });
            return;
        }
        dispatch({ type: actions.SET_SHOW_LOADER, showLoader: true });
        let params = {
            emailAddress: email,
            password: password
        };
        processLogin(params, event);
    }

    const handleClickShowPassword = () => {
        setshowPassword(!showPassword)
    }

    const onTermPolicyClick = () => {
        window.open(window.location.hash + 'termpolicy');
    }
    return (
        <Grid container xs={12} md={12} lg={12} className={classes.mainDiv + ' vertical-align'} >
            <Grid container xs={12} md={12} lg={12}>
                <Grid xs={1} md={1} lg={2} />
                <Grid xs={5} md={5} lg={4}>
                    <div className={classes.loginWrapper}>
                        <div className={classes.title}>
                            <img src={loginIconImg} alt={'Logout'} height='60' width='60' />
                            <h3 className="pdl-1">{appTitle}</h3>
                        </div>
                        <div className="login-form">
                            <div className="wel-text">Welcome Back</div>
                            <form className={classes.loginForm} autoComplete="off">
                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Grid className="full-width mrb-1-5">
                                        <TextField id="signin" label="Email Address" autoComplete='off' onChange={value => setEmail(value.target.value)} value={email} />
                                    </Grid>
                                    <Grid className="full-width">
                                        <TextField id="password" label="Password" autoComplete='off' type={showPassword ? 'text' : 'password'} onChange={value => setPassword(value.target.value)} value={password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                        >
                                                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <div className={classes.rememberCont}>
                                        <div className="remeb-me">
                                            <Checkbox color="primary" />
                                            <label>Remember me</label>
                                        </div>
                                        <div className='web-version'>Version: {versionNumber.version}</div>
                                    </div>
                                    {alertStatus.open ? <div><Alert onClose={handleClose} severity="error" className={classes.alert}>
                                        {alertStatus.error}
                                    </Alert></div> : null
                                    }
                                </Grid>
                                <Button variant="contained" type="submit" className={classes.login} onClick={onLogin}>
                                    sign in
                                        </Button>
                            </form>
                            <Link href="/" variant="body2" className={classes.fotgot}>Create an account</Link>
                            <span className={classes.divider} />
                            <Link href="#/forgotPassword" variant="body2" className={classes.fotgot}>Forgot password</Link>
                        </div>
                    </div>
                </Grid>
                <Grid xs={5} md={5} lg={4}>
                    <img src={loginImg} alt={'Logout'} className="full-width" />
                </Grid>
                <Grid xs={1} md={1} lg={2} />
                <div className={classes.termPolicy} onClick={onTermPolicyClick}>Terms and Privacy Policy</div>
            </Grid>
        </Grid>
    );
}

export default React.memo(SignIn);