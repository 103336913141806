import React from 'react';
import util from '../src/util';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { enums } from '../src/Utility/const';
import logo from "./img/DE_POSC_Short_Version_2019-0201_html_f957fa7c.jpg"

const tdClass = { border: '1px solid #00000a', paddingTop: '0.08in', paddingBottom: '0.08in', paddingLeft: '0.08in', paddingRight: '0.08in' }

const PrintDocument = (props) => {
    let formFields = useSelector(state => state.appReducer.formFields);
    let fields = util.getGroupedFields(formFields);
    let data = util.getStore();
    let {
        caseData = {},
        motherContact = [],
        fatherContact = [],
        careGiverContact,
        siblingData,
        caseSubstance = [],
        contacts = [],
        infantNeeds = [],
        motherNeeds = [],
        fatherNeeds = [],
        educationTags = [],
        microEducationTags = [],
        otherEducationtags = [],
        consent = [],
        infantSafeTags = [],
        childSafetyTags = [],
        monitoring = [],
        otherSupportPersonData = [],
        otherSubstanceExpoData = [] } = data;

    let { fullName: mFullname, birthdate: mBirthdate, streetAddress1: mStreetAddress1 = '', city: mCity, stateId: mStateId, zip: mZip, cellNumber: mCellNumber, employerName: mEmployerName, employerNumber: mEmployerNumber, countyId: mCountyId, streetAddress2: mStreetAddress2 = '', email: mEmail } = (motherContact && motherContact[0]) || [];
    let { fullName: fFullName, birthdate: fBirthdate, streetAddress1: fStreetAddress1 = '', city: fCity, stateId: fStateId, zip: fZip, cellNumber: fCellNumber, employerName: fEmployerName, employerNumber: fEmployerNumber, streetAddress2: fStreetAddress2 = '', email: fEmail } = (fatherContact && fatherContact[0]) || [];
    let { fullName: cFullName, relationId: cRelationId, birthdate: cBirthdate, email: cEmail } = (careGiverContact && careGiverContact[0]) || [];


    let setcionFields = (fields && fields['planOfSafeCareFields'] && fields['planOfSafeCareFields']['contacts']) || [];
    contacts = util.getDocData(setcionFields, contacts);
    let caseSubstanceFields = (fields && fields['subExpoFields'] && fields['subExpoFields']['caseSubstance']) || [];
    caseSubstance = util.getDocData(caseSubstanceFields, caseSubstance);

    let infantNeedFields = (fields && fields['infantNeedFields'] && fields['infantNeedFields']['assessmentNeed']) || [];
    infantNeeds = util.getDocData(infantNeedFields, infantNeeds);

    let motherNeedsFields = (fields && fields['motherNeedFields'] && fields['motherNeedFields']['assessmentNeed']) || [];
    motherNeeds = util.getDocData(motherNeedsFields, motherNeeds);

    let fatherNeedFields = (fields && fields['fatherNeedFields'] && fields['fatherNeedFields']['assessmentNeed']) || [];
    fatherNeeds = util.getDocData(fatherNeedFields, fatherNeeds);

    let healthEduFields = (fields && fields['healthEduFields'] && fields['healthEduFields']['educationTags']) || [];
    educationTags = util.getDocData(healthEduFields, educationTags);

    let healthMicroEducationTags = (fields && fields['healthEduFields'] && fields['healthEduFields']['microEducationTags']) || [];
    microEducationTags = util.getDocData(healthMicroEducationTags, microEducationTags);

    let healthotherEducationtags = (fields && fields['healthEduFields'] && fields['healthEduFields']['otherEducationtags']) || [];
    otherEducationtags = util.getDocData(healthotherEducationtags, otherEducationtags);

    let infantSafeFields = (fields && fields['infantSafeFields'] && fields['infantSafeFields']['infantSafeTags']) || [];
    infantSafeTags = util.getDocData(infantSafeFields, infantSafeTags);

    let consentFields = (fields && fields['consentFields'] && fields['consentFields']['consent']) || [];
    consent = util.getDocData(consentFields, consent);
    let consentData = util.getConsentData(consent)

    let childSafetyFields = (fields && fields['childSafetyFields'] && fields['childSafetyFields']['childSafetyTags']) || [];
    childSafetyTags = util.getDocData(childSafetyFields, childSafetyTags);

    let monitoringFields = (fields && fields['monitoringFields'] && fields['monitoringFields']['monitoring']) || [];
    monitoring = util.getDocData(monitoringFields, monitoring);

    siblingData = siblingData.filter(e => e.isDeleted == 0);


    const combos = useSelector(state => state.appReducer["comboData"]);
    const relationshipData = combos && combos[enums.lookupType["Relationship"]];

    const careGiverRelationship = combos && combos[enums.lookupType["CareGiverRelationship"]];

    let infantWeight = caseData && caseData.infantWeight && JSON.parse(caseData.infantWeight) || ''

    let childSafety = false;

    let fState = combos && fStateId && combos[enums.lookupType.State].filter(e => e.value == fStateId)[0] || {};
    let mState = combos && mStateId && combos[enums.lookupType.State].filter(e => e.value == mStateId)[0] || {};
    let infantBirthHospital = combos && caseData && caseData.infantBirthHospitalId && combos[enums.lookupType.Hospitals].filter(e => e.value == caseData.infantBirthHospitalId)[0] || {};
    let mCounty = combos && mCountyId && combos[enums.lookupType.County].filter(e => e.value == mCountyId)[0] || {};

    caseSubstance && caseSubstance.map(e => {
        if (e.label == 'Opioids' && e.value) {
            let comboArray = combos && combos[enums.lookupType.Opioids] || [];
            let selectedArray = e.value.split(',')
            e.label = '';
            comboArray.map(x => {
                if (selectedArray.indexOf(x.value.toString()) > -1) {
                    e.label = e.label + (e.label && ',') + x.label;
                }
            })
        }
    })
    caseSubstance = caseSubstance.filter(item => item.label !== 'Opioids');

    //CMS #214 hide the field
    childSafetyTags = childSafetyTags.filter(item => !(item.typeId == enums.lookup.DFSWorkerNamePhoneNumber || item.typeId == enums.lookup.DateOfDischarge));

    childSafetyTags.map(e => {
        if ((e.type == "dropdown" || e.type == 'autocomplete') && e.value) {
            let comboArray = combos && combos[enums.lookupType[e.comboType]] || [];
            let filterData = comboArray.filter(el => el.value == Number(e.value))[0] || '';
            e.value = filterData && filterData.label ? filterData.label : '';
        }
        if (e.typeId == enums.tagType.infantDischargeDate) {
            e.value = util.getDefaultValueForConsent(e.typeId, 'value')
            e.value = e.value ? moment(e.value).format(util.dateFormat) : '';
        }
    })

    motherNeeds = util.getlabelValuefromCombo(motherNeeds);
    fatherNeeds = util.getlabelValuefromCombo(fatherNeeds);
    infantNeeds = util.getlabelValuefromCombo(infantNeeds);
    motherNeeds = motherNeeds.filter(item => !((item.label === 'Substance Use/Abuse') || (item.label === 'Mental Health') || (item.label === 'Other' && item.value === "")));
    fatherNeeds = fatherNeeds.filter(item => !((item.label === 'Substance Use/Abuse') || (item.label === 'Mental Health') || (item.label === 'Other' && item.value === "")));
    infantNeeds = infantNeeds.filter(item => item.label !== 'Other');
    return (<div id='doc' style={{ display: 'none' }}>
        <div style={{ position: 'relative' }}>
            <p align="center" style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={4} style={{ fontSize: '16pt' }}><b>STATE OF DELAWARE</b></font></font><span className="sd-abs-pos" style={{ position: 'absolute', top: '0in', left: '1in', width: '103px' }}></span></p>
            <p align="center" style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={4} style={{ fontSize: '16pt', borderBottom: '1px solid #00000a' }}><b>PLAN OF SAFE CARE (Short Version)</b></font></font>
            </p>
            <p align="center" style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}>For Infants with Prenatal Substance Exposure and their Families </font></font>
            </p>
            <p style={{ marginBottom: '0.11in', borderTop: 'none', borderBottom: '1.50pt solid #00000a', borderLeft: 'none', borderRight: 'none', paddingTop: '0in', paddingBottom: '0.01in', paddingLeft: '0in', paddingRight: '0in' }}><font face="Times New Roman, serif"><font size={3}>						</font></font>
            </p>
            <p align="JUSTIFY" style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}>This
            Plan of Safe Care is being developed to identify necessary services
            and supports for the infant, mother, and family upon discharge from
            the birthing hospital.  The goal is to ensure the safety and
            well-being of the infant while providing the family with ongoing
            services such as physical and mental health, substance use treatment,
              parenting education and other family needs.</font></font>
            </p>
            <div style={{ width: '863px', display: 'flex', flexDirection: 'row' }}>
                <p style={{ marginBottom: '0.11in', width: '501px' }}><font face="Times New Roman, serif"><font size={3}><u><b>Sectio I: Family Information</b></u></font></font>
                </p>
                <p style={{ marginBottom: '0.11in', width: '272px', marginLeft: '0.11in' }}><font face="Times New Roman, serif"><font size={3}></font></font><font face="Times New Roman, serif"><font size={3}><u><b>Date of Discharge Meeting</b></u></font></font><font face="Times New Roman, serif"><font size={3}><b>:</b></font></font>
                </p>
            </div>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={501} />
                    <col width={172} />
                    <col width={147} />
                </colgroup>
                <tbody>
                    <tr >
                        <td width={501} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Infant’s Name: </font></font><font><font face="inherit"><font size={3}>{caseData && caseData.infantName}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={172} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Date of Birth: </font></font><font><font face="inherit"><font size={3}>{caseData ? moment(caseData.infantBirthDate).format('L') : null}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={147} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Gender: </font></font><font><font face="inherit"><font size={3}>{caseData && caseData.infantGender}</font></font>
                            </font>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td width={501} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Birth Hospital: </font></font><font><font face="inherit"><font size={3}>{infantBirthHospital.label}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={172} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Weight: </font></font><font><font face="inherit"><font size={3}>{`${infantWeight.lb || ''}LB ${infantWeight.oz || ''}OZ`}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={147} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Discharge Date: </font></font><font><font face="inherit"><font size={3}>{caseData && caseData.infantDischargeDate ? moment(caseData.infantDischargeDate).format('L') : null}</font></font>
                            </font>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td width={501} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Case Number: </font></font><font><font face="inherit"><font size={3}>{caseData && caseData.caseNumber}</font></font>
                            </font>
                            </p>
                        </td>
                        <td colSpan={2} width={333} style={{ ...tdClass }}>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={501} />
                    <col width={333} />
                </colgroup>
                <tbody>
                    <tr >
                        <td width={501} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Mother’s Full Name: </font></font><font><font face="inherit"><font size={3}>{mFullname}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={333} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Date of Birth: </font></font><font><font face="inherit"><font size={3}>{mBirthdate ? moment(mBirthdate).format('L') : null}</font></font>
                            </font>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td width={501} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Street Address: </font></font><font><font face="inherit"><font size={3}>{`${mStreetAddress1} ${mStreetAddress2 && ', ' + mStreetAddress2}`}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={333} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>City/State/ZIP Code: </font></font><font><font face="inherit"><font size={3}>
                                {`${mCity ? mCity : ''}${mState && mState.label ? ('/' + mState.label + '/') : ''}${mZip}`}</font></font>
                            </font>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td width={501} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Cell Number: </font></font><font><font face="inherit"><font size={3}>{mCellNumber}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={333} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Employer Name/Number: </font></font><font><font face="inherit"><font size={3}>{`${mEmployerName || ''}${mEmployerNumber ? '/' + mEmployerNumber : ''}`}</font></font>
                            </font><font face="Times New Roman, serif"><font size={3}></font></font>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td width={501} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>County: </font></font><font><font face="inherit"><font size={3}>{mCounty && mCounty.label}</font></font>
                            </font>
                            </p>
                        </td>
                        <td colSpan={2} width={333} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Mother's Email: </font></font><font><font face="inherit"><font size={3}>{mEmail && mEmail}</font></font>
                            </font>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={501} />
                    <col width={333} />
                </colgroup>
                <tbody>
                    <tr >
                        <td width={501} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Father’s Full Name: </font></font><font><font face="inherit"><font size={3}>{fFullName}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={333} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Date of Birth: </font></font><font><font face="inherit"><font size={3}>{fBirthdate ? moment(fBirthdate).format('L') : null}</font></font>
                            </font>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td width={501} style={{ ...tdClass }}>
                            <p style={{ marginBottom: '0in' }}><font face="Times New Roman, serif"><font size={3}>Street Address: </font></font><font><font face="inherit"><font size={3}>{`${fStreetAddress1} ${fStreetAddress2 && ', ' + fStreetAddress2}`}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={333} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>City/State/ZIP Code: </font></font><font><font face="inherit">
                                <font size={3}>{`${fCity ? fCity : ''}${fState && fState.label ? ('/' + fState.label + '/') : ''}${fZip}`}</font></font>
                            </font>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td width={501} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Cell Number: </font></font><font><font face="inherit"><font size={3}>{fCellNumber}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={333} style={{ ...tdClass }}>
                            <p style={{ marginBottom: '0in' }}><font face="Times New Roman, serif"><font size={3}>Employer Name/Number: </font></font><font><font face="inherit"><font size={3}>{`${fEmployerName || ''}${fEmployerNumber ? '/' + fEmployerNumber : ''}`}</font></font>
                            </font>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width={501} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Father's Email: </font></font><font><font face="inherit"><font size={3}>{fEmail && fEmail}</font></font>
                            </font>
                            </p>
                        </td>
                        <td width={501} style={{ ...tdClass }}></td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={381} />
                    <col width={106} />
                    <col width={4361} />
                    <col width={327} />
                </colgroup>
                <tbody>
                    <tr >
                        <td colSpan={2} width={501} style={{ ...tdClass }}>
                            <p style={{ marginBottom: '0in' }}><font face="Times New Roman, serif"><font size={3}>Other Caregiver's Full Name: </font></font><font>
                                <font face="inherit"><font size={3}>{cFullName}</font></font>
                            </font>
                            </p>
                        </td>
                        <td colSpan={2} width={333} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}>Date of Birth:
                                </font></font><font><font face="inherit"><font size={3}>{cBirthdate ? moment(cBirthdate).format('L') : null}</font></font>
                                </font>
                            </p>
                        </td>
                    </tr>
                    <tr >
                        <td colSpan={2} width={501} style={{ ...tdClass }}>
                            <p style={{ marginBottom: '0in' }}><font face="Times New Roman, serif"><font size={3}><span>Relationship
                                to Parent: </span></font></font><font><font face="inherit"><font size={3}><span>{careGiverRelationship && careGiverRelationship.map(item => item.value === cRelationId && item.label)}</span></font></font>
                                </font>
                            </p>
                        </td>
                        <td colSpan={2} width={333} style={{ ...tdClass }}>
                            <p style={{ marginBottom: '0in' }}><font face="Times New Roman, serif"><font size={3}><span>Other Caregiver's
                                Email: </span></font></font><font><font face="inherit"><font size={3}><span>{cEmail || ''}</span></font></font>
                                </font>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={401} />
                    <col width={222} />
                    <col width={197} />
                </colgroup>
                <tbody>
                    {
                        siblingData && siblingData.map((sibling, index) => {
                            let siblingName = (`${sibling.firstName || ``} ${sibling.lastName || ``}`).trim();
                            if (siblingName) {
                                return (
                                    <tr >
                                        <td width={401} style={{ ...tdClass }}>
                                            <font face="Times New Roman, serif"><font size={3}>Sibling {index + 1}'s Full Name: </font></font><font><font face="inherit"><font size={3}>{siblingName}</font></font>
                                            </font>
                                        </td>
                                        <td colSpan={2} width={222} style={{ ...tdClass }}>
                                            <font face="Times New Roman, serif"><font size={3}> DOB: </font></font><font><font face="inherit"><font size={3}>{sibling.birthdate ? moment(sibling.birthdate).format('L') : null}</font></font>
                                            </font>
                                        </td>
                                        <td width={197} style={{ ...tdClass }}>
                                            <p style={{ marginBottom: '0in' }}><font face="Times New Roman, serif"><font size={3}><span>Resides with: </span></font></font><font><font face="inherit"><font size={3}><span>
                                                {relationshipData && relationshipData.map(item => item.value === sibling.relationId && item.label)}
                                            </span></font></font>
                                            </font>
                                            </p>
                                        </td>
                                    </tr>
                                )
                            }
                        })
                    }
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}><u><b>Section
      II: Plan of Safe Care Coordinator and Plan Participants: </b></u></font></font><font face="Times New Roman, serif"><font size={3}>The role of the POSC Coordinator is to prepare, implement, and monitor the POSC for the family.  The POSC Coordinator is responsible for  ensuring appropriate referrals are made and services are delivered to the infant and family.  The POSC Coordinator is the primary point of contact for the family and Plan Participants.  The POSC Coordinator  will provide a copy of the POSC to the Plan Participants within 48  hours after the hospital Plan of Safe Care Discharge Meeting.</font></font>
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={633} />
                    <col width={201} />
                </colgroup>
                <tbody>
                    {contacts.map(d => {
                        return <tr >
                            <td width={633} style={{ ...tdClass }}>
                                <p align="JUSTIFY"><font face="Times New Roman, serif"><font size={3}>{d.label}: </font></font><font><font face="inherit"><font size={3}>{d.value}</font></font>
                                </font>
                                </p>
                            </td>
                            <td width={201} style={{ ...tdClass }}>
                                <p align="JUSTIFY" style={{ marginBottom: '0in' }}><font face="Times New Roman, serif"><font size={3}>Phone:
                                        </font></font><font><font face="inherit"><font size={3}>{d.cellNumber}</font></font>
                                    </font>
                                </p>
                                {d.appointmentDate ? <p align="JUSTIFY"><font face="Times New Roman, serif"><font size={3}>Appt.
                                        Date: </font></font><font><font face="inherit"><font size={3}>{d.appointmentDate ? moment(d.appointmentDate).format('L') : null}</font></font>
                                    </font>
                                </p> : null}
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={428} />
                    <col width={201} />
                    <col width={201} />
                </colgroup>
                <tbody>
                    {
                        otherSupportPersonData.map((support, index) => {
                            return (
                                <tr>
                                    <td width={220} style={{ ...tdClass }}>
                                        <font face="Times New Roman, serif"><font size={3}>Other Support Person’s Name {index + 1}: </font></font><font><font face="inherit"><font size={3}>{support.fullName || ''}</font></font>
                                        </font>
                                    </td>
                                    <td colSpan={2} width={201} style={{ ...tdClass }}>
                                        <font face="Times New Roman, serif"><font size={3}>Organisation: </font></font><font><font face="inherit"><font size={3}>{support.organisation || ''}</font></font>
                                        </font>
                                    </td>
                                    <td colSpan={3} width={201} style={{ ...tdClass }}>
                                        <font face="Times New Roman, serif"><font size={3}>Phone: </font></font><font><font face="inherit"><font size={3}>{support.cellNumber || ''}</font></font>
                                        </font>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}><u><b>Section III: Prenatal Substance Exposure</b></u></font></font>
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={441} />
                    <col width={76} />
                    <col width={88} />
                    <col width={201} />
                </colgroup>
                <tbody>
                    <tr >
                        <td width={441} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Type of Substance Exposure:</b></font></font>
                            </p>
                        </td>
                        <td width={76} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Prescribed</b></font></font>
                            </p>
                        </td>
                        <td width={88} style={{ ...tdClass }}>
                            <p align="CENTER"><font face="Times New Roman, serif"><font size={3}><b>Not Prescribed</b></font></font>
                            </p>
                        </td>
                        <td width={201} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Medication Administered to Infant for Withdrawal? (Y/N)</b></font></font>
                            </p>
                        </td>
                    </tr>
                    {
                        caseSubstance.map(d => (
                            <tr >
                                <td width={441} style={{ ...tdClass }}>
                                    <p><font face="Times New Roman, serif"><font size={3}>{d.label}</font></font>
                                    </p>
                                </td>
                                <td width={76} style={{ ...tdClass }}>
                                    {d.prescribed ? <p align="CENTER">&#9745;</p> : <p align="CENTER">&#9744;</p>}
                                </td>
                                <td width={88} style={{ ...tdClass }}>
                                    {d.prescribed === undefined ? <p align="CENTER">&#9744;</p> : !d.prescribed ? <p align="CENTER">&#9745;</p> : <p align="CENTER">&#9744;</p>}
                                </td>
                                <td width={201} style={{ ...tdClass }}>
                                    {!(d.forWithdrawl === null) ? <p><font><font face="inherit"><font size={3}>{d.forWithdrawl ? 'Yes' : 'No'}</font></font>
                                    </font>
                                    </p> : null}
                                </td>
                            </tr>))
                    }
                    {
                        otherSubstanceExpoData.map(d => {
                            if (!d.fullName) {
                                return;
                            }
                            return (
                                <tr >
                                    <td width={441} style={{ ...tdClass }}>
                                        <p><font face="Times New Roman, serif"><font size={3}>{d.fullName}</font></font>
                                        </p>
                                    </td>
                                    <td width={76} style={{ ...tdClass }}>
                                        {d.prescribed ? <p align="CENTER">&#9745;</p> : <p align="CENTER">&#9744;</p>}
                                    </td>
                                    <td width={88} style={{ ...tdClass }}>
                                        {d.prescribed === undefined ? <p align="CENTER">&#9744;</p> : !d.prescribed ? <p align="CENTER">&#9745;</p> : <p align="CENTER">&#9744;</p>}
                                    </td>
                                    <td width={201} style={{ ...tdClass }}>
                                        {!(d.forWithdrawl === null) ? <p><font><font face="inherit"><font size={3}>{d.forWithdrawl ? 'Yes' : 'No'}</font></font>
                                        </font>
                                        </p> : null}
                                    </td>
                                </tr>)
                        })
                    }
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}><u><b>Section IV: Assessment of Needs and Referrals for Services for the Family</b></u></font></font>
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}>Based
            upon the information gathered by the POSC Coordinator during the
            family assessment phase, the following section identifies the
            possible needs of the infant, mother, father or other caregiver, and
            the referrals made for appropriate services and treatment for the
            family.</font></font>
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={236} />
                    <col width={220} />
                    <col width={124} />
                    <col width={100} />
                    <col width={111} />
                </colgroup>
                <tbody>
                    <tr >
                        <td width={236} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Infant's Need(s):{/* Drop down? Can we categorize it */}</b></font></font>
                            </p>
                        </td>
                        <td width={220} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Service/Provider Referred To:</b></font></font>
                            </p>
                        </td>
                        <td width={124} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Family Accepted Service? (Y/N)</b></font></font>
                            </p>
                        </td>
                        <td width={100} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b><span>Referral Date:</span></b></font></font>
                            </p>
                        </td>
                        <td width={111} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b><span>30/60 Day Update Date:{/* Date or text box */}</span></b></font></font>
                            </p>
                        </td>
                    </tr>
                    {infantNeeds.map(d => {
                        return <tr >
                            <td width={236} style={{ ...tdClass }}>
                                <p><font face="Times New Roman, serif"><font size={3}>{d.label}</font></font>
                                </p>
                            </td>
                            <td width={220} style={{ ...tdClass }}>
                                <p><font><font face="Times New Roman, serif"><font size={3}>{d.referredTo}</font></font>
                                </font>
                                </p>
                            </td>
                            <td width={124} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.familyAcceptedService !== undefined ? d.familyAcceptedService ? 'Yes' : 'No' : null}</font></font>
                                </font>
                                </p>
                            </td>
                            <td width={100} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.referralDate ? moment(d.referralDate).format('L') : null}</font></font>
                                </font>
                                </p>
                            </td>
                            <td width={111} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.dayUpdate ? moment(d.dayUpdate).format('L') : null}</font></font>
                                </font>
                                </p>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={236} />
                    <col width={220} />
                    <col width={136} />
                    <col width={100} />
                    <col width={99} />
                </colgroup>
                <tbody>
                    <tr >
                        <td width={236} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b><span>Mother’s Need{/* Drop down? Can we categorize it */}(s):</span></b></font></font>
                            </p>
                        </td>
                        <td width={220} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Service/Provider Referred To:</b></font></font>
                            </p>
                        </td>
                        <td width={136} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Family Accepted Service? (Y/N)</b></font></font>
                            </p>
                        </td>
                        <td width={100} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Referral Date:</b></font></font>
                            </p>
                        </td>
                        <td width={99} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>30/60 Day Update Date:</b></font></font>
                            </p>
                        </td>
                    </tr>
                    {motherNeeds.map(d => {
                        return <tr >
                            <td width={236} style={{ ...tdClass }}>
                                <p><font face="Times New Roman, serif"><font size={3}>{d.label}</font></font>
                                </p>
                            </td>
                            <td width={220} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.referredTo}</font></font>
                                </font>
                                </p>
                            </td>
                            <td width={124} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.familyAcceptedService !== undefined ? d.familyAcceptedService ? 'Yes' : 'No' : null}</font></font>
                                </font>
                                </p>
                            </td>
                            <td width={100} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.referralDate ? d.referralDate && moment(d.referralDate).format('L') : null}</font></font>
                                </font>
                                </p>
                            </td>
                            <td width={111} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.dayUpdate ? moment(d.dayUpdate).format('L') : null}</font></font>
                                </font>
                                </p>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={236} />
                    <col width={220} />
                    <col width={136} />
                    <col width={100} />
                    <col width={99} />
                </colgroup>
                <tbody>
                    <tr >
                        <td width={236} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Father’s Need(s)</b></font></font>
                            </p>
                        </td>
                        <td width={220} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Service/Provider Referred To:</b></font></font>
                            </p>
                        </td>
                        <td width={136} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Family Accepted Service? (Y/N)</b></font></font>
                            </p>
                        </td>
                        <td width={100} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>Referral  Date:</b></font></font>
                            </p>
                        </td>
                        <td width={99} style={{ ...tdClass }}>
                            <p><font face="Times New Roman, serif"><font size={3}><b>30/60 Day Update Date:</b></font></font>
                            </p>
                        </td>
                    </tr>
                    {fatherNeeds.map(d => {
                        return <tr >
                            <td width={236} style={{ ...tdClass }}>
                                <p><font face="Times New Roman, serif"><font size={3}>{d.label}</font></font>
                                </p>
                            </td>
                            <td width={220} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.referredTo}</font></font>
                                </font>
                                </p>
                            </td>
                            <td width={124} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.familyAcceptedService !== undefined ? d.familyAcceptedService ? 'Yes' : 'No' : null}</font></font>
                                </font>
                                </p>
                            </td>
                            <td width={100} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.referralDate ? d.referralDate && moment(d.referralDate).format('L') : null}</font></font>
                                </font>
                                </p>
                            </td>
                            <td width={111} style={{ ...tdClass }}>
                                <p><font><font face="inherit"><font size={3}>{d.dayUpdate ? moment(d.dayUpdate).format('L') : null}</font></font>
                                </font>
                                </p>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}><u><b>Section V:  Hospital Education Provided to Mother, Father and Other
                Caregiver(s) </b></u></font></font><font face="Times New Roman, serif"><font size={3}>(check all that apply):</font></font>
            </p>
            <div style={{ border: '1px solid #00000a', width: '846px', paddingTop: '0.08in', paddingBottom: '0.08in', paddingLeft: '0.08in', paddingRight: '0.08in' }}>
                <font face="Times New Roman, serif"><font size={3}><b>Microlearning Education</b>
                </font></font>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '863px' }}>
                {microEducationTags.map(d => {
                    return <div style={{ ...tdClass, width: '270px' }}>
                        <font face="Times New Roman, serif"><font size={3}>{d.label}:
                        </font></font><font face="inherit"> {d.value ? <font size={3}>&#9745;</font> : <font size={3}>&#9744;</font>}</font>
                    </div>
                })}
            </div>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <div style={{ border: '1px solid #00000a', width: '846px', paddingTop: '0.08in', paddingBottom: '0.08in', paddingLeft: '0.08in', paddingRight: '0.08in' }}>
                <font face="Times New Roman, serif"><font size={3}><b>Hospital Education</b>
                </font></font>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '863px' }}>
                {educationTags.map(d => {
                    let otherValue = (d.label == "Other" && d.type == "text") && !d.value ? false : true;
                    if (otherValue) {
                        return (
                            <div style={{ ...tdClass, width: '270px' }}>
                                <font face="Times New Roman, serif"><font size={3}>{d.label}:
                            </font></font><font face="MS Gothic, serif"> {d.type == "text" ? d.value : d.value ? <font size={3}>&#9745;</font> : <font size={3}>&#9744;</font>}</font>
                            </div>
                        )
                    }
                })}
            </div>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <div style={{ border: '1px solid #00000a', width: '846px', paddingTop: '0.08in', paddingBottom: '0.08in', paddingLeft: '0.08in', paddingRight: '0.08in' }}>
                <font face="Times New Roman, serif"><font size={3}><b>Other Education</b>
                </font></font>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '863px' }}>
                {otherEducationtags.map(d => {
                    return <div style={{ ...tdClass, width: '270px' }}>
                        <font face="Times New Roman, serif"><font size={3}>{d.label}:
                        </font></font><font face="inherit"> {d.value ? <font size={3}>&#9745;</font> : <font size={3}>&#9744;</font>}</font>
                    </div>
                })}
            </div>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}><u><b>Section VI: Infant Safe Sleeping Arrangements </b></u></font></font><font face="Times New Roman, serif"><font size={3}>(check
                all that apply):</font></font>
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={201} />
                    <col width={202} />
                    <col width={202} />
                    <col width={201} />
                </colgroup>
                <tbody>
                    <tr >
                        {infantSafeTags.splice(0, 5).map(d => {
                            return <td width={201} style={{ ...tdClass }}>
                                <font face="Times New Roman, serif"><font size={3}>{d.label}: </font></font><font face={`${d.type == "text" ? 'inherit' : "MS Gothic, serif"}`}> {d.type == "text" ? d.value : d.value ? <font size={3}>&#9745;</font> : <font size={3}>&#9744;</font>}</font>
                            </td>
                        })}
                    </tr>
                    {infantSafeTags.map(d => {
                        return <tr>
                            <td colSpan={5} width={847} style={{ ...tdClass }}>
                                <p><font face="Times New Roman, serif"><font size={3}>{d.label} </font></font><font><font face="inherit"><font size={3}>
                                    {(d.type == "dateTime") ? `: ${moment(d.value).format('L')}` : util.isBase64(d.value) ? <img width={847} src={d.value} alt="img" /> : d.value}</font></font>
                                </font>
                                </p>
                            </td>
                        </tr>
                    })}

                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}><u> </u>
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}><u><b>Section VII: Child Safety and Discharge</b></u></font></font>
            </p>
            <div>
                {childSafetyTags.splice(0, 1).map(d => {
                    childSafety = d.value;
                    return <div style={{ ...tdClass, width: '845px' }}>
                        <font face="Times New Roman, serif"><font size={3}>{d.label}: </font></font><font face="inherit">{d.value ? <font size={3}>&#9745;</font> : <font size={3}>&#9744;</font>}</font>

                    </div>
                })}
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '863px' }}>
                    {childSafetyTags.map((d, i) => {
                        return <div style={{ ...tdClass, width: '270px' }}>
                            <font face="Times New Roman, serif"><font size={3}>{d.label}: </font></font><font><font face="inherit"><font size={3}>{i === 0 ? childSafety ? d.value : '' : d.value ? d.type === "dateTime" ? moment(d.value).format('L') : d.value : null}</font></font>
                            </font>
                        </div>
                    })}
                </div>
            </div>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}><u><b>Section
                VIII: </b></u></font></font><font face="Times New Roman, serif"><font size={3}><u><b><span>Monitoring{/* How the 120 day is the last checking date? */}{/* What is all the types of monitoring being done? */}</span></b></u></font></font>
            </p>
            <div width={863} cellPadding={7} cellSpacing={0}>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '863px' }}>
                    {monitoring.map(d => {
                        return (<>
                            <div style={{ ...tdClass, width: '270px' }}>
                                <font face="Times New Roman, serif"><font size={3}>{d.label}:
                                </font></font>
                            </div>
                            <div style={{ ...tdClass, width: '270px' }}>
                                <font face="Times New Roman, serif"><font size={3}>Notes: {d.notes}</font></font>
                            </div>
                            <div style={{ ...tdClass, width: '270px' }}>
                                <font face="Times New Roman, serif"><font size={3}>Updated Date: {d.updatedDate ? moment(d.updatedDate).format('L') : null}</font></font>
                            </div>
                        </>)
                    })}
                </div>
            </div>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}><u><b>Section IX: Consent for Information Sharing and Signatures</b></u></font></font>
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}>By
            signing below, Mother, Father or other caregiver(s) acknowledge that
            this Plan of Safe Care has been prepared, reviewed and thoroughly
            discussed with the POSC Coordinator.  It is agreed that medical and
            substance use treatment information may be shared/disclosed with the
            Plan Participants under this written consent as provided by HIPPA (45
            CFR 160, 164) and </font></font><font color="#767171"><font face="Times New Roman, serif"><font size={3}>42</font></font>
                </font><font face="Times New Roman, serif"><font size={3}>
                    CFR Part 2.  Mother, Father or other caregiver(s) hereby consent to
                    the sharing of the POSC with the Plan Participants. </font></font>
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}>Plan
            Participants will regularly communicate and share information to
            ensure that timely referrals for services are made by the POSC
            Coordinator and that the appropriate services are delivered to the
            family.  The POSC Coordinator and Plan Participants will ensure
            confidentiality of the information received through the POSC.</font></font>
            </p>
            <p style={{ marginBottom: '0.11in' }}><font face="Times New Roman, serif"><font size={3}>The
            POSC Coordinator hereby confirms that the Division of Family Services
            has been notified of the infant’s birth, this POSC has been
            prepared for the infant and family and a copy of the POSC will be
            provided to the Plan Participants within 48 hours after this date. </font></font>
            </p>
            <table width={863} cellPadding={7} cellSpacing={0}>
                <colgroup>
                    <col width={675} />
                    <col width={159} />
                </colgroup>
                <tbody>
                    {consentData.map(d => {
                        return <tr >
                            <td width={275} style={{ ...tdClass }}>
                                <p style={{ wordWrap: 'break-word', width: "275px" }}><font face="Times New Roman, serif"><font size={3}>{d.label}: {d.firstName} </font></font>
                                </p>
                            </td>
                            <td width={275} style={{ ...tdClass }}>
                                <p style={{ wordWrap: 'break-word', width: "275px" }}><font face="Times New Roman, serif"><font size={3}>{d.label && d.label.replace('First Name', 'Last Name')}: {d.lastName} </font></font>
                                </p>
                            </td>
                            <td width={159} style={{ ...tdClass }}>
                                <p><font face="Times New Roman, serif"><font size={3}>{d.signature ? <img width={159} src={d.signature} alt="img" /> : null}</font></font>
                                </p>
                            </td>
                            <td width={159} style={{ ...tdClass }}>
                                <p style={{ marginBottom: '0in' }}><font face="Times New Roman, serif">Date: <font size={3} face="inherit">{d.updatedDate ? moment(d.updatedDate).format('L') : null}</font></font>
                                </p>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <p style={{ marginBottom: '0.11in' }}>
                <br />
            </p>
        </div>
    </div >)
}
export default PrintDocument;