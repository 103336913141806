import React, { useState, useEffect } from 'react';
import Pie from '../../components/Pie';
import Bar from '../../components/Bar';
import Grid from '@material-ui/core/Grid';
import List from '../../components/List';
import { apis } from '../../httpUtil';
import actions from '../../redux/actions/actionType';
import { enums } from '../../Utility/const'
import util from '../../util';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    root: {
        '& .MuiFormGroup-row': {
        },
        '& .MuiCheckbox-root .MuiSvgIcon-root': {
            fontSize: '14px'
        },
        '& .MuiInputLabel-formControl': {
            fontSize: '14px'
        },
        '& .MuiTableSortLabel-root:hover': {
            color: 'rgb(255, 255, 255)'
        },
        '& .MuiTableSortLabel-icon': {
            marginRight: '0  !important',
            marginLeft: '0  !important'
        },
        '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
            marginRight: '0  !important',
            marginLeft: '0  !important'
        },
        '& .MuiTableCell-head': {
            color: '#FFFFFF',
            padding: '14px 0px !important'
        },
        '& .MuiAppBar-colorDefault': {
            backgroundColor: '#2196f3',
            color: '#FFFFFF'
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            background: '#5062e5'
        }
    }
}
);
const columns = [
    { title: "Date", field: "createdAt", type: 'date', render: rowData => { return util.renderer.dateTime('createdAt', rowData) } },
    { title: "Activity", field: "activityType", type: 'string' },
    { title: "Possible Points", field: "pointsReceived", type: 'string' }
]

const barChartColors = ['#4472C4', '#ED7D31', '#A5A5A5', '#FFC000'];
const pieChartColor = ['#4472C4', '#ED7D31', '#A5A5A5'];

const getDoughnutData = (chartData) => {
    let doughnutChart = {}, data = [], datasets = [], labels = [], totolPoint = 0;
    if (chartData.length > 0) {
        chartData.map(e => {
            totolPoint += e.pointsReceived;
        })
        chartData.map(e => {
            labels.push(e.displayValue);
            data.push(e.pointsReceived);
        })
        datasets = [{
            data: data,
            backgroundColor: pieChartColor,
            hoverBackgroundColor: pieChartColor,
            borderWidth: '0',
            borderColor: 'transparent'
        }]
    }
    doughnutChart.labels = labels;
    doughnutChart.datasets = datasets;
    doughnutChart.totolPoint = totolPoint;
    return doughnutChart;
}

const getBarChartData = (chartData) => {
    let barChart = {}, datasets = [], labels = [], allCount = [];;
    if (chartData.length > 0) {
        chartData.map((e, index) => {
            labels.push(e.displayValue);
            console.log(chartData);
            chartData.forEach(el => {
                allCount.push(el.pointsReceived);
            });

        })
        datasets.push({
            data: allCount,
            backgroundColor: barChartColors,
            borderColor: barChartColors,
            borderWidth: 1,
            barThickness: 14
        });
    }
    barChart.labels = labels;
    barChart.datasets = datasets;
    return barChart;
}

const getLongestLengend = (labels) => {
    var longest = labels.reduce(
        function (a, b) {
            return a.length > b.length ? a : b;
        }
    );
    return longest.length;
}

const Awards = (props) => {
    const classes = useStyles();
    const activeCaseId = useSelector(state => state.appReducer.activeCaseId);
    const awardData = useSelector(state => state.appReducer.awardList);
    const [searchValue, setSearchValue] = useState({ searchPramas: '' });
    let pieChartData = awardData && awardData.chartData && awardData.chartData.pieChartData || [];
    let barChartData = awardData && awardData.chartData && awardData.chartData.barChartData || [];
    let doughnutChart = getDoughnutData(pieChartData)
    let longestLengend = doughnutChart.labels.length > 0 && getLongestLengend(doughnutChart.labels) || '';
    let doughnutChartSize = longestLengend > 8 ? 350 : 280;
    let barData = getBarChartData(barChartData)
    const handleSearch = (e, value) => {
        e.preventDefault();
        setSearchValue({ ...searchValue, ...{ searchPramas: value } });
    }
    useEffect(() => {
        if (awardData && awardData.isNoPointEarned) {
            swal({
                title: "There is no point earned yet ",
                icon: 'info'
            });
        }
    }, [awardData])

    return (
        <div className={classes.root}>
            <Grid container justify="center" className="reward-tab">
                {pieChartData.length > 0 &&
                    <Grid item xs={5} md={5} lg={5} align="center">
                        <Pie width={doughnutChartSize} height={320} fromAward={true} chartHeader="Total points ever earned" data={doughnutChart} pieFooterText={`${doughnutChart.totolPoint} points`} />
                    </Grid>
                }
                {barChartData.length > 0 &&
                    <Grid item xs={7} md={7} lg={7} align="center" className="pdl-1">
                        <Bar barData={barData} chartHeader="Points Breakdown" fromAward={true} legend={false} />
                    </Grid>
                }
            </Grid>
            <Grid container spacing={0}>
                <List
                    defaultSort="createdAt"
                    defaultPageSize={10}
                    sortDir="desc"
                    activeCaseId={activeCaseId}
                    columns={columns}
                    fromDashboard={false}
                    options={{ toolbar: true }}
                    listOption={{ api: apis.award, listName: 'awardList', reduxActions: actions.SET_AWARD_LIST }}
                    tableHeader='Rewards'
                    handleSearch={handleSearch}
                    searchValue={searchValue}
                    fromAward={true} />
            </Grid>
        </div>
    )
}
export default Awards;