import * as React from 'react';
import { TextField } from '@material-ui/core';
import util from '../util';
import moment from 'moment';
import { enums } from '../Utility/const';

const BaseTextField = React.memo(function BaseTextField(props) {
    let error = [];
    let wrapperClass = '';
    let required = false;
    let { name, label, readOnly, data, onChange, inputProps, formSubmit, records, multiline, disabled } = props;
    let typeId, key, group, newId, autoAddRow;
    if (data) {
        name = data.name;
        typeId = data.typeId;
        key = data.key;
        group = data.group;
        newId = data.newId;
        autoAddRow = data.autoAddRow;
    }
    let textData = data ? { ...data } : { inputProps: inputProps };
    delete textData.helperText;
    let value = '';

    if (records) {
        if (key && typeId) {
            const rec = autoAddRow ? records.find(rec => (Number(rec[key]) === typeId) && (rec['newId'] == newId || rec['id'] === newId)) : records.find(rec => Number(rec[key]) === typeId);
            if (rec) {
                value = rec[name];
            }
        }
        else {
            value = records[name];
        }
    }
    if (!value && group === "consent") { // populate data from contacts for consent 
        value = util.getDefaultValueForConsent(typeId, name);
    }
    //For Disable consent sign date
    if (value && data.disabled && group == "consent" && data.consentSignDate) {
        value = moment(value).format(util.dateFormat);
    }
    //For Disable field Infant Discharge Date
    if (!value && typeId == enums.tagType.infantDischargeDate) {
        value = util.getDefaultValueForConsent(typeId, name);
        value = value ? moment(value).format(util.dateFormat) : '';
    }
    if ((textData.warning || textData.conditionalValid) && !value) {
        textData.helperText = "Warning"
        wrapperClass = 'warning'
    }

    if (formSubmit || (value && value !== undefined)) {
        error = util.validation(data, value, formSubmit);
        if (error && error.length > 0) {
            textData.helperText = error[0].helperText;
            textData.error = error[0].error;
        }
    }

    if (readOnly) {
        readOnly = { readOnly: true, value: value || "defaults" }
    }

    if (textData.format === "cellNumber") {
        value && value.replace(/-/g, '');
        value = util.normalizeInput(value)
    } else if (textData.format === "number") {
        value = util.numberField(value)
    }

    if (textData && textData.validations && textData.validations.includes("required")) {
        required = true;
    }

    return (<div className={wrapperClass}><TextField autoComplete={`off-${group}${name}`} typeId={props.typeId} required={required}
        label={label} value={value || ''} name={name} {...textData} onKeyDown={(e) => onChange(e, data)} onChange={(e) => onChange(e, data)}
        multiline={multiline} InputProps={readOnly} InputLabelProps={(textData.consentSignDate && value) ? { shrink: true } : ''} /></div>)
});
export default React.memo(BaseTextField);