import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Popover, Typography, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import util from '../util';
import { request, apis } from '../httpUtil';
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'relative',
        margin: '1em 0em 0 0.2em',
        padding: '2.8em 0em 2.6em 0em',
        '& .MuiFormLabel-root': {
            fontSize: '14px'
        }
    },
    removeBtn: {
        position: 'absolute',
        right: '-10px',
        top: '-7px;',
        color: 'red'
    },
    addButn: {
        background: '#E3F2FD',
        boxShadow: '0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 24%)',
        color: '#2196F3',
        width: '155px',
        marginTop: '1em'
    },
    itemPadding: {
        paddingRight: '0.5em',
        lineHeight: '4.5'
    },
    itemCont: {
        padding: '0em 1em'
    },
    datePicker: {
        '& .MuiSvgIcon-root': {
            color: 'rgba(33, 150, 243, 0.87)'
        }
    },
    popover: {
        '& .MuiPopover-paper': {
            maxHeight: '28em',
            top: '40% !important',
            left: '50% !important',
            transform: 'translate(-50%, -50%) !important'
        }
    },
    typography: {
        padding: '1.5em',
        width: '32em'
    },
    notificationText: {
        fontWeight: '500',
        fontSize: '18px',
        marginLeft: '.5em'
    },
    saveButn: {
        background: '#E3F2FD',
        boxShadow: '0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 24%)',
        color: '#2196F3',
        width: '6em',
        marginTop: '2em'
    },
}));

const CaseNote = React.memo(function FormSibling(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    let caseNote = useSelector(state => state.appReducer.caseNote);
    let filterNote = caseNote.filter(e => e.noteTypeId == props.group) || [];
    let noteId = filterNote.length > 0 ? filterNote[0].id : 0;
    const [notes, setNotes] = React.useState(filterNote.length > 0 ? filterNote[0].notes : '');

    const addNotes = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const saveNotes = async () => {
        let params = { action: 'save', notes: notes, noteTypeId: props.group, caseId: props.activeCaseId };
        if (notes && notes.length < 2 || notes.length > 2000) {
            return;
        }
        if (noteId) {
            params.id = noteId;
        }
        const response = await request(apis.casenote, params);
        if (response.success) {
            handleClose();
            const caseNoteData = await request(apis.casenote, { action: 'load', caseId: props.activeCaseId });
            if (caseNoteData && caseNoteData.data) {
                dispatch({ type: 'SET_CASE_NOTE', caseNote: caseNoteData.data });
            }
            swal({
                text: "Note saved successfully",
                icon: 'success'
            });
        } else {
            swal({
                text: response.error,
                icon: 'error'
            });
        }
    }

    const setNoteValue = (e) => {
        let { value } = e.target;
        value = util.capitalize(value);
        setNotes(value);
    }

    return <>
        {noteId ?
            <Paper className={classes.paper}>
                <Grid container spacing={0} className={classes.itemCont}>
                    {filterNote.length > 0 ? filterNote[0].notes : ''}
                </Grid>
            </Paper> : null
        }
        <Button variant="contained" className={classes.addButn} onClick={addNotes}><span style={{ marginRight: '1.2em' }} >{util.addIcon}</span>{noteId ? 'Edit' : 'Add'} Notes</Button>
        <Popover
            id={id}
            open={open}
            anchorEl={props.anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            className={classes.popover}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <Typography className={classes.typography}>
                <TextField
                    id={props.group}
                    label="Notes"
                    multiline
                    fullWidth
                    onChange={setNoteValue}
                    defaultValue={filterNote.length > 0 ? filterNote[0].notes : ''}
                    value={notes}
                    error={notes && (notes.length < 2 || notes.length > 2000)}
                    helperText={notes && (notes.length < 2 || notes.length > 2000) ? 'Invalid notes min: 2 and max: 2000' : ''}
                    inputProps={{ min: 2, max: 2000 }}
                />
                <Button variant="contained" className={classes.saveButn} onClick={saveNotes}> Save</Button>
            </Typography>
        </Popover>
    </>
})

export default React.memo(CaseNote);