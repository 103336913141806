import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import util from '../util';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: ' 0px !important'
        }
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    }
}));

const BaseWeight = React.memo(function BaseWeight(props) {
    const classes = useStyles();
    let error = [];
    let wrapperClass = '';
    let { name, label, readOnly, data, onChange, inputProps, formSubmit, records, handleChange } = props;
    let typeId, key;
    if (data) {
        name = data.name;
        typeId = data.typeId;
        key = data.key;
    }
    let textData = data ? { ...data } : { inputProps: inputProps };
    delete textData.helperText;
    let value = '', lb = '', oz = '';

    if (records) {
        if (key && typeId) {
            const rec = records.find(rec => Number(rec[key]) === typeId);
            if (rec) {
                value = rec[name];
            }
        } else {
            value = records[name] ? records[name] : '{"lb": "", "oz": "" }';
            let objParse = records[name] ? JSON.parse(records[name]) : { lb: '', oz: '' }
            lb = objParse.lb;
            oz = objParse.oz;
        }
    }

    if ((textData.warning || textData.conditionalValid) && !lb) {
        textData.helperText = "Warning"
        wrapperClass = 'warning'
    }

    if (formSubmit || (value && value !== undefined)) {
        error = util.validation(data, value, formSubmit);
        if (error && error.length > 0) {
            textData.helperText = error[0].helperText;
            textData.error = error[0].error;
        }
    }

    if (readOnly) {
        readOnly = { readOnly: true, value: lb || "defaults" }
    }

    if (textData.format === "number") {
        lb = util.numberField(lb);
        oz = util.numberField(oz);
    }


    return (
        <FormControl>
            <div className={classes.root} >
                <div style={{ position: 'relative' }}>
                    <TextField autoComplete={`off-${name}`} label={label} id="weight-input" value={lb} name={name} {...textData} onChange={(e) => onChange(e, data)} />
                    <span style={{ position: 'absolute', right: '8px', bottom: '7px' }}>LB</span>
                </div>
                <div style={{ position: 'relative' }}>
                    <TextField  autoComplete={`off-weightOZ`}  value={oz} id="ozweight" name={'weightOZ'} onChange={(e) => onChange(e, data)} />
                    <span style={{ position: 'absolute', right: 0, bottom: '7px' }}>OZ</span>
                </div>
            </div>
        </FormControl>
    )
});
export default BaseWeight;