import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { request, apis } from '../httpUtil';
import axios from 'axios';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const FormUploadFile = React.memo(function BaseCheckBox(props) {
    const { activeCaseId } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [fileData, setFileData] = React.useState('');
    const [descriptionData, setDescriptionData] = React.useState('');
    const [programName, setProgramName] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [completedOn, setCompletedOn] = React.useState(null);
    const [charactersLimit, setCharactersLimit] = React.useState(500);


    const dispatch = useDispatch();

    const handleModal = () => {
        setOpen(!open)
        setFileData('');
        setDescriptionData('');
        setProgramName('');
        setLocation('');
        setCharactersLimit(500)
        setCompletedOn(null)

    };
    const handleFileChange = (e) => setFileData(e.target.files[0]);
    const handleTextAreaChnage = (e) => {
        let val = e.target.value;
        if (val && val.length <= 500) {
            setCharactersLimit((500 - val.length));
            setDescriptionData(val)
        }
    };
    const handleProgramChnage = (e) => setProgramName(e.target.value);
    const handleLocationChnage = (e) => setLocation(e.target.value);

    const saveFileData = async () => {
        dispatch({ type: 'SET_SHOW_LOADER', showLoader: true });
        if (fileData) {
            let formData = new FormData();
            formData.append('caseId', activeCaseId);
            formData.append('programName', programName);
            formData.append('description', descriptionData);
            formData.append('location', location);
            completedOn && formData.append('completedOn', Date.parse(completedOn));

            formData.append('action', 'save');
            formData.append('file', fileData);
            if (fileData.size / (1024 * 1024) > 10) {
                swal("Please upload file only 10MB.", { icon: "error", });
                dispatch({ type: 'SET_SHOW_LOADER', showLoader: false });
                return
            }
            axios.post(apis.supportprogram, formData)
                .then(function () {
                    setTimeout(async () => {
                        let dataParams = { pageSize: 5, pageNo: 0, orderBy: 'id', dir: "desc" }
                        const params = Object.assign({ action: 'list', caseId: activeCaseId }, dataParams);
                        const response = await request(apis.supportprogram, params);
                        if (response && response.success) {

                            dispatch({ type: 'SET_SUPPORTPRO_LIST', fetchRecord: response });
                        }
                    }, 500);
                    dispatch({ type: 'SET_SHOW_LOADER', showLoader: false });
                    setOpen(!open);
                })
                .catch(function () {
                    dispatch({ type: 'SET_SHOW_LOADER', showLoader: false });
                    setOpen(!open);
                });
        } else {
            swal("Please upload file.", { icon: "error", });
            dispatch({ type: 'SET_SHOW_LOADER', showLoader: false });
        }
    }

    return (
        <div className="file-upload-wrapper">
            <Button variant="contained" color="primary" onClick={handleModal}>File Upload</Button>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className='file-upload-form'>
                        <h6>Upload file - Size ~10MB</h6>
                        <Grid>
                            <Grid container item xs={12}>
                                <TextField label="Program Name" autoComplete={`off-program-name`} onChange={handleProgramChnage} value={programName} />
                            </Grid>
                            <Grid container item xs={12}>
                                <TextField label="Location" autoComplete={`off-program-location`} onChange={handleLocationChnage} value={location} />
                            </Grid>
                            <Grid container item xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        value={completedOn}
                                        label="Completed On"
                                        onChange={setCompletedOn}
                                        format="MM/dd/yyyy"
                                        autoOk={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid container item xs={12}>
                                <label>Description - <span style={{ color: charactersLimit ? '#0026ff' : 'red', fontSize: '14px' }}>Remaining characters : {charactersLimit}</span></label>
                                <TextareaAutosize
                                    rowsMax={16}
                                    rowsMin={16}
                                    maxLength="500"
                                    onChange={handleTextAreaChnage}
                                    defaultValue={descriptionData}
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <TextField label="File" autoComplete={`off-program-file`} InputProps={{ type: 'file' }} onChange={handleFileChange} />
                            </Grid>
                            <Grid container item xs={12}>
                                <Button variant="contained" color="primary" onClick={saveFileData} >Upload</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
})

export default FormUploadFile;