import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import util from '../util';
import { enums } from '../Utility/const'
import Paper from '@material-ui/core/Paper';

const options = {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
        position: 'right',
        labels: {
            boxWidth: 10
        }
    }
}

const DoughnutChart = React.memo(function DoughnutChart(props) {
    let { width, height, chartData } = props;
    chartData = chartData && chartData.pieChartData ? chartData.pieChartData : {}
    const chart = Object.keys(chartData).length > 0 ? util.setChartData(enums.lookupType.AppointmentStatus, chartData) : [];
    defaults.global.defaultFontSize = 13;
    const data = {
        datasets: [{
            data: chart.chartData,
            backgroundColor: ['#22B14C', '#4472C4', '#A5A5A5', '#ED1C24', '#7446B9'],
            hoverBackgroundColor: ['#22B14C', '#4472C4', '#A5A5A5', '#ED1C24', '#7446B9']
        }],
        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: chart.labels
    };
    return (
        chart.chartData && chart.chartData.length > 0 &&
        <div className="pie-header paper-header">
            <Paper elevation={0}>
                <div className="br-b flex">
                    {props.chartHeader ?
                        <div className="paper-header-text">{props.chartHeader}</div>
                        : null}
                    {props.pieChartTitleRight && <div className="paper-herder-rt-txt">{props.pieChartTitleRight}</div>}
                </div>
                < Doughnut
                    data={data || {}}
                    width={width || 230}
                    height={height || 190}
                    options={options}
                />
            </Paper>
        </div>
    )
})

export default DoughnutChart;