import React from 'react';
import { useDispatch } from 'react-redux';
import {
    makeStyles, withStyles, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper
} from '@material-ui/core';
import BaseRadioGroup from '../components/BaseRadioGroup';
import BaseCheckBox from '../components/BaseCheckBox';
import BaseSelect from '../components/BaseSelect';
import { useSelector } from 'react-redux';
import util from '../util'
import BaseTextField from '../components/BaseTextField';
import swal from 'sweetalert';

const useStyles = makeStyles({
    root: {
        width: '100%',
        '& .MuiFormGroup-row': {
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .MuiTableCell-root': {
            padding: '0 16px'
        }
    },
    tableView: {
        '& .MuiTableRow-hover > td:first-child, .MuiTableRow-head th:first-child': {
            display: 'table-cell !important'
        }
    },
});

const columns = [
    { id: 'typeofsubstanceexposure', label: 'Type of Substance Exposure', minWidth: 170 },
    { id: 'prescribed', label: 'Prescribed', minWidth: 100, align: 'center', },
    {
        id: 'notprescribed',
        label: 'Not Prescribed',
        minWidth: 170,
        align: 'center'
    },
    {
        id: 'medicationforinfant',
        label: 'Medication for Infant for Withdrawal?',
        minWidth: 170,
        align: 'center'
    }
];

const StyledTableCell = withStyles(theme => ({
    head: {
        background: 'rgba(196, 196, 196, 0.31) !important', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 'bold', fontSize: '16px !important'
    }
}))(TableCell);

let dispatch = null;

const Substance = (props) => {
    dispatch = useDispatch();
    const classes = useStyles();
    const { jsonRecord, group } = props;
    let records = useSelector(state => state.appReducer[group], []) || [];
    const onChange = (e, fieldInfo) => {
        let { value, checked } = e.target || {};
        let { key, typeId, group, name, type, newId, isMultiDropDown, index, autoAddRow } = fieldInfo;
        let newField = {};
        // #378
        let alternateCheckboxName = name === 'notPrescribed' ? 'prescribed': 'notPrescribed';
        let alternateCheckboxIsChecked = !checked;

        if (fieldInfo && fieldInfo.type === "multiSelect") {
            value = value.toString();
            let isAlreadySelected = records.findIndex(e => e.substances == value);
            if (isAlreadySelected > -1) {
                swal({
                    title: 'Already Exists',
                    icon: "error",
                    dangerMode: true
                });
                return false;
            }
            let filterValue = jsonRecord.filter(e => e.typeId == fieldInfo.typeId) || [];
            let sortedValue = filterValue.sort((a, b) => (a.newId > b.newId) ? 1 : -1);
            let isAlreadyAdded = jsonRecord.filter(e => e.typeId == fieldInfo.typeId && e.lookupId == 0 && e.newId == fieldInfo.newId) || [];

            if (isAlreadyAdded.length < 2 && fieldInfo.newId == sortedValue[0].newId) {
                let filterField = jsonRecord.filter(e => e.typeId == fieldInfo.typeId && !e.newId) || [];
                if (filterField.length > 0) {
                    newField = { ...filterField[0] };
                    let filterValue = jsonRecord.filter(e => e.typeId == fieldInfo.typeId) || [];
                    let sortedValue = filterValue.sort((a, b) => (a.newId > b.newId) ? 1 : -1);
                    if (sortedValue[0].newId <= 0) {
                        newField.newId = sortedValue[0].newId + -1;
                        let index = jsonRecord.findIndex(e => e.typeId == fieldInfo.typeId && e.lookupId == 0 && e.newId == sortedValue[0].newId);
                        if (index > -1) {
                            jsonRecord[index].lookupId = value;
                        }
                        newField.isMultiDropDown = 1;
                    } else {
                        newField.newId = 0;
                        newField.isMultiDropDown = autoAddRow ? 1 : 0;;
                    }
                    newField.lookupId = 0;
                    newField.autoAddRow = true;
                    //fields.splice(index + 1, 0, newField);
                    jsonRecord.push(newField);
                }
                let isMultiIndex = jsonRecord.findIndex(e => e.isMultiDropDown == -1 && e.id == fieldInfo.typeId);
                if (isMultiIndex > 0) {
                    jsonRecord[isMultiIndex].isMultiDropDown = 1;
                }
            }
        }

        if (fieldInfo && fieldInfo.type === "text") {
            value = util.capitalize(value.toString());
        }
       
        const values = {
            [key]: typeId, [name]: type === "checkbox" ? checked : value,
            isMultiDropDown: !autoAddRow ? 0 : 1, newId: fieldInfo.newId
        };
        if(name.indexOf('notPrescribed') > -1 || name.indexOf('prescribed') > -1){
            values[alternateCheckboxName] = alternateCheckboxIsChecked;
        }
        dispatch({
            type: 'SET_FORM_DATA', formData: {
                group: group, key: key, value: values
            }
        });
    }

    return (<Paper className={classes.root}>
        <TableContainer className={[classes.container, classes.tableView]}>
            <Table stickyHeader aria-label="sticky table" className="substance-cont">
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <StyledTableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                                {column.label}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[].concat(jsonRecord).map((row, index) => {
                        if (row.customStringValue) {
                            let fieldInfo = row.customStringValue;
                            fieldInfo = JSON.parse(fieldInfo);
                            fieldInfo.label = row.displayValue;
                            let data = {
                                key: row.key, typeId: row.typeId, group: group, newId: row.newId,
                                isMultiDropDown: row.isMultiDropDown, autoAddRow: row.autoAddRow, index: index
                            }
                            let disable = util.hideWithdrawal.includes(fieldInfo.label) ? true : false;
                            let customStringValue = row.customStringValue ? JSON.parse(row.customStringValue) : {};
                            let disableMulti = false;
                            if (records) {
                                let multSelectIndex = records.findIndex(d => d.substanceTypeId === row.typeId && (customStringValue.type === "multiSelect" || customStringValue.type === "text"));
                                if (multSelectIndex > -1) {
                                    disableMulti = (records[multSelectIndex].substances || records[multSelectIndex].other) ? false : true;
                                }
                            }
                            return (
                                !customStringValue.hidden && <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell>
                                        {
                                            fieldInfo.type === 'multiSelect' ? <BaseSelect records={records} id={data.id} data={{ ...data, ...fieldInfo }} handleChange={onChange} label={"Opioids-List Type"} /> :
                                                fieldInfo.type === 'text' ? <BaseTextField records={records} data={{ ...data, ...fieldInfo }} onChange={onChange} /> : row.displayValue
                                        }

                                    </TableCell>

                                    <TableCell align="center">
                                        {fieldInfo.prescribed ? disableMulti ? <BaseCheckBox records={records} data={{ ...{ name: 'prescribed', type: "checkbox", disabled: disableMulti, checked: false } }} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} disabled={fieldInfo.prescribedisabled} /> : <BaseCheckBox records={records} data={{ ...data, ...{ name: 'prescribed', type: "checkbox", disabled: disableMulti } }} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} disabled={fieldInfo.prescribedisabled} handleChange={onChange} /> : null}
                                    </TableCell>
                                    <TableCell align="center">
                                        {disableMulti ? <BaseCheckBox records={records} data={{ ...{ name: 'prescribed', type: "checkbox", disabled: disableMulti, checked: false } }} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} disabled={fieldInfo.prescribedisabled} /> : <BaseCheckBox records={records} data={{ ...data, ...{ name: 'notPrescribed', type: "checkbox", disabled: disableMulti } }} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} disabled={fieldInfo.prescribedisabled} handleChange={onChange} />}
                                    </TableCell>

                                    <TableCell align="center">
                                        {fieldInfo.forInfantWithdrawal ?
                                            <BaseRadioGroup records={records} onChange={onChange} disabled={disableMulti} data={{ ...data, ...{ name: 'forWithdrawl' } }} />
                                            : null}
                                    </TableCell>
                                </TableRow>
                            );
                        }
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper >)
}

export default React.memo(Substance);