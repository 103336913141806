import * as React from 'react';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import util from '../util';

const useStyles = makeStyles(theme => ({
    selectBase: {
        marginTop: '1em'
    }
}));
// Date Picker component
const BaseTimePicker = React.memo(function BaseDatePicker(props) {
    const { data, handleChange, formSubmit, records } = props, classes = useStyles();
    let required = false;
    let helperText = '', dateError = false, wrapperClass = '';
    let error = [], dateData = { ...data }, typeId, key, name;
    if (data) {
        name = data.name;
        typeId = data.typeId;
        key = data.key;
    }
    let value = null;
    if (records) {
        if (key && typeId) {
            const rec = records.find(rec => rec[key] === typeId);
            rec && (value = rec[name]);
        } else {
            value = records[name];
        }
    }
    if ((dateData.warning || dateData.conditionalValid) && !value) {
        helperText = "Warning"
        wrapperClass = 'warning'
    }
    if (formSubmit || (value && value !== undefined)) {
        error = util.validation(data, value, formSubmit);
        if (error && error.length) {
            helperText = error[0].helperText
            dateError = error[0].error
        }
    }

    if (data && data.validations && data.validations.includes("required")) {
        required = true;
    }
    !dateData.format && (dateData.format = util.dateTimePickerFormat);
    //(value && value.toString() === "Invalid Date") && (value = null)
    !value && (value = null);
    return (<div className={wrapperClass}><MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.selectBase}>
        <KeyboardTimePicker
            label={dateData.label}
            id={dateData.id}
            name={dateData.name}
            value={value}
            error={dateError}
            onChange={(date) => handleChange(date, data)}
            required={required}
            KeyboardButtonProps={{
                'aria-label': 'change time',
            }}
        />
    </MuiPickersUtilsProvider></div>)
})

export default React.memo(BaseTimePicker);