import * as React from 'react';
import {
    withStyles, FormControlLabel, RadioGroup, Radio
} from '@material-ui/core';

const GreenRadio = withStyles({
    root: {
        color: 'rgba(0, 0, 0, 0.54)',
        '&$checked': {
            color: '#43a047 !important',
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

const RedRadio = withStyles({
    root: {
        color: 'rgba(0, 0, 0, 0.54)',
        '&$checked': {
            color: 'rgb(220, 0, 78)  !important',
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

const BaseRadioGroup = (props) => {
    const { onChange, data, records, disabled = false } = props;
    let { name, typeId, key, newId, autoAddRow } = data;
    let value = null;
    if (records) {
        const rec = autoAddRow ? records.find(rec => (Number(rec[key]) === typeId) && (rec['newId'] == newId || rec['id'] === newId)) : records.find(rec => Number(rec[key]) === typeId);
        if (rec && rec.hasOwnProperty(name)) {
            value = rec[name];
            value = (value && typeof (value) == 'string' && value.toLocaleLowerCase() === "yes" || value === true) ? "yes" : "no";
        }
    }
    return (
        <RadioGroup aria-label="medicationforinfant" name={name} value={disabled ? "" : value} onChange={(e) => onChange(e, data)} row >
            <FormControlLabel value="yes" control={<GreenRadio />} label="Yes" disabled={disabled} />
            <FormControlLabel value="no" control={<RedRadio />} label="No" disabled={disabled} />
        </RadioGroup>
    )
}
export default React.memo(BaseRadioGroup);