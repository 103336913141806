import React, { useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { useSelector } from 'react-redux';
import { enums } from '../Utility/const';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import util from '../util';

const SelectComp = React.memo(function SelectComp(props) {
    let error = false;
    let required = false;
    const { name, handleChange, data, formSubmit, records } = props;
    const combos = useSelector(state => state.appReducer["comboData"]);
    const { key, typeId, comboType, type, group } = data;
    let { items } = props;
    if (!items && comboType) {
        items = combos && combos[enums.lookupType[comboType]]
    }
    const fieldName = name ? name : data.name;
    let value = 0;
    if (records) {
        if (key && typeId) {
            const rec = records.find(rec => Number(rec[key]) === typeId);
            if (rec) {
                value = rec[fieldName];
            }
        } else {
            value = records[fieldName];
        }
    }

    if (formSubmit || (value && value !== undefined)) {
        if (!value && data.validations && data.validations[0] === "required") {
            error = true
        }
    }

    const isMultiSelect = type === 'multiSelect';
    if (isMultiSelect && value) {
        value = value.split(',');
        let values = [];
        value.forEach((val) => { values.push(Number(val)) });
        value = values;
    }

    const defaultProps = {
        options: items.sort(util.dynamicsort("label", "asc")),
        getOptionLabel: item => item.label ? item.label : item,
        value: item => item.value ? item.value : item,
    };

    items && (value = items.find(d => d.value === value || d.value === Number(value)))

    if (data && data.validations && data.validations.includes("required")) {
        required = true;
    }

    useEffect(() => {
        let id = document.getElementById(`autoId-${group}-${name}`);
        if (id)
            id.autocomplete = "off-state"
    }, [])

    return <FormControl error={error}>
        <Autocomplete
            id={`autoId-${group}-${name}`}
            {...defaultProps}
            value={value}
            onChange={(e, d) => handleChange(d, data)}
            renderInput={(autoprops) => {
                return <TextField {...autoprops} label={props.label} error={error} required={required} />
            }}
        /></FormControl>
});

export default React.memo(SelectComp);