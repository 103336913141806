import React, { Fragment, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Pie from '../../components/Pie';
import Task from '../tasks/index';
import Appointment from '../appointments/index';
import { AppBar, Button, makeStyles, Popover } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { request, apis } from '../../httpUtil';
import util from '../../util';
import Bar from '../../components/Bar';
import { enums } from '../../Utility/const';
import { DateRangePicker } from "../../components/DateRangePicker";
import { history } from '../../redux/store/';
import CaseReport from '../report';
import actions from '../../redux/actions/actionType';

const moment = require('moment');
const dashboardTab = 0;
const appointmentTab = 1;
const reportTab = 2;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`dashboard-panel-${index}`}
            aria-labelledby={`dashboard-panel-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `dashboard-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTabs-indicator': {
            backgroundColor: '#01579B !important'
        },
        '& .materialui-daterange-picker-makeStyles-highlighted-26': {
            background: 'rgba(2, 119, 189, 0.25) !important'
        },
        '& .MuiAppBar-root': {
            zIndex: '1'
        }
    },
    posAbs: {
        position: 'absolute'
    },
    dateSelectionBtn: {
        background: '#FFFFFF',
        border: '1px solid #E5E5E5',
        boxSizing: 'border-box',
        borderRadius: '18px',
        marginRight: '1em',
        '& .MuiButton-root': {
            marginLeft: '0 !important',
            color: 'rgba(103, 103, 103, 0.87)',
            minWidth: '5.5em'
        }
    },
    weekBtn: {
        borderLeft: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderRadius: 0
    }
}));

const Dashboard = (props) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const toggle = () => setOpen(!open);
    const isReportTab = tabIndex === reportTab;
    let defaultDateRange = isReportTab ? { startDate: moment().startOf('year')._d, endDate: moment().endOf('day')._d, label: 'Month', active: '' } : { startDate: moment().startOf('week')._d, endDate: moment().endOf('week')._d, active: 'week' };
    const [dateRange, setDateRange] = React.useState(defaultDateRange);
    const dispatch = useDispatch();
    const appointmentChartData = useSelector(state => state.appReducer.appointmentChartData);
    const taskChartData = useSelector(state => state.appReducer.taskChartData);
    const chartFilter = useSelector(state => state.appReducer.chartFilter);
    const taskList = useSelector(state => state.appReducer.taskList);
    const getData = useCallback(async () => {
        let activeCaseId = util.getCaseId();
        const params = Object.assign({ action: 'list', caseId: activeCaseId, fromDashboard: true }, { pageSize: 5, pageNo: 0, orderBy: "appointmentDateTime", dir: "desc", searchValue: { dateRange: dateRange } });
        const response = await request(apis.appointment, params);
        if (response && response.success) {
            if (response.chartData) {
                dispatch({ type: 'SET_CHART_DATA_APPOINTMENTLIST', chartData: response.chartData });
                dispatch({ type: 'SET_NOTIFICATION_DATA', notifications: response.notificationData });
            }
        }
    }, [dispatch])

    useEffect(() => {
        getData();
    }, [getData])

    useEffect(() => {
        dispatch({ type: actions.SET_ACTIVE_TAB, activeTab: 1 });
        setDateRange(defaultDateRange);
    }, [tabIndex]);

    useEffect(() => {
        let { hash } = window.location;
        let activeTab = 0;
        switch (hash) {
            case '#/dashboard/appointment':
                activeTab = appointmentTab;
                break;
            case '#/dashboard/report':
                activeTab = reportTab;
                break;
            default:
                break;
        }
        setTabIndex(activeTab);
    }, [])

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        let url = '/dashboard';
        switch (newValue) {
            case appointmentTab:
                url = '/dashboard/appointment';
                break;
            case reportTab:
                url = '/dashboard/report';
                break;
            default:
                url = '/dashboard';
                break;
        }
        history.push(url);
        setDateRange(defaultDateRange);
    };

    const onDateRangeChange = (event, type) => {
        switch (type) {
            case 'day':
                setDateRange({ startDate: moment().startOf('day')._d, endDate: moment().endOf('day')._d, label: "Today", active: 'day' })
                break;
            case 'week':
                setDateRange({ startDate: moment().startOf('week')._d, endDate: moment().endOf('week')._d, active: 'week' })
                break;
            case 'month':
                setDateRange({ startDate: moment().startOf('months')._d, endDate: moment().endOf('months')._d, label: 'This Month', active: 'month' })
                break;
            default:
                break;
        }
    }

    const classes = useStyles();

    const today = moment(new Date()).format(util.dateFormat);
    const chartData = tabIndex === 0 ? taskChartData : appointmentChartData;
    const appointmentChartTitle = chartFilter && chartFilter.appointmentDate && (new Date()).getMonth() !== chartFilter.appointmentDate.month() ? "Appointments Status (Selected Month)" : `All Client Appointments Status`;
    const pieChartTitle = chartFilter && chartFilter.month != 0 ? "My Tasks by Priority" : `My Tasks by Priority`;
    const pieChartTitleRight = chartFilter && chartFilter.month != 0 ? "(Selected Month)" : moment(new Date()).format(util.chartDateFormat);
    const pieFooterText = taskList && `${taskList.total} Tasks`;
    const startDate = dateRange ? moment(dateRange.startDate).format(util.dateRangeFormat) : moment().format(util.dateRangeFormat);
    const endDate = dateRange ? moment(dateRange.endDate).format(util.dateRangeFormat) : moment().format(util.dateRangeFormat);
    const isSameDate = startDate == endDate;
    const isDashBoardTab = tabIndex === dashboardTab;
    return (
        <div className={`dashboard-tab ${classes.root}`} >
            <Grid container spacing={0}>
                <AppBar position="static" color="default">
                    <Tabs value={tabIndex} onChange={handleChange} indicatorColor="primary">
                        <Tab label="My Tasks" {...a11yProps(dashboardTab)} />
                        <Tab label="Client Appointments" {...a11yProps(appointmentTab)} />
                        <Tab label="Report" {...a11yProps(reportTab)} />
                    </Tabs>
                </AppBar>
            </Grid>
            <Grid container spacing={0} className="mrt-2">
                <div className={classes.dateSelectionBtn}>
                    <Button className={`day-btn ${dateRange.active == 'day' && 'active-date'}`} onClick={(e) => onDateRangeChange(e, 'day')} >Day</Button>
                    <Button className={`${classes.weekBtn} ${dateRange.active == 'week' && 'active-date'}`} onClick={(e) => onDateRangeChange(e, 'week')}>Week</Button>
                    <Button className={`month-btn ${dateRange.active == 'month' && 'active-date'}`} onClick={(e) => onDateRangeChange(e, 'month')}>Month</Button>
                </div>
                <div>
                    <div className="date-range-bg" onClick={toggle} >
                        <svg className="pdr-1" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6667 10.6667H9.33333V16H14.6667V10.6667ZM17.3333 1.33333H16V0H13.3333V1.33333H5.33333V0H2.66667V1.33333H1.33333C0.6 1.33333 0 1.93333 0 2.66667V18.6667C0 19.4 0.6 20 1.33333 20H17.3333C18.0667 20 18.6667 19.4 18.6667 18.6667V2.66667C18.6667 1.93333 18.0667 1.33333 17.3333 1.33333ZM16 17.3333H2.66667V6.66667H16V17.3333Z" fill="black" fill-opacity="0.87" />
                        </svg>
                        <div>{dateRange && isSameDate ? startDate : `${startDate} - ${endDate}`}</div>
                    </div>
                    <div className={classes.posAbs}>
                        <DateRangePicker
                            open={open}
                            toggle={toggle}
                            wrapperClassName="date-range"
                            //maxDate={moment()}
                            initialDateRange={dateRange}
                            dateRangeValue={dateRange}
                            onChange={(range) => setDateRange(range)}
                        />
                    </div>
                </div>
            </Grid>
            { !isReportTab ? <Grid container justify="center">
                <Grid item xs={5} md={5} lg={5} align="center" className="mrt-2">
                    {isDashBoardTab ? <Pie chartData={chartData} chartHeader={pieChartTitle} pieFooterText={pieFooterText} pieChartTitleRight={pieChartTitleRight} isTaks={true} /> :
                        <Bar chartData={chartData} chartHeader={appointmentChartTitle}
                            typeCombo={isDashBoardTab ? enums.lookupType.ActivityType : enums.lookupType.AppointmentStatus}
                            statusCombo={isDashBoardTab ? enums.lookupType.ActivityStatus : enums.lookupType.AppointmentStatus}
                            barChartTitleRight={pieChartTitleRight} isAppointment={true} legend={false} width={380} stepSize={10}
                            xAxisLabel={'Appointment Count'} yAsisLabel={"Appointment Status"}
                        />
                    }
                </Grid>
                <Grid item xs={7} md={7} lg={7} align="center" className="pdl-1 mrt-2">
                    <Bar chartData={chartData} chartHeader={isDashBoardTab ? `My Tasks by Type` : `All Client Appointments by Type`}
                        typeCombo={isDashBoardTab ? enums.lookupType.ActivityType : enums.lookupType.AppointmentType}
                        statusCombo={isDashBoardTab ? enums.lookupType.ActivityStatus : enums.lookupType.AppointmentStatus}
                        barChartTitleRight={pieChartTitleRight} legend={false} xAxisLabel={isDashBoardTab ? "Task Amount" : 'Appointment Amount'}
                        yAsisLabel={isDashBoardTab ? "Task Type" : "Appointment Type"} />
                </Grid>
            </Grid>
                : null}
            <TabPanel value={tabIndex} index={dashboardTab}>
                <Grid item xs={12} >
                    <Task {...props} fromDashboard={true} showChart={false} dateRange={dateRange} />
                </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={appointmentTab}>
                <Grid item xs={12}>
                    <Appointment {...props} fromDashboard={true} dateRange={dateRange} />
                </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={reportTab}>
                <CaseReport {...props} fromDashboard={false} dateRange={dateRange} />
            </TabPanel>
        </div >
    )
}

export default Dashboard;