import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Checkbox } from '@material-ui/core';
import Pie from '../../components/Pie';
import List from '../../components/List';
import { enums } from '../../Utility/const'
import util from '../../util';
import actions from '../../redux/actions/actionType';
import { useSelector, useDispatch } from 'react-redux';
import '../../App.css';
import Bar from '../../components/Bar';
import FormDialog from '../../components/FormDialog';
import swal from 'sweetalert';
import { request, apis } from '../../httpUtil';
const moment = require('moment');

const useStyles = makeStyles({
    root: {
        '& .MuiFormGroup-row': {
        },
        '& .MuiCheckbox-root .MuiSvgIcon-root': {
            fontSize: '20px'
        },
        '& .MuiInputLabel-formControl': {
            fontSize: '14px'
        },
        '& .MuiTableSortLabel-root:hover': {
            color: 'rgb(255, 255, 255)'
        },
        '& .MuiTableSortLabel-icon': {
            marginRight: '0  !important',
            marginLeft: '0  !important'
        },
        '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
            marginRight: '0  !important',
            marginLeft: '0  !important'
        },
        '& .MuiTableCell-head': {
            color: '#FFFFFF',
            padding: '14px 0px !important'
        },
        '& .MuiAppBar-colorDefault': {
            backgroundColor: '#2196f3',
            color: '#FFFFFF'
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            background: '#5062e5'
        }
    },
    searchBox: {
        padding: '.5em 2em 1.5em 2em'
    },
    bottomSection: {
        marginTop: '0em'
    },
    pieChart: {
        padding: '0em',
        fontSize: '14px',
    },
    addButton: {
        color: '#FFFFFF',
        marginBottom: '.5em'
    },
    tableWrapper: {
        marginTop: '.2em'
    },
    container: {
        maxHeight: 365,
    }
});

const formFields = [
    {
        "label": "Priority",
        "name": "activityPriorityId",
        "type": "dropdown",
        "id": "activityPriorityId",
        "comboType": "Priority",
        "validations": [
            "required"
        ],
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Due Date",
        "name": "time",
        "type": "dateTime",
        "id": "time",
        "format": "MM/dd/yyyy",
        "validations": [
            "required",
            {
                "type": "date",
                "min": "01/01/1900",
                "max": moment().add(1, 'years').format('MM/DD/YYYY')
            }
        ],
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    },
    {
        "label": "Type",
        "name": "activityTypeId",
        "type": "dropdown",
        "id": "activityTypeId",
        "comboType": "ActivityType",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        },
        "validations": [
            "required"
        ]
    },
    {
        "label": "Activity",
        "name": "activityId",
        "type": "dropdown",
        "id": "activityId",
        "comboType": "Activity",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        },
        "validations": [
            "required"
        ]
    },
    {
        "label": "Status",
        "name": "activityStatusId",
        "type": "dropdown",
        "id": "activityStatusId",
        "comboType": "ActivityStatus",
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        },
        "validations": [
            "required"
        ]
    },
    {
        "label": "Notes",
        "name": "notes",
        "type": "text",
        "id": "notesId",
        "multiline": true,
        "layout": {
            xs: 12, sm: 12, md: 12, lg: 12
        }
    }
]

let combos, classes = {};
const columns = [
    {
        title: "Priority", comboType: "Priority", field: "activityPriorityId",
        render: rowData => { return util.renderer.combo('activityPriorityId', rowData, enums.lookupType.Priority) }
    },
    {
        title: "Due Date", field: "time", type: 'date',
        render: rowData => { return util.renderer.date('time', rowData) }
    },
    {
        title: "Mother's Name", field: "motherName", cellStyle: {
            color: '#2196F3'
        }, type: 'string'
    },
    {
        title: "Type", field: "activityType", width: 120, type: 'string'
    },
    {
        title: "Activity", field: "activity", type: 'string'
    },
    {
        title: "Status", field: "activityStatus", type: 'string',
        render: rowData => { return util.renderer.statusFormatter('activityStatus', rowData) }
    },
    {
        title: "Created By", field: "createdBy", type: 'string'
    },
    {
        title: "Modified On", field: "updatedAt", type: 'date',
        render: rowData => { return util.renderer.date('updatedAt', rowData) }
    }
]
const Tasks = (props) => {
    const dispatch = useDispatch();
    classes = useStyles();
    const [formSubmit, setFormSubmit] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [taskId, setTaskId] = useState(0);
    let [searchValue, setSearchValue] = React.useState({ createdByMe: true, month: 0, searchPramas: '' });
    combos = useSelector(state => state.appReducer.comboData);
    const chartData = useSelector(state => state.appReducer.taskChartData);
    const taskList = useSelector(state => state.appReducer.taskList);
    let { fromDashboard, showChart } = props;
    let activeCaseId = util.getCaseId();
    const onAdd = () => {
        dispatch({ type: actions.SET_ACTIVITY_DATA, data: { notes: '' } });
        setShowForm(true);
        setTaskId(0);
    }
    const changeAction = {
        icon: 'edit_outline',
        tooltip: 'Edit',
        onClick: (event, rowData) => {
            dispatch({ type: actions.SET_ACTIVITY_DATA, data: rowData });
            setShowForm(true);
            setTaskId(rowData.caseActivityId);
        }
    }

    const expandAction = (data) => {
        return (
            {
                icon: () => <>
                    {data.tableData && data.tableData.showDetailPanel ? util.openDescriptionIcon(data.notes) : util.descriptionIcon(data.notes)}
                </>,
                tooltip: 'Detail Information'
            }
        )
    }

    const handleClose = () => {
        setShowForm(false);
    }
    const onCheckBoxChange = (e) => {
        setSearchValue({ ...searchValue, ...{ createdByMe: !e.currentTarget.checked } });
    }

    const getToolbar = () => {
        return (
            <>
                <div className="task-toolbar">
                    <Checkbox checked={!searchValue.createdByMe} onChange={onCheckBoxChange} />
                    <div className="pdt-5">Show All</div>
                </div>
            </>
        )
    }
    const onSave = async () => {
        const storeData = util.getStore();
        let data = storeData.taskData;
        data.caseId = activeCaseId || data.caseId;
        if (taskId > 0) {
            data.id = taskId;
        }
        let errors, isValid = true;
        formFields.forEach(s => {
            if (!isValid) {
                return;
            }
            const { name, validations } = s;
            let value;
            if (data) {
                value = data[name];
                if (validations && validations.length > 0 && value === undefined) {
                    if (validations.includes('required')) {
                        value = '';
                    }
                }

                errors = util.validation(s, value);
                if (errors && errors.length > 0) {
                    isValid = false;
                }
            }
        });
        if (!isValid) {
            swal({
                title: errors[0].helperText || "Fill required fields.",
                icon: "error",
                dangerMode: true
            });
            setFormSubmit(true);
        } else {
            const responseAdd = await request(apis.task, { action: 'Save', data: data });
            if (responseAdd && responseAdd.success) {
                swal('Data saved successfully', { icon: "success", });
                handleClose();
            }
            if (!responseAdd.success) {
                util.listErrorAlert(responseAdd.error);
            }
        }
    }
    const handleSearch = (e, value) => {
        e.preventDefault();
        setSearchValue({ ...searchValue, ...{ searchPramas: value } });
    }
    const today = moment(new Date()).format(util.dateFormat);
    const pieChartTitleRight = searchValue && searchValue.month != 0 ? "(Selected Month)" : moment(new Date()).format(util.chartDateFormat);
    const pieFooterText = taskList && `${taskList.total} Tasks`;
    searchValue = Object.assign(searchValue, { dateRange: props.dateRange });
    return (<div className={classes.root}>
        <FormDialog formSubmit={formSubmit} dataGroup='taskData' open={showForm} formFields={formFields} onSave={onSave} title='Task' handleClose={handleClose} activeCaseId={activeCaseId} id={taskId} />
        {showChart && <Grid container justify="center">
            <Grid item xs={5} md={5} lg={5} align="center">
                <Pie chartData={chartData} chartHeader={'Case Tasks by Priority'} pieFooterText={pieFooterText} pieChartTitleRight={pieChartTitleRight} isTaks={true} />
            </Grid>

            <Grid item xs={7} md={7} lg={7} align="center" className="pdl-1">
                <Bar chartData={chartData} chartHeader={`Case Tasks by Type`} typeCombo={enums.lookupType.ActivityType} statusCombo={enums.lookupType.ActivityStatus}
                    barChartTitleRight={pieChartTitleRight} yAsisLabel="Task Type" xAxisLabel="Task Amount" />
            </Grid>
        </Grid>}

        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.tableWrapper}>
                <List isExpand={true}
                    getToolbar={getToolbar}
                    actions={[expandAction, changeAction]}
                    showForm={showForm}
                    // formRoute='/tasks/'
                    doubleClick={!fromDashboard}
                    defaultSort="time"
                    sortDir="desc"
                    onAdd={!fromDashboard && onAdd}
                    options={{ toolbar: true }}
                    columns={columns}
                    fromDashboard={fromDashboard}
                    searchValue={searchValue}
                    activeCaseId={activeCaseId}
                    listOption={{ api: apis.task, listName: 'taskList', reduxActions: actions.SET_TASK_LIST }}
                    addTitle='Add Task'
                    tableHeader='Tasks Monitor'
                    handleSearch={handleSearch} />
            </Grid>
        </Grid>
    </div>)
}
export default Tasks;