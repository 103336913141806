import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { apis, request } from '../../../httpUtil';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { lookupTypeFormFields } from '../../../Utility/const';
import List from '../../../components/List';
import util from '../../../util';
import swal from 'sweetalert';
import actions from '../../../redux/actions/actionType';
import { history } from '../../../redux/store/';
import FormDialog from '../../../components/FormDialog';

const useStyles = makeStyles({
    root: {
        '& .table-expand-wrapper .MuiTableRow-hover>td:first-child': {
            paddingLeft: '0 !important'
        }
    },
    tableWrapper: {
        marginTop: '.2em'
    }
});

const columns = [
    {
        title: "Setup Type Id", field: "lookupTypeId", cellStyle: { color: '#2196F3', textAlign: 'center' }, width: '11em',
        render: rowData => {
            return <div onClick={() => onRowClick(rowData)}>{rowData.lookupTypeId}</div>
        }
    },
    {
        title: "Setup Type", field: "lookupType", type: 'string', cellStyle: { color: '#2196F3' },
        render: rowData => {
            return <div onClick={() => onRowClick(rowData)}>{rowData.lookupType}</div>
        }
    },
    { title: "Description", field: "description", type: 'string' }
]

const onRowClick = (rowData) => {
    history.push(`/admin/lookuptype/${rowData.lookupTypeId}`);
}

const LookupType = React.memo(function LookupType(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const [lookupTypeId, setLookupTypeId] = useState(0);
    const [searchValue, setSearchValue] = React.useState({ searchPramas: '' });

    const handleSearch = (e, value) => {
        e.preventDefault();
        setSearchValue({ ...searchValue, ...{ searchPramas: value } });
    }

    const onAdd = () => {
        dispatch({ type: actions.SET_LOOKUP_TYPE_DATA, data: {} });
        setShowForm(true);
        setLookupTypeId(0);
    }

    const changeAction = {
        icon: 'edit_outline',
        tooltip: 'Edit',
        onClick: (event, rowData) => {
            dispatch({ type: actions.SET_LOOKUP_TYPE_DATA, data: rowData });
            setShowForm(true);
            setLookupTypeId(rowData.lookupTypeId)
        }
    }

    const handleClose = () => {
        setShowForm(false);
    }

    const onSave = async () => {
        const storeData = util.getStore();
        let data = storeData.lookupTypeData;
        if (lookupTypeId > 0) {
            data.id = lookupTypeId;
        }
        let errors, isValid = true;
        lookupTypeFormFields.forEach(s => {
            if (!isValid) {
                return;
            }
            const { name, validations } = s;
            let value;
            if (data) {
                value = data[name];
                if (validations && validations.length > 0 && value === undefined) {
                    if (validations.includes('required')) {
                        value = '';
                    }
                }
                errors = util.validation(s, value);
                if (errors && errors.length > 0) {
                    isValid = false;
                }
            }
        });
        if (!isValid) {
            swal({
                title: errors[0].helperText || "Fill required fields.",
                icon: "error",
                dangerMode: true
            });
            setFormSubmit(true);
        } else {
            const responseAdd = await request(apis.lookupType, { action: 'Save', data: data });
            if (responseAdd && responseAdd.success) {
                swal('Data saved successfully', { icon: "success", });
                handleClose();
            }
            if (!responseAdd.success) {
                util.listErrorAlert(responseAdd.error);
            }
        }
    }

    return <>
        <div className="case-section">
            <Grid container spacing={0} className={classes.root}>
                <Grid item xs={12} className={classes.tableWrapper}>
                    <List columns={columns}
                        showForm={showForm}
                        searchValue={searchValue}
                        doubleClick={false}
                        history={history}
                        actions={[changeAction]}
                        onAdd={onAdd}
                        options={{ toolbar: true }}
                        defaultSort="lookupTypeId"
                        sortDir="ASC"
                        // className='table-expand-wrapper-stick'
                        listOption={{
                            api: apis.lookupType,
                            listName: 'lookupTypeList',
                            reduxActions: 'SET_LOOKUP_TYPE_LIST'
                        }}
                        addTitle='Add Setup Type'
                        tableHeader='Setup Type'
                        handleSearch={handleSearch}
                    />
                </Grid>
            </Grid>
            <FormDialog formSubmit={formSubmit} dataGroup='lookupTypeData' open={showForm} formFields={lookupTypeFormFields} onSave={onSave} title='Setup Type' handleClose={handleClose} id={lookupTypeId} />
        </div>
    </>
})

export default React.memo(LookupType);