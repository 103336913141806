import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { apis, request } from '../../../httpUtil';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { lookupFormFields } from '../../../Utility/const';
import List from '../../../components/List';
import util from '../../../util';
import swal from 'sweetalert';
import actions from '../../../redux/actions/actionType';
import { history } from '../../../redux/store/';
import FormDialog from '../../../components/FormDialog';
import { de } from 'date-fns/locale';

const useStyles = makeStyles({
    tableWrapper: {
        marginTop: '.2em'
    }
});

const Lookup = React.memo(function Lookup(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const [lookupId, setLookupId] = useState(0);
    const [searchValue, setSearchValue] = React.useState({ searchPramas: '' });
    const lookupList = useSelector(state => state.appReducer.lookupList) || {};
    const lookupTypeName = lookupList.lookupTypeName || '';
    const showPoints = lookupList.showPoints;

    let columns = [
        {
            title: `Id`, field: "lookupId", cellStyle: { color: '#2196F3' }, width: '10%',
            render: rowData => {
                return <div onClick={() => onRowClick(rowData)}>{rowData.lookupId}</div>
            }
        },
        {
            title: `${lookupTypeName || 'Display Value'}`, field: "displayValue", type: 'string', cellStyle: { color: '#2196F3' },
            render: rowData => {
                return <div onClick={() => onRowClick(rowData)}>{rowData.displayValue}</div>
            }
        },
        // { title: "Custom Field", field: "customStringValue", type: 'string', width: '25%' },
        { title: "Display Order", field: "sortOrder", type: 'string', cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' } }

    ]
    if (showPoints) {
        if (columns.findIndex(e => e.field == "field") == -1) {
            columns.push({ title: "Points", field: "points", type: 'string', cellStyle: { textAlign: 'center' }, headerStyle: { textAlign: 'center' } })
        }
        if (lookupFormFields.findIndex(e => e.label == "Points") == -1) {
            lookupFormFields.push({
                "label": "Points", "name": "points", "type": "text", "id": "points", 'format': "number",
                "layout": {
                    xs: 12, sm: 12, md: 12, lg: 12
                }
            })
        }
    }

    const onRowClick = (rowData) => {
        dispatch({ type: actions.SET_LOOKUP_DATA, data: rowData });
        setShowForm(true);
        setLookupId(rowData.lookupId);
    }

    const handleSearch = (e, value) => {
        e.preventDefault();
        setSearchValue({ ...searchValue, ...{ searchPramas: value } });
    }

    const onAdd = () => {
        dispatch({ type: actions.SET_LOOKUP_DATA, data: {} });
        setShowForm(true);
        setLookupId(0);
    }

    const changeAction = {
        icon: 'edit_outline',
        tooltip: 'Edit',
        onClick: (event, rowData) => {
            dispatch({ type: actions.SET_LOOKUP_DATA, data: rowData });
            setShowForm(true);
            setLookupId(rowData.lookupId);
        }
    }

    const handleClose = () => {
        setShowForm(false);
    }

    const onSave = async () => {
        const storeData = util.getStore();
        let data = storeData.lookupData;
        if (lookupId > 0) {
            data.id = lookupId;
        }
        if (props.lookupTypeId) {
            data.lookupTypeId = props.lookupTypeId
        }
        let errors, isValid = true;
        lookupFormFields.forEach(s => {
            if (!isValid) {
                return;
            }
            const { name, validations } = s;
            let value;
            if (data) {
                value = data[name];
                if (validations && validations.length > 0 && value === undefined) {
                    if (validations.includes('required')) {
                        value = '';
                    }
                }
                errors = util.validation(s, value);
                if (errors && errors.length > 0) {
                    isValid = false;
                }
            }
        });
        if (!isValid) {
            swal({
                title: errors[0].helperText || "Fill required fields.",
                icon: "error",
                dangerMode: true
            });
            setFormSubmit(true);
        } else {
            const responseAdd = await request(apis.lookup, { action: 'Save', data: data });
            if (responseAdd && responseAdd.success) {
                swal('Data saved successfully', { icon: "success", });
                handleClose();
            }
            if (!responseAdd.success) {
                util.listErrorAlert(responseAdd.error);
            }
        }
    }

    return <>
        <div className="case-section">
            <Grid container spacing={0} >
                <Grid item xs={12} className={classes.tableWrapper}>
                    <List columns={columns}
                        showForm={showForm}
                        searchValue={searchValue}
                        doubleClick={false}
                        history={history}
                        actions={[changeAction]}
                        onAdd={onAdd}
                        options={{ toolbar: true }}
                        defaultSort="lookupId"
                        sortDir="ASC"
                        // className='table-expand-wrapper-stick'
                        listOption={{
                            api: apis.lookup,
                            listName: 'lookupList',
                            reduxActions: 'SET_LOOKUP_LIST'
                        }}
                        addTitle={`Add ${lookupTypeName}`}
                        tableHeader={`${lookupTypeName || ''}`}
                        handleSearch={handleSearch}
                        lookupTypeId={props.lookupTypeId}
                    />
                </Grid>
            </Grid>
            <FormDialog formSubmit={formSubmit} dataGroup='lookupData' open={showForm} formFields={lookupFormFields} onSave={onSave} title={lookupTypeName} handleClose={handleClose} id={lookupId} />
        </div>
    </>
})

export default React.memo(Lookup);