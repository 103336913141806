const isLive = true;
//dev site - https://dev.cms.bentenlab.com/
//test site - https://test.cms.bentenlab.com/
//live site - https://cms.bentenlab.com/
const serverUrl = isLive ? "https://dev.cms.bentenlab.com/api/v1/" : "http://localhost:1337/api/v1/";

const apis = {
    login: serverUrl + 'account/login',
    check_login: serverUrl + 'account/check-login',
    logout: serverUrl + 'account/logout',
    case: serverUrl + 'cases/case',
    common: serverUrl + 'common/common',
    task: serverUrl + 'task/task',
    appointment: serverUrl + 'appointment/appointment',
    statuslog: serverUrl + 'cases/statuslog',
    supportdocument: serverUrl + 'cases/supportdocument',
    supportprogram: serverUrl + 'cases/supportprogram',
    attachment: serverUrl + 'cases/attachment',
    award: serverUrl + 'award/award',
    forgotPassword: serverUrl + 'account/forgotpassword',
    casenote: serverUrl + 'cases/note',
    resource: serverUrl + 'admin/resource',
    lookupType: serverUrl + 'admin/lookuptype',
    lookup: serverUrl + 'admin/lookup'
}

export default apis;