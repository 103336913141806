import React, { useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Typography, Popover, makeStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText, TextareaAutosize } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import { styles } from '../../navigation/styles';
import util from '../../util';
import moment from 'moment';
import actions from '../../redux/actions/actionType';
import axios from 'axios';
import io from "socket.io-client";
import { enums } from '../../Utility/const';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '25em',
        padding: '1em 1.5em',
        height: '21em',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        '& .MuiListItemText-primary': {
            fontWeight: '500',
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: '0.5px',
            display: 'flex',
            alignItems: 'center'
        }
    },
    typography: {
        padding: '1em 1em 0.5em 1em',
        width: '26em',
        borderBottom: '1px solid #E5E5E5'
    },
    notificationText: {
        fontWeight: '500',
        fontSize: '18px',
        marginLeft: '.5em'
    },
    popover: {
        '& .MuiPopover-paper': {
            maxHeight: '34em',
            top: '57px !important',
            right: '60px !important',
            left: 'auto !important'
        }
    },
    newMessage: {
        fontSize: '12px',
        color: '#2196F3',
        display: 'flex'
    },
    newMessageCount: {
        background: '#2196F3',
        color: '#fff',
        borderRadius: '10px',
        fontSize: '12px',
        padding: '0px 11px',
        marginLeft: '1em',
        marginTop: '4px',
        height: '19px'
    },
    headerName: {
        margin: 'auto 0',
        fontWeight: '500',
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    senderCont: {
        maxWidth: '22em',
        float: 'right',
        margin: '1em 0',
        width: '100%'
    },
    sender: {
        background: '#FAFAFA',
        border: '1px solid #E5E5E5',
        borderRadius: '12px 0 12px 12px',
        padding: '1em',
        marginTop: '.5em',
        color: '#9E9E9E',
        fontSize: '15px',
        lineHeight: '21px',
        float: 'right',
        color: '#000'
    },
    receiverCont: {
        maxWidth: '22em',
        float: 'left',
        margin: '1em 0',
        width: '100%'
    },
    receiver: {
        background: '#E3F2FD',
        borderRadius: '0 12px 12px 12px',
        float: 'left',
        padding: '1.5em',
        marginTop: '.5em',
        fontSize: '15px',
        lineHeight: '21px',
        color: '#000'
    },
    senderName: {
        fontSize: '12px',
        letterSpacing: '0.4px',
        color: 'rgba(0, 0, 0, 0.24)'
    },
    receiverName: {
        fontSize: '12px',
        letterSpacing: '0.4px',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    chatInputBox: {
        borderTop: '1px solid #E5E5E5'
    },
    notification: {
        background: '#FAFAFA',
        border: '1px solid #E5E5E5',
        borderRadius: '8px 8px 8px 8px',
        padding: '.35em .75em;',
        marginTop: '.5em',
        color: '#9E9E9E',
        fontSize: '12px',
        lineHeight: '21px',
        fontStyle: 'italic',
        display: 'inline-flex',
        '& span': {
            marginLeft: '5px'
        }
    },
    callMessage: {
        justifyContent: 'center',
        width: '100%',
        display: 'inline-flex'
    }
}))

const chat = [
    {
        senderMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id aliquet lectus proin nibh nisl. Suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consecteturg',
        receiverMessage: 'Did you get my last message?'
    },
    {
        senderMessage: '',
        receiverMessage: 'I couldn’t make the appointment today.'
    },
    {
        senderMessage: 'I couldn’t make the appointment today.',
        receiverMessage: ''
    },
    {
        senderMessage: '',
        receiverMessage: 'I couldn’t make the appointment today.'
    }
]

const SendMessage = (props) => {
    // const message = useSelector(state => state.appReducer.message);
    let dispatch = useDispatch();
    const chatData = useSelector(state => state.appReducer.chatData);
    const userData = useSelector(state => state.appReducer.userData);
    const classes = useStyles();
    const socketRef = useRef();
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [socketMessage, setSocketMessage] = useState('');
    const { appuserId, roomId, webUserId, chatRoomDetails } = chatData;
    let conversations = chatData && chatData.chatRoomDetails && chatData.chatRoomDetails.conversations || [];

    const connectRoom = () => {
        if (!roomId) {
            console.log("Subscription skipped");
            return;
        }
        setMessages(conversations);
        socketRef.current.emit('identity', webUserId);
        socketRef.current.emit('subscribe', roomId, webUserId);
        console.log("Subscribing to " + webUserId + " :" + roomId);
    }

    const socketError = (err) => {
        console.log("Scocket error" + err);
    }

    useEffect(() => {
        const socket = io.connect(util.mobileAppBaseUrl);
        socketRef.current = socket;
        socket.on('connect', () => {
            console.log("Scocket Connect");
            connectRoom();
        });

        socketRef.current.on("new message", (newMessage) => {
            if (newMessage.postedUserType == 'app') {
                setSocketMessage(newMessage);
            }
            setUnReadCount();
        })
        // socket.on('connect_failed', socketError);
        // socket.on('connect_error', socketError);
        socket.on('disconnect', socketError);
        // socket.on('error', socketError);
    }, []);

    useEffect(() => {
        connectRoom();
    }, [roomId, webUserId, appuserId]);

    useEffect(() => {
        if (socketMessage && socketMessage.chatRoomId !== roomId) {
            console.log("Received message - todo - notification:" + JSON.stringify(socketMessage));
            return;
        }
        setMessages([...messages, socketMessage]);
        setTimeout(() => {
            document.getElementById('chatBody') && document.getElementById('chatBody').lastElementChild.scrollIntoView();
        }, 50)
    }, [socketMessage]);

    const sendMessage = async (e) => {
        e.preventDefault();
        if (roomId && message) {
            await axios({
                method: 'POST', url: `${util.mobileAppBaseUrl}/chat/${roomId}/message`,
                data: {
                    userId: webUserId,
                    messageText: message,
                    requestFrom: "web"
                }
            });
            setSocketMessage({ chatRoomId: roomId, createdAt: Date.parse(new Date()), userId: webUserId, message: message, postedUserType: "web" });
            setMessage("");
        }
        //socketRef.current.emit("send message", messageObject);
    }

    const setUnReadCount = () => {
        axios({
            method: 'POST', url: `${util.videoCallAPIEndPoints.unreadMsgsAndMissedCallCount}`,
            data: {
                userId: userData.id,
                requestFrom: "web"
            }
        }).then(e => {
            if (e.data && !e.data.isError) {
                const totalUnreads = Number(e.data.totalUnreadMsgs) + Number(e.data.totalUnreadMissedVideoCalls);
                dispatch({ type: actions.SET_UNREAD_COUNT, unreadCount: (totalUnreads || 0) });
            }
        });
    }
    const handleChange = (e) => {
        let { value } = e.target;
        value = util.capitalize(value);
        setMessage(value);
    }
    let AppUserName = chatRoomDetails && chatRoomDetails.user.length > 0 && chatRoomDetails.user[0].name || '';

    const getTime = (e) => {
        return (
            e.callDuration > 59 ?
                `${(Math.floor(e.callDuration / 60) < 10 ? '0' : '') + Math.floor(e.callDuration / 60)}:${(e.callDuration % 60 < 10 ? '0' : '') + e.callDuration % 60}`
                : '00:' + (e.callDuration < 10 ? '0' : '') + e.callDuration
        )
    }

    return (
        <>
            <Popover
                id={props.sendMessageId}
                open={props.openSendMessage}
                anchorEl={props.messageAnchorEl}
                // onClose={props.onSendMessageClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className={classes.popover}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>
                    <div className="flex">
                        <span className="mr-auto cursor" onClick={props.onSendMessageClose}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 7.62891H3.83L9.42 2.03891L8 0.628906L0 8.62891L8 16.6289L9.41 15.2189L3.83 9.62891H16V7.62891Z" fill="black" fill-opacity="0.54" />
                            </svg>
                        </span>
                        <div className="full-width flex mrl-2">
                            <ListItemAvatar>
                                <Avatar alt={AppUserName} src="" />
                            </ListItemAvatar>
                            <div className={classes.headerName}>{AppUserName}</div>
                        </div>
                        <span className="mr-auto">
                            <svg width="4" height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 4.62891C3.1 4.62891 4 3.72891 4 2.62891C4 1.52891 3.1 0.628906 2 0.628906C0.9 0.628906 0 1.52891 0 2.62891C0 3.72891 0.9 4.62891 2 4.62891ZM2 6.62891C0.9 6.62891 0 7.52891 0 8.62891C0 9.72891 0.9 10.6289 2 10.6289C3.1 10.6289 4 9.72891 4 8.62891C4 7.52891 3.1 6.62891 2 6.62891ZM2 12.6289C0.9 12.6289 0 13.5289 0 14.6289C0 15.7289 0.9 16.6289 2 16.6289C3.1 16.6289 4 15.7289 4 14.6289C4 13.5289 3.1 12.6289 2 12.6289Z" fill="black" fill-opacity="0.54" />
                            </svg>
                        </span>
                    </div>
                </Typography>
                <div className={classes.root} id="chatBody" >
                    {
                        messages && messages.map((e, index) => {
                            return (<>
                                {e.postedUserType != "app" && e.message && <div className={classes.senderCont} key={index}>
                                    <div>
                                        <span className={classes.senderName}>{moment(e.createdAt).format(util.chatDateFormat)}</span>
                                        <span className={`${classes.senderName} fl-r`}>You</span>
                                    </div>
                                    <div className={classes.sender}>{e.message}</div>
                                </div>
                                }
                                {e.postedUserType == "app" && e.message && <div className={classes.receiverCont} key={index}>
                                    <div>
                                        <span className={classes.receiverName}>{AppUserName}</span>
                                        <span className={`${classes.senderName} fl-r`}>{moment(e.createdAt).format(util.chatDateFormat)}</span>
                                    </div>
                                    <div className={classes.receiver}>{e.message}</div>
                                </div>
                                }
                                {
                                    e.calledUserType == 'app' &&
                                    <div className={classes.callMessage} key={index}>
                                        <div className={classes.notification}>
                                            {util.callIcon}
                                            <span>{Number(e.status) === enums.lookup.rejectedVideoCall ? 'Rejected' : Number(e.status) === enums.lookup.unansweredVideoCall ?
                                                'Missed call' : Number(e.status) === enums.lookup.answeredVideoCall && 'Incoming Call'} - {getTime(e)} - {moment(e.createdAt).format(util.chatDateFormat)} </span>
                                        </div>
                                    </div>
                                }
                                {
                                    e.calledUserType == 'web' &&
                                    <div className={classes.callMessage} key={index}>
                                        <div className={classes.notification}>
                                            {util.callIcon}
                                            <span>{Number(e.status) === enums.lookup.rejectedVideoCall ? 'No Answer' : Number(e.status) === enums.lookup.unansweredVideoCall ?
                                                'No Answer' : Number(e.status) === enums.lookup.answeredVideoCall && 'Outgoing Call'} - {getTime(e)} - {moment(e.createdAt).format(util.chatDateFormat)} </span>
                                        </div>
                                    </div>
                                }
                            </>)
                        })
                    }
                </div>
                <Typography className={`${classes.typography} ${classes.chatInputBox}`}>
                    <form className="flex" onSubmit={sendMessage}>
                        <input aria-label="minimum height" rows="5" placeholder="Type a message..." className='chat-input'
                            value={message} onChange={handleChange} />
                        <button type="submit" style={{ display: 'none' }} />
                    </form>
                </Typography>
            </Popover>
        </>
    )
}
export default withStyles(styles, { withTheme: true })(React.memo(SendMessage)); 