import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import actions from '../redux/actions/actionType';
import { enums } from '../Utility/const';
import Button from '@material-ui/core/Button';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import util from '../util';

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'relative',
        margin: '.5em 0em 0.9em 0.2em',
        padding: '2.8em 0em 1em 0em',
        '& .MuiFormLabel-root': {
            fontSize: '14px'
        }
    },
    removeBtn: {
        position: 'absolute',
        right: '-10px',
        top: '-7px;',
        color: 'red'
    },
    addButn: {
        background: '#E3F2FD',
        boxShadow: '0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 24%)',
        color: '#2196F3',
        width: '232px'
    },
    itemPadding: {
        paddingRight: '0.5em',
        lineHeight: '4.5'
    },
    itemCont: {
        padding: '0em 1em'
    },
    datePicker: {
        '& .MuiSvgIcon-root': {
            color: 'rgba(33, 150, 243, 0.87)'
        }
    }
}));

const OtherSpecifyForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let contactTypeId = enums.lookup.otherSupportPerson;
    let otherSupportPersonFields = useSelector(state => state.appReducer.otherSupportPersonData);
    let combos = useSelector(state => state.appReducer["comboData"]);
    let contactTypeData = combos && combos[enums.lookupType["contactType"]];

    useEffect(() => {
        if (otherSupportPersonFields && otherSupportPersonFields.length === 0) {
            addOtherSupport(true);
        }
    }, []);

    const handleSupportChange = (event, fieldInfo, type) => {
        let { name, value } = (event && event.target) || {};

        const { tempId, id } = fieldInfo;
        let supportIndex = -1;
        supportIndex = otherSupportPersonFields.findIndex(d => id ? d.id === id : d.tempId === tempId);
        if (supportIndex > -1) {
            if (value) {
                if (type === "cellNumber") {
                    value = util.normalizeInput(value);
                    value = value.replace(/-/g, '');
                    otherSupportPersonFields[supportIndex] = Object.assign({}, otherSupportPersonFields[supportIndex], { error: value.length !== 10 });
                    value = util.normalizeInput(value);
                }
                else if (type === "text") {
                    otherSupportPersonFields[supportIndex] = Object.assign({}, otherSupportPersonFields[supportIndex], { error: (value.length < 2 || value.length > 100) });
                    value = util.capitalize(value);
                }
            } else {
                otherSupportPersonFields[supportIndex] = Object.assign({}, otherSupportPersonFields[supportIndex], { helperText: '', error: false });
            }
            if (!id && supportIndex == 0) {
                otherSupportPersonFields[supportIndex]['defaultAddSection'] = false;
            }
            otherSupportPersonFields[supportIndex][name] = value;
            otherSupportPersonFields[supportIndex].changed = true;
            dispatch({ type: actions.SET_SUPPORT_PERSON_DATA, otherSupportPersonData: [...otherSupportPersonFields] });
        }
    }

    const addOtherSupport = (defaultAdd) => {
        let otherSupportPersonDataArray = [...otherSupportPersonFields];
        otherSupportPersonDataArray.push({ tempId: -(otherSupportPersonDataArray.length + 1), contactTypeId: contactTypeId, defaultAddSection: defaultAdd == true });
        dispatch({ type: actions.SET_SUPPORT_PERSON_DATA, otherSupportPersonData: otherSupportPersonDataArray });
    }

    const removeOtherSupport = (index, tempId) => {
        let otherSupportPersonDataArray = [...otherSupportPersonFields];
        if (tempId) {
            let tempIdIndex = otherSupportPersonDataArray.findIndex(d => d.tempId === tempId);
            if (tempIdIndex > -1) {
                otherSupportPersonDataArray.splice(tempIdIndex, 1);
            }
        } else {
            otherSupportPersonDataArray[index].isDeleted = 1;
            otherSupportPersonDataArray[index].changed = true;
        }
        dispatch({ type: actions.SET_SUPPORT_PERSON_DATA, otherSupportPersonData: otherSupportPersonDataArray });
    }

    return <>
        {otherSupportPersonFields && otherSupportPersonFields.map((data, index) => !data.isDeleted && <Paper className={classes.paper} key={index}>
            <Grid container spacing={0} className={classes.itemCont}>
                <Grid container item xs={12} sm={3} md={3} lg={3} spacing={0} className={classes.itemPadding}>
                    <Grid item xs={12} sm={12} md={12} lg={12} container >
                        <Grid item>
                            <TextField
                                autoComplete={`off`}
                                id={`Support Person’s${index + 1}`}
                                label={`Other Support Person’s Name ${index + 1}`}
                                value={(data && data['fullName']) || ''}
                                onChange={(e) => handleSupportChange(e, data, 'text')}
                                name="fullName"
                                error={data && data['fullName'] && (data['fullName'].length < 2 || data['fullName'].length > 100)}
                                inputProps={{ min: 2, max: 100 }}
                                helperText={data && data['fullName'] && (data['fullName'].length < 2 || data['fullName'].length > 100) ? 'Invalid Support Person’s Name min: 2 and max: 100' : ''}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={3} md={3} lg={3} spacing={0} className={classes.itemPadding}>
                    <Grid item xs={12} sm={12} md={12} lg={12} container >
                        <Grid item>
                            <TextField
                                autoComplete={`off`}
                                id={`Organisation${index + 1}`}
                                label={`Organization`}
                                value={(data && data['organisation']) || ''}
                                onChange={(e) => handleSupportChange(e, data, 'text')}
                                name="organisation"
                                error={data && data['organisation'] && (data['organisation'].length < 2 || data['organisation'].length > 100)}
                                helperText={data && data['organisation'] && (data['organisation'].length < 2 || data['organisation'].length > 100) ? 'Invalid organisation Name min: 2 and max: 100' : ''}
                                inputProps={{ min: 2, max: 100 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={3} md={3} lg={3} spacing={0} className={classes.itemPadding}>
                    <Grid item xs={12} sm={12} md={12} lg={12} container >
                        <Grid item>
                            <FormControl >
                                <InputLabel >{`Role:`}</InputLabel>
                                <Select labelId="dropdown" onChange={(e) => handleSupportChange(e, data)} value={(data && data['roleId']) || ''} inputProps={{
                                    name: 'roleId',
                                    id: `RoleSelect${index + 1}`
                                }} >
                                    {contactTypeData && contactTypeData.map(item => <MenuItem key={item.value ? item.value : item} value={item.value ? item.value : item}>{item.label ? item.label : item}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={3} md={3} lg={3} spacing={0} className={classes.itemPadding}>
                    <Grid item xs={12} sm={12} md={12} lg={12} container >
                        <Grid item>
                            <TextField
                                autoComplete={`off`}
                                id={`cellNumber${index + 1}`}
                                label={`Phone`}
                                type="text"
                                value={(data && data['cellNumber']) || ''}
                                onChange={(e) => handleSupportChange(e, data, 'cellNumber')}
                                name="cellNumber"
                                format={"cellNumber"}
                                helperText={data && data['cellNumber'] && data['cellNumber'].length != 12 ? 'Invalid phone number.' : ''}
                                error={data && data['cellNumber'] && data['cellNumber'].length != 12}
                                inputProps={{ min: 0, max: 10 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {index > 0 && <RemoveCircleIcon onClick={() => removeOtherSupport(index, data.tempId)} className={classes.removeBtn} />}
            </Grid>
        </Paper>)}
        <Button variant="contained" className={classes.addButn} onClick={addOtherSupport}><span style={{ marginRight: '1.2em' }} >{util.addIcon}</span>ADD ANOTHER PERSON</Button>

    </>
}

export default React.memo(OtherSpecifyForm);