import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TextField, InputAdornment } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import actions from '../../redux/actions/actionType';
import { request, apis } from '../../httpUtil/';
import swal from 'sweetalert';
import Alert from '@material-ui/lab/Alert';
import { history } from '../../redux/store/';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import loginIconImg from '../../img/login-icon.png';

const useStyles = makeStyles(theme => ({
    submit: {
        background: '#2196F3',
        color: '#fff',
        padding: '.75em 0',
        marginLeft: '0 !important',
        '&:hover': {
            background: '#2196F3',
            borderRadius: 0
        }
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        padding: '1em 0',
        '& h2': {

            fontSize: '3em',
            color: '#006dc3'
        }
    },
    subTitle: {
        fontWeight: '500',
        fontSize: '24px',
        margin: '.5em 0',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    cancelBtn: {
        background: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '.75em 0',
        marginTop: '1.5em',
        border: '1px solid rgba(0, 0, 0, 0.54)',
        boxShadow: 'none',
        marginLeft: '0 !important',
        '&:hover': {
            background: '#FFFFFF',
            borderRadius: 0
        }
    },
    passFormat: {
        fontSize: '12px',
        margin: '.5em 0 1.5em 0',
        color: 'rgba(0, 0, 0, 0.8)',
        '& div': {
            lineHeight: '18px',
            letterSpacing: '0.4px'
        }
    }
}));

const ResetPassword = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [validationError, setValidationError] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const appTitle = useSelector(state => state.appReducer.appTitleName);

    const handleClose = () => setValidationError('');

    const validation = () => {
        let valid = true;
        if (password !== confirmPassword) {
            setValidationError("Password Mismatch.");
            valid = false;
        }
        return valid;
    }
    const checkValidLink = async (event) => {
        const secretKey = props.match.params.id || null;
        if (secretKey) {
            let params = { secretKey: secretKey, action: 'validateLink' };
            const response = await request(apis.forgotPassword, params);
            dispatch({ type: actions.SET_SHOW_LOADER, showLoader: false });
            if (response) {
                let { success, userData, message } = response;
                if (success) {
                    setEmail(userData.emailAddress);
                } else {
                    swal({
                        title: message,
                        dangerMode: true,
                    })
                    history.push('/');
                }
            }
        }
    }

    useEffect(() => {
        checkValidLink();
    }, []);

    const onReset = async (event) => {
        event.preventDefault();
        if (validation() && email) {
            let params = {
                password: password,
                emailAddress: email,
                action: 'resetPassword'
            };
            const response = await request(apis.forgotPassword, params);
            dispatch({ type: actions.SET_SHOW_LOADER, showLoader: false });
            if (response) {
                let { success, message } = response;
                if (success) {
                    history.push('/');
                }
                swal({
                    title: message,
                    dangerMode: success ? true : false,
                })
            }
        }
    }

    const handleClickShowPassword = (e, isPassword) => {
        if (isPassword) {
            setShowPassword(!showPassword)
        } else {
            setShowConfirmPassword(!showConfirmPassword)
        }
    }

    const onBackClick = () => {
        history.push('/');
    }
    return (
        <div className="forgot-wrapper">
            <form >
                <Grid spacing={3} xs={6} md={5} lg={4} className="forgotPasswordBox">
                    <Grid item xs={12}>
                        <div className={classes.title}>
                            <img src={loginIconImg} alt={'Logout'} height='60' width='60' />
                            <h3 className="pdl-1">{appTitle}</h3>
                        </div>
                        <Typography variant="h4" color="inherit" align='center' className={classes.subTitle}>Change your password</Typography>
                    </Grid><br />

                    <TextField id="password" label="New password" autoComplete='off-resetpassword' type={showPassword ? 'text' : 'password'}
                        fullWidth className="mrb-1" placeholder="Your new password" onChange={value => setPassword(value.target.value)} value={password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={(e) => handleClickShowPassword(e, true)}
                                    >
                                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField id="confirmPassword" name="confirmPassword" label="Confirm new password" autoComplete='off-resetpassword' type={showConfirmPassword ? 'text' : 'password'}
                        fullWidth placeholder="Confirm your new password" onChange={value => setConfirmPassword(value.target.value)} value={confirmPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={(e) => handleClickShowPassword(e, false)}
                                    >
                                        {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div className={classes.passFormat}>
                        <div>Password must contain:</div>
                        <div>Atleast one upper case letter (A-Z)</div>
                        <div>Atleast one number (0-9)</div>
                        <div>Atleast 8 characters </div>
                    </div>
                    {validationError !== "" ? <div><Alert onClose={handleClose} severity="error" className={classes.alert}>
                        {validationError}
                    </Alert></div> : null
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onReset}
                    > Reset </Button>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.cancelBtn}
                        onClick={onBackClick}
                    >Cancel</Button>
                </Grid>
            </form></div>);
}

export default ResetPassword;


