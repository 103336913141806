import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import actions from '../redux/actions/actionType';

export default function AlertDialogSlide() {
    const dialogData = useSelector(state => state.appReducer.dialogData);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch({
            type: actions.SET_DIALOG_DATA, value: {
                open: false,
                content: null,
                title: null
            }
        });
    };
    return (<Dialog
        open={dialogData.open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
    >
        <DialogTitle id="alert-dialog-slide-title">{dialogData.title || ''}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                {dialogData.content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Ok
                         </Button>
        </DialogActions>
    </Dialog>);
}
