import * as React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import util from '../util';
import Paper from '@material-ui/core/Paper';
//import { HorizontalBar } from './Chart';

const BarChart = (props) => {
    let { width, height, responsive, maintainAspectRatio, chartData, position, typeCombo, statusCombo, fromAward, isAppointment, legend,
        xAxisLabel, yAsisLabel, stepSize, barData } = props;
    let barChartData = isAppointment ? chartData && chartData.pieChartData : chartData && chartData.barChartData ? chartData.barChartData : {};
    barChartData = Object.keys(barChartData || {}).length > 0 ? util.setBarChartData(typeCombo, statusCombo, barChartData, isAppointment) : [];
    const isIpad = window.innerWidth <= 1200;
    return (
        (fromAward || barChartData && barChartData.datasets) ? <div className="bar-chart paper-header">
            <Paper elevation={0}>
                <div className="br-b flex">
                    {props.chartHeader ?
                        <div className="paper-header-text">{props.chartHeader}</div>
                        : null}
                    {props.barChartTitleRight && <div className="paper-herder-rt-txt">{props.barChartTitleRight}</div>}
                </div>
                <HorizontalBar
                    data={!fromAward ? barChartData : barData}
                    width={width || (isIpad ? 400 : 500)}
                    height={height || 250}
                    options={{
                        responsive: responsive || false,
                        maintainAspectRatio: maintainAspectRatio || false,
                        scales: {
                            xAxes: [{
                                stacked: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: xAxisLabel || '',
                                    fontColor: 'rgba(103, 103, 103, 0.87)',
                                    fontSize: 12,
                                },
                                gridLines: {
                                    display: true,
                                },
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: !fromAward && (stepSize || 4)
                                }
                            }],
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: yAsisLabel || '',
                                    fontColor: 'rgba(103, 103, 103, 0.87)',
                                    fontSize: 12,
                                    fontStyle: 'normal'
                                },
                                stacked: true,
                                ticks: {
                                    beginAtZero: true,
                                    minRotation: 40
                                },
                                gridLines: {
                                    display: false,
                                }
                            }]
                        },
                        legend: {
                            display: !legend ? false : true,
                            position: position || 'right',
                            labels: {
                                fontColor: 'rgb(255, 99, 132)'
                            }
                        }
                    }}
                />
            </Paper>
        </div> : null
    )
}

export default React.memo(BarChart);