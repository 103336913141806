import React, { Component, useEffect } from 'react'
import ContactForm from '../../pages/assessment/ContactForm';
import Awards from '../../pages/awards';
import Grid from '@material-ui/core/Grid';
import Task from '../tasks/index';
import Appointment from '../appointments/index';
import { AppBar, makeStyles, Modal } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import loginIconImg from '../../img/login-icon.png';
import util from '../../util';
import { DateRangePicker } from "../../components/DateRangePicker";
import { enums } from '../../Utility/const';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../redux/actions/actionType';
import axios from 'axios';
import { request, apis } from '../../httpUtil';

const moment = require('moment');
const rewardTabIndex = 2;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`dashboard-panel-${index}`}
            aria-labelledby={`dashboard-panel-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </Typography>
    );
}
function a11yProps(index) {
    return {
        id: `casedetail-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiTabs-indicator': {
            backgroundColor: '#01579B !important'
        },
        '& .materialui-daterange-picker-makeStyles-highlighted-29': {
            background: 'rgba(2, 119, 189, 0.25) !important'
        },
        '& .MuiStepLabel-label': {
            fontSize: '12px'
        }
    },
    StepperCont: {
        padding: '9em 0 2em 0',
        background: 'transparent'
    },
    nameText: {
        color: '#000000',
        fontSize: '24px',
        fontWeight: '500'
    },
    userDetailCont: {
        padding: '1em 1em',
        fontSize: '14px',
        marginBottom: '3em'
    },
    dob: {
        color: '#676767',
        paddingTop: '.2em'
    },
    status: {
        color: 'red'
    },
    messageBtn: {
        background: '#E3F2FD',
        boxShadow: '0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 24%)',
        color: '#2196F3',
        margin: '1em 0 !important'
    },
    borderBtm: {
        border: '1px solid #E5E5E5',
        margin: '0 2em'
    },
    contactCont: {
        '& svg': {
            marginRight: '.75em'
        }
    },
    contactTxt: {
        margin: '.5em 0 1em 0',
        fontWeight: '500'
    },
    videoCall: {
        color: '#2196F3',
        cursor: 'pointer'
    },
    userImg: {
        borderRadius: '50%',
        width: '7em',
        height: '7em',
        marginRight: '1.5em'
    },
    posAbs: {
        position: 'absolute'
    },
    dateSelectionBtn: {
        background: '#FFFFFF',
        border: '1px solid #E5E5E5',
        boxSizing: 'border-box',
        borderRadius: '18px',
        marginRight: '1em',
        '& .MuiButton-root': {
            marginLeft: '0 !important',
            color: 'rgba(103, 103, 103, 0.87)',
            minWidth: '5.5em'
        }
    },
    weekBtn: {
        borderLeft: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderRadius: 0
    },
    baby: {
        padding: '5px 0'
    }
}));


function getSteps(caseDetail, caseData, monitoringData) {
    let days30 = '', days60 = '', days90 = '';
    let nextDates = caseData.infantDischargeDate || '';
    if (monitoringData && nextDates) {
        let monitoring = [...monitoringData];
        for (const e of monitoring) {
            if (e.monitoringTypeId == 90 && e.updatedDate) {
                days30 = e.updatedDate
            } else if (e.monitoringTypeId == 91 && e.updatedDate) {
                days60 = e.updatedDate;
            } else if (e.monitoringTypeId == 92 && e.updatedDate) {
                days90 = e.updatedDate;
            }
        }
    }
    return [
        { upperLabel: 'Plan start', value: moment(caseDetail.createdAt).format(util.dateFormat) },
        { upperLabel: 'Delivery date', value: caseData.infantBirthDate ? moment(caseData.infantBirthDate).format(util.dateFormat) : '' },
        { upperLabel: '2 Weeks visit', value: days30 ? moment(days30).format(util.dateFormat) : nextDates ? moment(nextDates).add(14, 'days').format(util.dateFormat) : '' },
        { upperLabel: '4 Weeks visit', value: days60 ? moment(days60).format(util.dateFormat) : days30 ? moment(days30).add(14, 'days').format(util.dateFormat) : nextDates ? moment(nextDates).add(28, 'days').format(util.dateFormat) : '' },
        { upperLabel: '6 Weeks visit', value: days90 ? moment(days90).format(util.dateFormat) : days60 ? moment(days60).add(14, 'days').format(util.dateFormat) : nextDates ? moment(nextDates).add(42, 'days').format(util.dateFormat) : '' }
    ]
}

const CaseDetail = (props) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const [activeStep, setActiveStep] = React.useState(0);
    const caseDetail = useSelector(state => state.appReducer.caseDetail) || {};
    const caseData = useSelector(state => state.appReducer.caseData) || {};
    const userData = useSelector(state => state.appReducer.userData);
    let monitoringData = useSelector(state => state.appReducer.monitoring);
    const { fullName, birthdate, cellNumber, motherEmailAddress } = caseDetail;
    let { match, history } = props;
    const dispatch = useDispatch();
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        if (newValue == 0) {
            history.push(`/assessment/${match.params.id}/task`);
        } else if (newValue == 1) {
            history.push(`/assessment/${match.params.id}/appointment`);
        } else {
            history.push(`/assessment/${match.params.id}/reward`);
        }
        dispatch({ type: actions.SET_AWARD_LIST, awardList: { total: 0, records: [] } });
        setDateRange({ startDate: moment().startOf('week')._d, endDate: moment().endOf('week')._d, active: 'week' })
    };
    const [open, setOpen] = React.useState(false);
    const toggle = () => setOpen(!open);
    const [dateRange, setDateRange] = React.useState({ startDate: moment().startOf('week')._d, endDate: moment().endOf('week')._d, active: 'week' });
    const classes = useStyles();

    const id = match.params && match.params.id || 0;
    let steps = getSteps(caseDetail, caseData, monitoringData);
    if (caseData && caseData.infantDischargeDate) {
        steps.push({ upperLabel: 'Discharge date', value: moment(caseData.infantDischargeDate).format('MM/DD/YYYY') })
    }

    steps = steps.sort((a, b) => (Date.parse(new Date(a.value)) < Date.parse(new Date(b.value))) ? -1 : (Date.parse(new Date(a.value)) > Date.parse(new Date(b.value))) ? 1 : 0);
    const onBackClick = () => {
        history.goBack();
    }

    useEffect(() => {
        //Set CaseLoad active
        let tabs = match.params && match.params.tab;
        if (tabs == "appointment") {
            setTabIndex(1);
        } else if (tabs == "reward") {
            setTabIndex(2);
        }
        dispatch({ type: actions.SET_ACTIVE_CASEID, activeCaseId: id });
        dispatch({ type: actions.SET_ACTIVE_TAB, activeTab: 2 });
    }, [])
    const onDateRangeChange = (event, type) => {
        switch (type) {
            case 'day':
                setDateRange({ startDate: moment().startOf('day')._d, endDate: moment().endOf('day')._d, label: "Today", active: 'day' });
                break;
            case 'week':
                setDateRange({ startDate: moment().startOf('week')._d, endDate: moment().endOf('week')._d, active: 'week' });
                break;
            case 'month':
                setDateRange({ startDate: moment().startOf('months')._d, endDate: moment().endOf('months')._d, label: 'This Month', active: 'month' });
                break;
            default:
                break;
        }
    }

    const getAppUserList = async () => {
        let appUserList = {};
        appUserList = await axios(`${util.mobileAppBaseUrl}/users/appUsersList`);
        return appUserList
    }

    const getRoomData = async () => {
        let data = await getAppUserList();
        return new Promise(function (resolve, reject) {
            if (data && data.data && data.data.result) {
                let appUserData = data.data.result.filter(e => e.caseId == Number(id)) || [];
                let appUserId = appUserData.length > 0 ? appUserData[0].userId : null;
                if (appUserId) {
                    axios({
                        method: 'POST', url: `${util.mobileAppBaseUrl}/chat/initiate`,
                        data: {
                            userIds: `${appUserId},${userData.id}`,
                            appuserId: appUserId, webuserId: userData.id
                        }
                    }).then(initiateChatData => {
                        if (initiateChatData && initiateChatData.data) {
                            let roomId = initiateChatData.data.chatRoom.details.id;
                            if (roomId) {
                                axios({
                                    method: 'POST', url: `${util.videoCallAPIEndPoints.getChatAndCallHistory}?roomId=${roomId}`,
                                    data: {
                                        userId: userData.id,
                                        requestFrom: 'web'
                                    }
                                }).then(conversationData => {
                                    let data = conversationData.data || {};
                                    data.roomId = roomId;
                                    data.appuserId = appUserId
                                    resolve(data);
                                })
                            }
                        }
                    })
                } else {
                    //TODO: If appUserId is not availble
                }
            }
        })
    }

    const onMessageClick = async (e) => {
        let data = await getRoomData()
        data.openChat = true;
        data.webUserId = id;
        dispatch({ type: actions.SET_CHAT_DATA, chatData: data });
    }

    const onVideoCallClick = async () => {
        let data = await getAppUserList();
        if (data && data.data && data.data.result) {
            let appUserData = data.data.result.filter(e => e.caseId == Number(id)) || [];
            let remoteUserData = {};
            if (appUserData.length > 0 && appUserData[0].connectyCubeId) {
                remoteUserData.openVideoCall = true;
                remoteUserData.id = appUserData[0].connectyCubeId;
                remoteUserData.name = appUserData[0].fullName;
                dispatch({ type: actions.OPEN_VIDEO_CALL, remoteUserData: remoteUserData });
            } else {
                //TODO: if mobile user is not availble
            }
        }
    }

    const startDate = dateRange ? moment(dateRange.startDate).format(util.dateRangeFormat) : moment().format(util.dateRangeFormat);
    const endDate = dateRange ? moment(dateRange.endDate).format(util.dateRangeFormat) : moment().format(util.dateRangeFormat);
    const isSameDate = startDate == endDate;

    return (<div className={`user-detail dashboard-tab ${classes.root}`}>
        {id > 0 && <>
            <div className={classes.root}>
                <Grid container xs={12} className="mrb-2">
                    <span onClick={onBackClick} className='cursor'>
                        <svg className="pdr-7" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill="black" fill-opacity="0.87" />
                        </svg>
                    Caseload
                    </span>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={6} md={5} lg={5}>
                        <Paper className={classes.userDetailCont}>
                            <Grid container xs={12}>
                                <div>
                                    <img src={loginIconImg} alt={'User'} className={classes.userImg} />
                                </div>
                                <div>
                                    <div><span className={classes.nameText}>{fullName}</span></div>
                                    <div className={classes.dob}>Maternal DOB: {moment(birthdate).format(util.dateFormat)}</div>
                                    <div className={classes.baby}>Baby: {caseData.infantFirstName}</div>
                                    <div className="flex"><span>Status: </span><span className={classes.status} style={{ paddingLeft: '4px' }}> {util.renderer.combo('statusId', caseData, enums.lookupType.ProfileStatus)}</span></div>
                                    <Button variant="contained" className={classes.messageBtn} onClick={onMessageClick} >
                                        <svg className="pdr-7" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.992 1.6C15.992 0.72 15.28 0 14.4 0H1.6C0.72 0 0 0.72 0 1.6V11.2C0 12.08 0.72 12.8 1.6 12.8H12.8L16 16L15.992 1.6ZM12.8 9.6H3.2V8H12.8V9.6ZM12.8 7.2H3.2V5.6H12.8V7.2ZM12.8 4.8H3.2V3.2H12.8V4.8Z" fill="#2196F3" />
                                        </svg>
                                    Message
                                </Button>
                                </div>
                            </Grid>
                            <div className={classes.borderBtm} />
                            <div className={classes.contactCont}>
                                <div className={classes.contactTxt}>Contact information </div>
                                <div>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.41333 5.19333C3.37333 7.08 4.92 8.62 6.80667 9.58667L8.27333 8.12C8.45333 7.94 8.72 7.88 8.95333 7.96C9.7 8.20667 10.5067 8.34 11.3333 8.34C11.7 8.34 12 8.64 12 9.00667V11.3333C12 11.7 11.7 12 11.3333 12C5.07333 12 0 6.92667 0 0.666667C0 0.3 0.3 0 0.666667 0H3C3.36667 0 3.66667 0.3 3.66667 0.666667C3.66667 1.5 3.8 2.3 4.04667 3.04667C4.12 3.28 4.06667 3.54 3.88 3.72667L2.41333 5.19333Z" fill="#2196F3" />
                                    </svg>
                                    <span>Phone no: {cellNumber}</span>
                                </div>
                                <div className="pdt-7">
                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.7 0H1.3C0.585 0 0.00649999 0.5625 0.00649999 1.25L0 8.75C0 9.4375 0.585 10 1.3 10H11.7C12.415 10 13 9.4375 13 8.75V1.25C13 0.5625 12.415 0 11.7 0ZM11.7 2.5L6.5 5.625L1.3 2.5V1.25L6.5 4.375L11.7 1.25V2.5Z" fill="#2196F3" />
                                    </svg>
                                    <span className={classes.emailTxt}>E-mail address: </span><span>{motherEmailAddress}</span>
                                </div>
                                <Button variant="contained" className={classes.messageBtn} onClick={onVideoCallClick} >
                                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8889 6.25V9.16667C10.8889 9.625 10.5389 10 10.1111 10H0.777778C0.35 10 0 9.625 0 9.16667V0.833334C0 0.375 0.35 0 0.777778 0H10.1111C10.5389 0 10.8889 0.375 10.8889 0.833334V3.75L14 0.416667V9.58333L10.8889 6.25Z" fill="#2196F3" />
                                    </svg>
                                    <span className={classes.videoCall} >Video conference</span>
                                </Button>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={6} md={6} lg={6}>
                        <div className={classes.root} >
                            <Stepper alternativeLabel className={classes.StepperCont}>
                                {steps.map((stepper) => {
                                    return (
                                        <Step key={stepper.value} active={stepper.value ? true : false}>
                                            <div className="stepper-upper-txt" >{stepper.upperLabel}</div>
                                            <StepLabel>{stepper.value}</StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Grid container spacing={0}>
                <AppBar position="static" color="default">
                    <Tabs value={tabIndex} onChange={handleChange} indicatorColor="primary">
                        <Tab label="My Tasks" {...a11yProps(0)} />
                        <Tab label="Client Appointments" {...a11yProps(1)} />
                        <Tab label="Case Rewards" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
            </Grid>
            {tabIndex != rewardTabIndex &&
                <Grid container spacing={0} className={`mrt-2`} >
                    <div className={classes.dateSelectionBtn}>
                        <Button className={`day-btn ${dateRange.active == 'day' && 'active-date'}`} onClick={(e) => onDateRangeChange(e, 'day')} >Day</Button>
                        <Button className={`${classes.weekBtn} ${dateRange.active == 'week' && 'active-date'}`} onClick={(e) => onDateRangeChange(e, 'week')}>Week</Button>
                        <Button className={`month-btn ${dateRange.active == 'month' && 'active-date'}`} onClick={(e) => onDateRangeChange(e, 'month')}>Month</Button>
                    </div>
                    <div>
                        <div className="date-range-bg" onClick={toggle} >
                            <svg className="pdr-1" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.6667 10.6667H9.33333V16H14.6667V10.6667ZM17.3333 1.33333H16V0H13.3333V1.33333H5.33333V0H2.66667V1.33333H1.33333C0.6 1.33333 0 1.93333 0 2.66667V18.6667C0 19.4 0.6 20 1.33333 20H17.3333C18.0667 20 18.6667 19.4 18.6667 18.6667V2.66667C18.6667 1.93333 18.0667 1.33333 17.3333 1.33333ZM16 17.3333H2.66667V6.66667H16V17.3333Z" fill="black" fill-opacity="0.87" />
                            </svg>
                            <div>{dateRange && isSameDate ? startDate : `${startDate} - ${endDate}`}</div>
                        </div>
                        <div className={classes.posAbs}>
                            <DateRangePicker
                                open={open}
                                toggle={toggle}
                                wrapperClassName="date-range"
                                //maxDate={moment()}
                                initialDateRange={dateRange}
                                dateRangeValue={dateRange}
                                onChange={(range) => setDateRange(range)}
                            />
                        </div>
                    </div>
                </Grid>
            }

            <TabPanel value={tabIndex} index={0}>
                <Grid item xs={12} className="mrt-2">
                    <Task {...props} fromDashboard={false} showChart={true} dateRange={dateRange} />
                </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <Grid item xs={12} className="mrt-2">
                    <Appointment {...props} fromDashboard={false} dateRange={dateRange} />
                </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <Grid item xs={12} className="mrt-2">
                    <Awards {...props} fromDashboard={false} dateRange={dateRange} />
                </Grid>
            </TabPanel>
        </>}
        <div className={id > 0 && "mrt-2"}>
            <ContactForm match={match} />
        </div>
    </div >
    )
}

export default CaseDetail