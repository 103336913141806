import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import util from '../util';
import { Link } from 'react-router-dom';
import actionTypes from '../redux/actions/actionType';
import { history } from '../redux/store/';
const moment = require('moment');

let activeCaseId, motherContact, dispatch;

function handleClick(event) {
  event.preventDefault();
  let path = event.target.dataset.path;
  if (!event.target.parentElement && event.target.parentElement.dataset.path) {
    path = event.target.parentElement.dataset.path;
  }
  if (path && path.indexOf('/:id') > -1) {
    path = path.replace(":id", activeCaseId);
  } else {
    dispatch({ type: actionTypes.SET_ACTIVE_CASEID, activeCaseId: 0 });
  }
  history.push(path);
}
let breadcrumbs = null;
export default function Breadcrumb(props) {
  dispatch = useDispatch();
  activeCaseId = useSelector(state => state.appReducer.activeCaseId);
  motherContact = useSelector(state => state.appReducer.motherContact);
  breadcrumbs = [
    { path: '/cases', name: `Case List` },
    { path: '/dashboard', name: `Dashboard` },
    { path: '/dashboard', name: `All` }
  ];
  if (activeCaseId && motherContact && motherContact.length > 0 && props.match && props.match.params.id) {
    breadcrumbs.push({ path: '/assessment/:id', name: <span data-path={"/assessment/:id"}>{motherContact[0].fullName ? `${motherContact[0].fullName}` : null} {motherContact[0].cellNumber ? <> | <b data-path={"/assessment/:id"}>Phone #</b> {motherContact[0].cellNumber}</> : null} {motherContact[0].birthdate ? <> | <b data-path={"/assessment/:id"}>DOB</b> {moment(motherContact[0].birthdate).format(util.dateFormat)} </> : null}</span> });
  }
  return (
    <div className="breadcrumb">
      {breadcrumbs.map((d, i) => {
        let pathName = props.location.pathname;
        if (props.match && props.match.params.id) {
          pathName = props.location.pathname.replace(props.match.params.id, ':id');
        }
        let isActive = d.path === pathName || (d.path.indexOf('assessment') > -1 && (pathName.indexOf('tasks') > -1 || pathName.indexOf('appointments') > -1 || pathName.indexOf('awards') > -1));
        return (<><Link to="" className={isActive ? "breadcrumb-active" : ""} data-path={d.path} onClick={handleClick} >{d.name}</Link>{!(breadcrumbs.length - 1 === i) && <span className="padding"> &gt; </span>}</>)
      }
      )}
    </div >
  );
}