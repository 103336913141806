import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import actions from '../redux/actions/actionType';
import { enums } from '../Utility/const';
import Button from '@material-ui/core/Button';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import util from '../util';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'relative',
        margin: '.5em 0em 0.9em 0em',
        padding: '1.8em 0em 0em 0em',
        '& .MuiFormLabel-root': {
            fontSize: '14px'
        }
    },
    removeBtn: {
        position: 'absolute',
        right: '-10px',
        top: '-7px;',
        color: 'red'
    },
    addButn: {
        background: '#E3F2FD',
        boxShadow: '0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 24%)',
        color: '#2196F3',
        width: '232px'
    },
    itemPadding: {
        paddingRight: '0.5em',
        lineHeight: '4.5'
    },
    itemCont: {
        padding: '0em 1em'
    },
    datePicker: {
        '& .MuiSvgIcon-root': {
            color: 'rgba(33, 150, 243, 0.87)'
        }
    }
}));

const BlueCheckBox = withStyles({
    root: {
        color: 'rgba(0, 0, 0, 0.54)',
        '&$checked': {
            color: '#54b3ff',
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

const OtherSubstanceForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // find sibling contact type - to Do : Optimize code later
    let substanceTypeId = enums.lookup.otherSubstanceExpo;
    let otherSupportPersonFields = useSelector(state => state.appReducer.otherSubstanceExpoData);

    useEffect(() => {
        if (otherSupportPersonFields && otherSupportPersonFields.length === 0) {
            addOtherSupport(true);
        }
    }, []);

    const handleSupportChange = (event, fieldInfo, type) => {
        let { name, value, checked } = (event && event.target) || {};
        const { tempId, id } = fieldInfo;
        let supportIndex = -1;
        supportIndex = otherSupportPersonFields.findIndex(d => id ? d.id === id : d.tempId === tempId);
        if (supportIndex > -1) {
            if (value) {
                if (type === "text") {
                    value = util.capitalize(value);
                }
            }
            let alternateCheckboxName = name === 'notPrescribed' ? 'prescribed' : 'notPrescribed';
            let alternateCheckboxIsChecked = !checked;

            if (!id && supportIndex == 0) {
                otherSupportPersonFields[supportIndex]['defaultAddSection'] = false;
            }
            otherSupportPersonFields[supportIndex][name] = type == 'checkbox' ? checked : value;
            otherSupportPersonFields[supportIndex].changed = true;

            if (name.indexOf('notPrescribed') > -1 || name.indexOf('prescribed') > -1) {
                otherSupportPersonFields[supportIndex][alternateCheckboxName] = alternateCheckboxIsChecked;
            }

            dispatch({ type: actions.SET_SUBSTANCE_OTHER_DATA, otherSubstanceExpoData: [...otherSupportPersonFields] });
        }
    }

    const addOtherSupport = (defaultAdd) => {
        let otherSubstanceExpoDataArray = [...otherSupportPersonFields];
        otherSubstanceExpoDataArray.push({ tempId: -(otherSubstanceExpoDataArray.length + 1), substanceTypeId: substanceTypeId, defaultAddSection: defaultAdd == true });
        dispatch({ type: actions.SET_SUBSTANCE_OTHER_DATA, otherSubstanceExpoData: otherSubstanceExpoDataArray });
    }

    const removeOtherSupport = (index, tempId) => {
        let otherSubstanceExpoDataArray = [...otherSupportPersonFields];
        if (tempId) {
            let tempIdIndex = otherSubstanceExpoDataArray.findIndex(d => d.tempId === tempId);
            if (tempIdIndex > -1) {
                otherSubstanceExpoDataArray.splice(tempIdIndex, 1);
            }
        } else {
            otherSubstanceExpoDataArray[index].isDeleted = 1;
            otherSubstanceExpoDataArray[index].changed = true;
        }
        dispatch({ type: actions.SET_SUBSTANCE_OTHER_DATA, otherSubstanceExpoData: otherSubstanceExpoDataArray });
    }

    return <>
        {otherSupportPersonFields && otherSupportPersonFields.map((data, index) => !data.isDeleted && <Paper className={classes.paper} key={index}>
            <Grid container spacing={0} className={classes.itemCont}>
                <Grid container item xs={6} sm={4} md={4} lg={4} spacing={0} className={classes.itemPadding}>
                    <Grid item xs={12} sm={12} md={12} lg={12} container >
                        <Grid item>
                            <TextField
                                autoComplete={`off`}
                                id={`Other Substance's${index + 1}`}
                                label={`Other Substance's Name ${index + 1}`}
                                value={(data && data['other']) || ''}
                                onChange={(e) => handleSupportChange(e, data, 'text')}
                                name="other"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={6} sm={3} md={3} lg={3} spacing={0} className={classes.itemPadding}>
                    <Grid item xs={6} sm={6} md={6} lg={6} container >
                        <Grid item>
                            <div className="checkbox-area">
                                <BlueCheckBox
                                    name="prescribed"
                                    checked={(data && data['prescribed']) || false}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    onChange={(e) => handleSupportChange(e, data, 'checkbox')} />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} container >
                        <Grid item>
                            <div className="checkbox-area">
                                <BlueCheckBox
                                    name='notPrescribed'
                                    checked={(data && data['notPrescribed']) || false}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    onChange={(e) => handleSupportChange(e, data, 'checkbox')} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {index > 0 && <RemoveCircleIcon onClick={() => removeOtherSupport(index, data.tempId)} className={classes.removeBtn} />}
            </Grid>
        </Paper>)}
        <Button variant="contained" className={classes.addButn} onClick={addOtherSupport}><span style={{ marginRight: '1.2em' }} >{util.addIcon}</span> Add Other Substance</Button>

    </>
}

export default React.memo(OtherSubstanceForm);