import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import actions from '../redux/actions/actionType';
import { enums } from '../Utility/const';
import Button from '@material-ui/core/Button';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import util from '../util';

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'relative',
        margin: '.5em 0em 0.9em 0.2em',
        padding: '2.8em 0em 2.6em 0em',
        '& .MuiFormLabel-root': {
            fontSize: '14px'
        }
    },
    removeBtn: {
        position: 'absolute',
        right: '-10px',
        top: '-7px;',
        color: 'red'
    },
    addButn: {
        background: '#E3F2FD',
        boxShadow: '0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 24%)',
        color: '#2196F3',
        width: '155px'
    },
    itemPadding: {
        paddingRight: '0.5em',
        lineHeight: '4.5'
    },
    itemCont: {
        padding: '0em 1em'
    },
    datePicker: {
        '& .MuiSvgIcon-root': {
            color: 'rgba(33, 150, 243, 0.87)'
        }
    }
}));

const FormSibling = React.memo(function FormSibling(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const combos = useSelector(state => state.appReducer["comboData"]);
    const relationshipData = combos && combos[enums.lookupType["Relationship"]];

    const formFields = useSelector(state => state.appReducer.formFields);
    // find sibling contact type - to Do : Optimize code later
    let contactTypeId = 0;
    const sublingContactType = formFields.filter(rec => rec.displayValue === "Sibling");
    if (sublingContactType && sublingContactType.length > 0) {
        contactTypeId = sublingContactType[0].id;
    }

    let siblingFields = useSelector(state => state.appReducer.siblingData);

    useEffect(() => {
        if (siblingFields && siblingFields.length === 0) {
            addSibling(true)
        }
    }, [])


    const handleSiblingChange = (event, fieldInfo, type) => {
        let { name, value } = (event && event.target) || {};
        if (type === 'date') {
            name = 'birthdate';
            value = event;
        }

        const { tempId, id } = fieldInfo;
        let siblingIndex = -1;
        if (id) {
            siblingIndex = siblingFields.findIndex(d => d.id === id);
        } else {
            siblingIndex = siblingFields.findIndex(d => d.tempId === tempId);
            if (siblingIndex == 0) {
                siblingFields[siblingIndex]['defaultAddSection'] = false;
            }
        }
        if (siblingIndex > -1) {
            if (type === "text") {
                siblingFields[siblingIndex] = Object.assign({}, siblingFields[siblingIndex], { error: value ? (value.length < 2 || value.length > 100) : false });
                value = util.capitalize(value);
            }
            siblingFields[siblingIndex][name] = value;
            siblingFields[siblingIndex].changed = true;
            dispatch({ type: actions.SET_SIBLING_DATA, siblingData: [...siblingFields] });
        }
    }

    const addSibling = (defaultAdd) => {
        let siblingDataArray = [...siblingFields]
        const keyId = siblingDataArray.length + 1;
        siblingDataArray.push({ tempId: keyId * -1, contactTypeId: contactTypeId, defaultAddSection: defaultAdd == true })
        dispatch({ type: actions.SET_SIBLING_DATA, siblingData: siblingDataArray });
    }

    const removeSibling = (index, tempId) => {
        let siblingDataArray = [...siblingFields];
        if (tempId) {
            let tempIdIndex = siblingDataArray.findIndex(d => d.tempId === tempId);
            if (tempIdIndex > -1) {
                siblingDataArray.splice(tempIdIndex, 1)
            }
        } else {
            siblingDataArray[index].isDeleted = 1;
            siblingDataArray[index].changed = true;
        }
        dispatch({ type: actions.SET_SIBLING_DATA, siblingData: [...siblingDataArray] });
    }

    return <>
        {siblingFields && siblingFields.map((data, index) => !data.isDeleted && <Paper className={classes.paper} key={index}>
            <Grid container spacing={0} className={classes.itemCont}>
                <Grid container item xs={12} sm={4} md={4} lg={4} spacing={0} className={classes.itemPadding}>
                    <Grid item xs={12} sm={12} md={12} lg={12} container >
                        <Grid item>
                            <TextField
                                autoComplete={`off`}
                                id={`sibling${index + 1}`}
                                label={`Sibling ${index + 1}'s First Name`}
                                value={(data && data['firstName']) || ''}
                                onChange={(e) => handleSiblingChange(e, data, 'text')}
                                name="firstName"
                                error={data && data['firstName'] && (data['firstName'].length < 2 || data['firstName'].length > 100)}
                                inputProps={{ min: 2, max: 100 }}
                                helperText={data && data['firstName'] && (data['firstName'].length < 2 || data['firstName'].length > 100) ? 'Invalid Sibling’s First Name min: 2 and max: 100' : ''}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={4} md={4} lg={4} spacing={0} className={classes.itemPadding}>
                    <Grid item xs={12} sm={12} md={12} lg={12} container >
                        <Grid item>
                            <TextField
                                autoComplete={`off`}
                                id={`sibling${index + 1}`}
                                label={`Sibling ${index + 1}'s Last Name`}
                                value={(data && data['lastName']) || ''}
                                onChange={(e) => handleSiblingChange(e, data, 'text')}
                                name="lastName"
                                error={data && data['lastName'] && (data['lastName'].length < 2 || data['lastName'].length > 100)}
                                inputProps={{ min: 2, max: 100 }}
                                helperText={data && data['lastName'] && (data['lastName'].length < 2 || data['lastName'].length > 100) ? 'Invalid Sibling’s Last Name min: 2 and max: 100' : ''}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={4} md={4} lg={4} spacing={0} >
                    <Grid item xs={12} sm={12} md={12} lg={12} container >
                        <Grid item>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.selectBase}>
                                <KeyboardDatePicker
                                    label={`Date of Birth`}
                                    value={(data && data['birthdate']) || null}
                                    onChange={(e) => handleSiblingChange(e, data, 'date')}
                                    format="MM/dd/yyyy"
                                    autoOk={true}
                                    minDate={new Date('01/01/1900')}
                                    maxDate={new Date(new Date())}
                                    maxDateMessage={'Date should not be in future'}
                                    minDateMessage={'Date should not be before (01/01/1900) date'}
                                    inputProps={{
                                        name: 'birthdate',
                                        id: `siblingdob${index + 1}`
                                    }}
                                    className={classes.datePicker}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={4} md={4} lg={4} spacing={0} >
                    <Grid item xs={12} sm={12} md={12} lg={12} container >
                        <Grid item>
                            <FormControl >
                                <InputLabel >{`Resides with:`}</InputLabel>
                                <Select labelId="dropdown" onChange={(e) => handleSiblingChange(e, data)} value={(data && data['relationId']) || ''} inputProps={{
                                    name: 'relationId',
                                    id: `siblingselect${index + 1}`
                                }} >
                                    {relationshipData && relationshipData.map(item => <MenuItem key={item.value ? item.value : item} value={item.value ? item.value : item}>{item.label ? item.label : item}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {index > 0 && <RemoveCircleIcon onClick={() => removeSibling(index, data.tempId)} className={classes.removeBtn} />}
            </Grid>
        </Paper>)}
        <Button variant="contained" className={classes.addButn} onClick={addSibling}><span style={{ marginRight: '1.2em' }} >{util.addIcon}</span> Add Sibling</Button>

    </>
})

export default React.memo(FormSibling);