const loader = ({
    url = 'https://cdn.jsdelivr.net/npm/connectycube@3.5.1/dist/connectycube.min.js',
    callback, scriptId = 'connectyCubeScript' }) => {
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
        console.log('existing script');
        callback();
    } else {
        const script = document.createElement('script');
        script.src = url;
        script.id = scriptId;
        document.body.appendChild(script);
        script.onload = function () {
            console.log('loaded');
            callback();
        };
    }
};
export default loader;