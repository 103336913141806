const drawerWidth = 170;

export const styles = (theme) => ({
    root: {
        flexGrow: 1,
        height: '100vh',
        minHeight: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.background.default,

    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            width: `100%`,
        }
    },
    appBarShift: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        width: `calc(100% - ${drawerWidth}px)`
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        overflow: 'hidden',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '-6px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: '1em 9%',
        minHeight: '100%',
        height: '100%',
        flex: '1 1 auto',
        overflowY: 'hidden',
        minHeight: '72vh'
    },
    button: {
        margin: theme.spacing(),
    },
    link: {
        textDecoration: 'none',
    },
    current: {
        color: 'red !important',
    },
    notifications: {
        overflowX: 'hidden'
    },
    fillSpace: {
        flex: '.50'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    },
    contentShift: {
        marginLeft: -drawerWidth
    },
    logoutRight: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#FFF',
        },
        marginLeft: 0,
        marginRight: '.5em',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    input: { padding: '.1em .5em' },
    searchIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        color: '#9e9e9e !important'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
    caseAdd: {
        marginLeft: '.2em'
    }
});