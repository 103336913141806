import "babel-polyfill";
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './components/VideoCall/reportWebVitals';
import { hotjar } from 'react-hotjar';

const hjid = 2164369, hjsv = 6;
var InitializeJSLogger = {
    initialize: function () {
        window.JsLoggerEx && window.JsLoggerEx.initialize();
    }
}
InitializeJSLogger.initialize();
hotjar.initialize(hjid, hjsv);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
reportWebVitals();

