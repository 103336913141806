import ContactForm from '../pages/assessment/ContactForm';
import Tasks from '../pages/tasks';
import Appointments from '../pages/appointments';
import Awards from '../pages/awards';
import Cases from '../pages/cases';
import { consts } from '../Utility/const';
import TabsLayout from '../components/TabLayout';
import Dashboard from '../pages/dashboard/index';
import CaseDetail from '../pages/caseDetail/index'
import Resource from '../pages/Resource/index';
import Admin from '../pages/Admin';
import { isLive } from '../Utility/const';

let routes = [
    { path: '/cases', title: consts.Cases, exact: true, component: Cases },
    { path: '/dashboard', title: consts.Dashboard, exact: true, component: Dashboard },
    { path: '/dashboard/:id', title: consts.Dashboard, exact: true, component: Dashboard },
    { path: '/assessment/:id', title: consts.Assessment, exact: true, component: ContactForm },
    { path: '/assessment/:id/:tab', title: consts.Assessment, exact: true, component: CaseDetail },
    { path: '/tasks/:id', title: consts.Tasks, exact: true, component: TabsLayout, isMenu: true, tabComponent: Tasks, tabIndex: 1 },
    { path: '/appointments/:id', title: consts.Appointments, exact: true, component: TabsLayout, isMenu: true, tabComponent: Appointments, tabIndex: 2 },
    { path: '/awards/:id', title: consts.Rewards, exact: true, component: Awards, isMenu: true, tabComponent: Awards, tabIndex: 6 },
    { path: '/admin', title: consts.Resource, exact: true, component: Admin },
    { path: '/admin/:tab', title: consts.Resource, exact: true, component: Admin },
    { path: '/admin/:tab/:id', title: consts.Resource, exact: true, component: Admin }
]
if (isLive) {
    routes.push({ path: '/', title: consts.Cases, exact: true, component: Cases });
}
export { routes };