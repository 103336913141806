import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Typography, Popover, makeStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import { styles } from './../navigation/styles';
import moment from 'moment';
import axios from 'axios';
import util from './../util';
import actions from '../redux/actions/actionType';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '35em',
        borderBottom: '1px solid #E5E5E5',
        padding: '0',
        backgroundColor: theme.palette.background.paper,
        '& .MuiListItemText-primary': {
            fontWeight: '500',
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: '0.5px',
            display: 'flex',
            alignItems: 'center'
        }
    },
    appointmentStatus: {
        color: '#676767',
        fontWeight: '400',
        marginLeft: '.5em'
    },
    inline: {
        display: 'flex',
        alignItems: 'center'
    },
    typography: {
        padding: '1em 1.5em 0 1.5em',
        width: '32em',
        borderBottom: '1px solid #E5E5E5'
    },
    notificationText: {
        fontWeight: '500',
        fontSize: '18px',
        marginLeft: '.5em'
    },
    scheduleAppointment: {
        fontWeight: '500',
        fontSize: '12px',
        color: '#676767'
    },
    missedAppointment: {
        color: '#E53935'
    },
    missedIcon: {
        height: '16px',
        width: '16px',
        borderRadius: '50%',
        textAlign: 'center',
        background: '#FCE7E7',
        border: '1px solid #E53935',
        marginRight: '.5em'
    },
    notesText: {
        color: '#676767',
        fontSize: '12px',
        letterSpacing: '0.4px',
        marginLeft: '.75em',
        width: '100%'
    },
    popover: {
        '& .MuiPopover-paper': {
            maxHeight: '28em'
        }
    },
    clearBtn: {
        fontSize: '12px',
        fontWeight: '500',
        color: '#2196F3',
        marginLeft: '1em',
        marginTop: '.4em'
    },
    time: {
        fontSize: '9px',
        width: '20%',
        textAlign: 'right'
    },
    notSeen: {
        background: 'rgba(179, 229, 252, 0.24)'
    },
    newMessageCount: {
        background: '#FFA000',
        color: '#fff',
        borderRadius: '10px',
        fontSize: '12px',
        padding: '0px 11px',
        marginLeft: '1em',
        marginTop: '4px',
        height: '19px'
    },
    myclient: {
        fontSize: '14px',
        marginTop: '3px'
    }
}));

const newDate = moment().format(util.dateFormat);
const preDate = moment().subtract(1, 'days').format(util.dateFormat);
const missedAppointment = 105;

const Notifcation = (props) => {
    const notifications = useSelector(state => state.appReducer.notifications) || [];
    const userData = useSelector(state => state.appReducer.userData);
    const classe = useStyles();
    const dispatch = useDispatch();

    const getAppUserList = async () => {
        let appUserList = {};
        appUserList = await axios(`${util.mobileAppBaseUrl}/users/appUsersList`);
        return appUserList
    }

    const onVideoCallClick = async (caseId) => {
        let data = await getAppUserList();
        if (data && data.data && data.data.result) {
            let appUserData = data.data.result.filter(e => e.caseId == Number(caseId)) || [];
            let remoteUserData = {};
            if (appUserData.length > 0 && appUserData[0].connectyCubeId) {
                remoteUserData.openVideoCall = true;
                remoteUserData.id = appUserData[0].connectyCubeId;
                remoteUserData.name = appUserData[0].fullName;
                dispatch({ type: actions.OPEN_VIDEO_CALL, remoteUserData: remoteUserData });
            } else {
                //TODO: if mobile user is not availble
            }
        }
    }

    const getRoomData = async (caseId) => {
        let data = await getAppUserList();
        return new Promise(function (resolve, reject) {
            if (data && data.data && data.data.result) {
                let appUserData = data.data.result.filter(e => e.caseId == Number(caseId)) || [];
                let appUserId = appUserData.length > 0 ? appUserData[0].userId : null;
                if (appUserId) {
                    axios({
                        method: 'POST', url: `${util.mobileAppBaseUrl}/chat/initiate`,
                        data: {
                            userIds: `${appUserId},${userData.id}`,
                            appuserId: appUserId, webuserId: userData.id
                        }
                    }).then(initiateChatData => {
                        if (initiateChatData && initiateChatData.data) {
                            let roomId = initiateChatData.data.chatRoom.details.id;
                            if (roomId) {
                                axios({
                                    method: 'POST', url: `${util.videoCallAPIEndPoints.getChatAndCallHistory}?roomId=${roomId}`,
                                    data: {
                                        userId: userData.id,
                                        requestFrom: 'web'
                                    }
                                }).then(conversationData => {
                                    let data = conversationData.data || {};
                                    data.roomId = roomId;
                                    data.appuserId = appUserId;
                                    setTimeout(() => {
                                        document.getElementById('chatBody') && document.getElementById('chatBody').lastElementChild && document.getElementById('chatBody').lastElementChild.scrollIntoView();
                                    }, 1000)
                                    resolve(data);
                                })
                            }
                        }
                    })
                } else {
                    //TODO: If appUserId is not availble
                }
            }
        })
    }

    const markedAsRead = async (data) => {
        if (data.roomId) {
            await axios({
                method: 'PUT', url: `${util.mobileAppBaseUrl}/videoCallActivity/${data.roomId}/mark-read`,
                data: {
                    userId: userData.id,
                    requestFrom: 'web'
                }
            })

            axios({
                method: 'POST', url: `${util.videoCallAPIEndPoints.unreadMsgsAndMissedCallCount}`,
                data: {
                    userId: userData.id,
                    requestFrom: "web"
                }
            }).then(e => {
                if (e.data && !e.data.isError) {
                    const totalUnreads = Number(e.data.totalUnreadMsgs) + Number(e.data.totalUnreadMissedVideoCalls);
                    dispatch({ type: actions.SET_UNREAD_COUNT, unreadCount: (totalUnreads || 0) });
                }
            });
        }
    }

    const onChatClick = async (caseId) => {
        let data = await getRoomData(caseId)
        if (data && data.roomId) {
            data.openChat = true;
            data.webUserId = caseId;
            dispatch({ type: actions.SET_CHAT_DATA, chatData: data });
            props.handleClose();
            await markedAsRead(data);
        }
    }

    return (
        <>
            <Popover
                id={props.id}
                open={props.open}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className={classe.popover}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classe.typography}>
                    <div className="flex">
                        <span>
                            <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 28.5198C12.5125 28.5198 13.75 27.2275 13.75 25.648H8.25C8.25 27.2275 9.47375 28.5198 11 28.5198ZM19.25 19.9044V12.7249C19.25 8.3167 16.995 4.62644 13.0625 3.65003V2.67362C13.0625 1.48183 12.1412 0.519775 11 0.519775C9.85875 0.519775 8.9375 1.48183 8.9375 2.67362V3.65003C4.99125 4.62644 2.75 8.30234 2.75 12.7249V19.9044L0 22.7762V24.2121H22V22.7762L19.25 19.9044Z" fill="black" fill-opacity="0.87" />
                            </svg>
                        </span>
                        <div className="full-width flex">
                            <div className={classe.notificationText}>Notifications</div>
                            <div className={classe.newMessageCount}>{notifications ? notifications.length : 0} new</div>
                        </div>
                        <div className="fl-r width-40 flex">
                            <div className={classe.myclient}>My clients
                            <svg style={{ paddingLeft: '5px' }} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0.519531L5 5.51953L10 0.519531H0Z" fill="black" fill-opacity="0.87" />
                                </svg>
                            </div>
                            <div className={classe.clearBtn}>Clear all</div>
                        </div>
                    </div>
                </Typography>
                {notifications.length > 0 && notifications.map((el, index) =>
                    <List className={classe.root} key={index}>
                        <ListItem alignItems="flex-start" className={!el.isSeen && classe.notSeen}>
                            <ListItemAvatar>
                                <Avatar alt={el.motherName} src="" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <>
                                        <div className='width-80'>
                                            <span>{el.motherName}</span>
                                            <span className={classe.appointmentStatus}>
                                                {el.statusId ? `${el.AppointmentStatus} a ${el.AppointmentType} appointment` : ''}
                                            </span>
                                        </div>
                                        <div className={classe.time}>
                                            {newDate == moment(el.updatedAt).format(util.dateFormat) ? moment.duration(moment().diff(moment(el.updatedAt))).asMinutes() > 60 ?
                                                moment.duration(moment().diff(moment(el.updatedAt))).hours() + ' Hrs ago' : moment.duration(moment().diff(moment(el.updatedAt))).minutes() + ' Min ago'
                                                : preDate == moment(el.updatedAt).format(util.dateFormat) ? 'Yesterday' : `${moment().diff(moment(el.updatedAt), 'days')} days ago`}
                                            {el.time}
                                        </div>
                                    </>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={`${classe.inline} ${classe.scheduleAppointment} ${classe.missedAppointment}`}
                                            color="textPrimary"
                                        >
                                            {
                                                el.statusId == missedAppointment &&
                                                <div className={classe.missedIcon}>
                                                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 8H10L5 0L0 8ZM5.45455 6.73684H4.54545V5.89474H5.45455V6.73684ZM5.45455 5.05263H4.54545V3.36842H5.45455V5.05263Z" fill="#E53935" />
                                                    </svg>
                                                </div>
                                            }
                                            {(moment(el.createdAt).format(util.dateFormat) < moment().subtract(3, 'days').format(util.dateFormat) && el.statusId != missedAppointment) ? 'Not complete' : `${el.AppointmentStatus} appointment`}
                                        </Typography>
                                        <div className="flex">
                                            <div className={`width-69 ${classe.inline}`}>
                                                {el.notes &&
                                                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.5 0.519531H1.5C0.675 0.519531 0.00750017 1.19453 0.00750017 2.01953L0 14.0195C0 14.8445 0.6675 15.5195 1.4925 15.5195H10.5C11.325 15.5195 12 14.8445 12 14.0195V5.01953L7.5 0.519531ZM9 12.5195H3V11.0195H9V12.5195ZM9 9.51953H3V8.01953H9V9.51953ZM6.75 5.76953V1.64453L10.875 5.76953H6.75Z" fill="#676767" fill-opacity="0.87" />
                                                    </svg>
                                                }
                                                <div className={classe.notesText}>{el.notes}</div>
                                            </div>
                                            <div className="flex jt-fl-end width-30">
                                                <svg onClick={() => onChatClick(el.caseId)} className="cursor pdr-1" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M21.989 2.2C21.989 0.99 21.01 0 19.8 0H2.2C0.99 0 0 0.99 0 2.2V15.4C0 16.61 0.99 17.6 2.2 17.6H17.6L22 22L21.989 2.2ZM17.6 13.2H4.4V11H17.6V13.2ZM17.6 9.9H4.4V7.7H17.6V9.9ZM17.6 6.6H4.4V4.4H17.6V6.6Z" fill="#2196F3" />
                                                </svg>
                                                <svg onClick={() => onVideoCallClick(el.caseId)} className="cursor" width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.3333 7.5V1.66667C23.3333 0.75 22.5833 0 21.6667 0H1.66667C0.75 0 0 0.75 0 1.66667V18.3333C0 19.25 0.75 20 1.66667 20H21.6667C22.5833 20 23.3333 19.25 23.3333 18.3333V12.5L30 19.1667V0.833333L23.3333 7.5Z" fill="#2196F3" />
                                                </svg>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                )}
            </Popover>
        </>
    )
}
export default withStyles(styles, { withTheme: true })(React.memo(Notifcation)); 