import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import actions from '../../redux/actions/actionType';
import { request, apis } from '../../httpUtil/';
import swal from 'sweetalert';
import Alert from '@material-ui/lab/Alert';
import loginIconImg from '../../img/login-icon.png';
// import { history } from '../../redux/store/';

const useStyles = makeStyles(theme => ({
    submit: {
        height: '3.5em'
    },
    loginWrapper: {
        background: '#FFFFFF',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundRepeat: 'no-repeat',
        border: '1px solid #E5E5E5',
        borderRadius: '4px',
        padding: '3em 0'
    },
    login: {
        top: '1.75em',
        background: '#2196F3',
        color: '#fff',
        padding: '.75em 0',
        marginLeft: '0 !important',
        '&:hover': {
            background: '#2196F3',
            borderRadius: 0
        }
    },
    cancelBtn: {
        background: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '.75em 0',
        marginTop: '3em',
        border: '1px solid rgba(0, 0, 0, 0.54)',
        boxShadow: 'none',
        marginLeft: '0 !important',
        '&:hover': {
            background: '#FFFFFF',
            borderRadius: 0
        }
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        padding: '1em 0',
        '& h2': {

            fontSize: '3em',
            color: '#006dc3'
        }
    },
    subTitle: {
        fontWeight: '500',
        fontSize: '24px',
        marginTop: '.5em',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    resetText: {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '0 3em !important',
        marginBottom: '2em',
    }
}));

const ForgotPassword = ({ history }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [validationError, setValidationError] = useState('');
    const appTitle = useSelector(state => state.appReducer.appTitleName);

    const handleClose = () => {
        setValidationError('');
    }

    const validation = () => {
        let valid = true;
        if (!email) {
            setValidationError("Required Field");
            valid = false;
        }
        if (email) {
            let isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
            setValidationError(isValidEmail ? '' : "Invalid Email.");
            valid = isValidEmail;
        }
        return valid;
    }

    const sendLink = async (event) => {
        event.preventDefault();
        if (validation()) {
            let params = {
                emailAddress: email,
                action: 'sendLink'
            };
            const response = await request(apis.forgotPassword, params);
            dispatch({ type: actions.SET_SHOW_LOADER, showLoader: false });
            if (response) {
                let { success, message } = response;
                if (success) {
                    history.push('/');
                }
                swal({
                    title: message,
                    dangerMode: success ? true : false,
                })
            }
        }
    }

    const onBackClick = () => {
        history.push('/');
    }

    return (
        <div className="forgot-wrapper">
            <form>
                <Grid container spacing={3} xs={6} md={5} lg={4} className="forgotPasswordBox">
                    <Grid item xs={12}>
                        <div className={classes.title}>
                            <img src={loginIconImg} alt={'Logout'} height='60' width='60' />
                            <h3 className="pdl-1">{appTitle}</h3>
                        </div>
                        <Typography variant="h4" color="inherit" align='center' className={classes.subTitle}>Forget your password?</Typography>
                    </Grid><br />
                    <Grid item xs={12} align='center' className={classes.resetText}>
                        Don’t worry! Resetting your password is easy. Just type in your email below.
                        </Grid><br />

                    <TextField
                        fullWidth
                        id="email"
                        label="Email"
                        placeholder="Enter your email address"
                        name="email"
                        autoComplete="off"
                        onChange={value => setEmail(value.target.value)} value={email}
                    /><br />
                    {validationError != "" ? <div><Alert onClose={handleClose} severity="error" className={classes.alert}>
                        {validationError}
                    </Alert></div> : null
                    }

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.login}
                        onClick={sendLink}
                    >Send</Button>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.cancelBtn}
                        onClick={onBackClick}
                    >Cancel</Button>

                    {/* </div> */}
                </Grid>
            </form>
        </div >);
}

export default ForgotPassword;


