import * as React from 'react';
//import { Pie, Doughnut } from 'react-chartjs-2';
import { defaults } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import util from '../util';
import { enums } from '../Utility/const'
import Paper from '@material-ui/core/Paper';
import { Doughnut } from './Chart';

const PieChart = React.memo(function PieChart(props) {
    let { width, height, responsive, position, fromAward, chartData, data, isTaks } = props;
    let chart = {};
    defaults.global.defaultFontSize = 13;
    if (!data) {
        const pieChartData = chartData && chartData.pieChartData ? chartData.pieChartData : {};
        chart = Object.keys(pieChartData).length > 0 ? util.setChartData(enums.lookupType.Priority, pieChartData, isTaks) : [];
        data = {
            labels: chart.labels,
            datasets: [{
                data: chart.chartData,
                backgroundColor: ['#E8504C', '#FFBA07', '#F5F93C', '#ED1C24'],
                hoverBackgroundColor: ['#E8504C', '#FFBA07', '#F5F93C', '#ED1C24'],
                borderWidth: '0',
                borderColor: 'transparent'
            }]
        };
    }

    return (
        (fromAward || chart.chartData && chart.chartData.length > 0) ?
            <div className="pie-header paper-header">
                <Paper elevation={0}>
                    <div className="br-b flex">
                        {props.chartHeader ?
                            <div className="paper-header-text">{props.chartHeader}</div>
                            : null}
                        {props.pieChartTitleRight && <div className="paper-herder-rt-txt">{props.pieChartTitleRight}</div>}
                    </div>
                    <Doughnut
                        data={data}
                        width={width || 320}
                        height={height || 320}
                        options={{
                            maintainAspectRatio: false,
                            responsive: responsive || false,
                            cutoutPercentage: 56,
                            grid: {
                                borderColor: "#EF3252"
                            },
                            legend: {
                                position: position || 'right',
                                labels: { boxWidth: 10, padding: 20 },
                                fullWidth: false
                            },
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                        var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                                            return previousValue + currentValue;
                                        });
                                        var currentValue = dataset.data[tooltipItem.index];
                                        var percentage = (currentValue * 100 / total).toFixed(2); //((currentValue / total) * 100);
                                        var returnValue = fromAward ? data.labels[tooltipItem.index] + ': ' + percentage + "%" : data.labels[tooltipItem.index] + ': ' + currentValue;
                                        return returnValue;
                                    }
                                }
                            }
                        }}
                    />
                    {props.pieFooterText ? <div className="pie-footer-text">{props.pieFooterText}</div> : null}
                </Paper>
            </div> : null
    )
})

export default React.memo(PieChart);